import React from "react";

const Category = () => {
  return (
    <svg
      width="20"
      height="20"
      fill="none"
      stroke="white"
      stroke-linecap="round"
      stroke-width="2.5"
      viewBox="0 0 24 24"
    >
      <path d="M19 14H5a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2Z"></path>
      <path d="M10 3.5H5a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h5"></path>
      <path d="M17 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"></path>
      <path
        fill="white"
        stroke="none"
        d="M17 8.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      ></path>
    </svg>
  );
};

export default Category;
