import React, { useState, useEffect } from "react";
import Calendar from "./Calendar";
import moment from "moment";
import { AnimatePresence } from "framer-motion";
import CalenderPicker from "./CalenderPicker/CalenderPicker";
import { pickerList } from "../../common/DateHelpers";
import DateRangePicker from "./DateRangePicker";

const DatePicker = ({ fromDate, toDate, setAddDateInParam, setOpenPicker, setFromDate, setToDate }) => {
  const [openCalendar, setOpenCalendar] = useState(null);
  const [fromTo, setFromTo] = useState(false);
  const [showRange, setShowRange] = useState(false);
  const [otherSelectedDate, setOtherSelectedDate] = useState("");

  useEffect(() => {
    if (fromTo) {
      setOpenCalendar("to");
      setFromTo(false);
    }
  }, [fromTo]);

  useEffect(() => {
    if (otherSelectedDate) {
      setFromDate(pickerList[otherSelectedDate]);
      if(otherSelectedDate === "Yesterday") {
        setToDate(moment().subtract(1, "days").endOf("day").toDate());
      } else {
        setToDate(new Date());
      }
      if(otherSelectedDate === "All time"){
        setAddDateInParam(false)
      } else {
        setAddDateInParam(true)
      }
    }
  }, [otherSelectedDate]);


  useEffect(() => {
    setOpenPicker(showRange)
  }, [showRange])

  return (
    <section
      onClick={() => {
        setShowRange((prev) => !prev);
      }}
      className="ml-5 h-[4vh] flex font-semibold bg-white rounded"
    >
      <div className="w-52 h-[4vh]">
        <button
          // onClick={() => {
          //   setOpenCalendar((prev) => (prev !== "from" ? "from" : null));
          //   setFromDate(new Date(new Date().setHours(0,0,0,0)));
          // }}
          className={`w-52 flex justify-center items-center px-2 text-sm font-medium h-full text-grey ${
            openCalendar === "from" || showRange
              ? "bg-grey/50 rounded-l text-black"
              : openCalendar === null && "border-r border-gray-400"
          }`}
        >
          From:
          {openCalendar !== "from" && !showRange && (
            <p className="ml-2 text-xs">
              {moment(fromDate).format("LT, DD/MM/YYYY")}
            </p>
          )}
        </button>
        {/* <AnimatePresence>
          {openCalendar === "from" && (
            <Calendar
              date={fromDate}
              setDate={setFromDate}
              setOpenCalendar={setOpenCalendar}
              setFromTo={setFromTo}
            />
          )}
        </AnimatePresence> */}
      </div>
      <div className="w-52 h-[4vh]">
        <button
          // onClick={() => {
          //   setOpenCalendar((prev) => (prev !== "to" ? "to" : null));
          //   setToDate(new Date(new Date().setHours(23,59,0,0)));
          // }}
          className={`w-52 flex justify-center items-center px-2 text-sm font-medium h-full text-grey ${
            openCalendar === "to" || showRange
              ? "bg-grey/50 rounded-r text-black"
              : openCalendar === null && "border-l border-gray-400"
          }`}
        >
          To:
          {openCalendar !== "to" && !showRange && (
            <p className="ml-2 text-xs">
              {moment(toDate).format(`LT, DD/MM/YYYY`)}
            </p>
          )}
        </button>
        {/* <AnimatePresence>
          {openCalendar === "to" && (
            <Calendar
              setDate={setToDate}
              date={toDate}
              setOpenCalendar={setOpenCalendar}
            />
          )}
        </AnimatePresence> */}
        <AnimatePresence>
          {showRange && (
            <div onClick={(e) => e.stopPropagation()}>
              <DateRangePicker
              setAddDateInParam={setAddDateInParam}
              setPickerOpen={setShowRange}
              setStartDate={setFromDate}
              setEndDate={setToDate}
              setOtherSelectedDate={setOtherSelectedDate}
            />
            </div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default DatePicker;
