import React from "react";
import { motion } from "framer-motion";

const FilledClose = () => {
  return (
    <motion.svg
      whileHover={{ rotate: 90, scale: 1.1 }}
      transition={{ duration: 0.4}}
      width="26"
      height="26"
      fill="none"
      style={{ position: 'absolute', top: '-10px', right: '-10px' }}
    >
      <circle
        cx="12.8607"
        cy="12.8603"
        r="11.7545"
        transform="rotate(0.518036 12.8607 12.8603)"
        fill="#FF595E"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M15.5 10.2163L10.5 15.2163"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 10.2163L15.5 15.2163"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </motion.svg>
  );
};

export default FilledClose;
