import React, { useState } from "react";
import useCalenderControl from "../../../hooks/useCalenderControl";
import DateView from "./DateView";
import DayHeader from "./DayHeader";
import PickerControl from "./PickerControl";
import MonthView from "./MonthView";
import YearView from "./YearView";

const DatePicker = ({
  date,
  otherDate,
  from = false,
  selectedDate,
  setSelectedDate,
  selectedOtherDate,
}) => {
  const [monthView, setMonthView] = useState(false);
  const [yearView, setYearView] = useState(false);
  const [yearOffset, setYearOffset] = useState(0);
  const {
    setToNextMonth,
    setToPrevMonth,
    getDaysArray,
    setToNextYear,
    setToPrevYear,
  } = useCalenderControl({
    selectedMonth: date.month,
    setSelectedMonth: date.setMonth,
    selectedYear: date.year,
    setSelectedYear: date.setYear,
  });

  return (
    <div className="w-[20vw] h-full bg-white rounded-xl font-medium text-sm pr-4">
      <PickerControl
        date={date}
        setToNextYear={setToNextYear}
        setToPrevYear={setToPrevYear}
        setToNextMonth={setToNextMonth}
        setToPrevMonth={setToPrevMonth}
        monthView={monthView}
        setMonthView={setMonthView}
        yearView={yearView}
        setYearView={setYearView}
        yearOffset={yearOffset}
        setYearOffset={setYearOffset}
      />
      {yearView ? (
        <YearView
          yearOffset={yearOffset}
          date={date}
          selectedYear={selectedDate.getFullYear()}
          setYearView={setYearView}
        />
      ) : monthView ? (
        <MonthView
          month={selectedDate.getMonth()}
          setMonth={date.setMonth}
          setMonthView={setMonthView}
          sameYear={selectedDate.getFullYear() === date.year}
        />
      ) : (
        <>
          <DayHeader />
          <DateView
            getDaysArray={getDaysArray}
            date={date}
            otherDate={otherDate}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedOtherDate={selectedOtherDate}
            from={from}
          />
        </>
      )}
    </div>
  );
};

export default DatePicker;
