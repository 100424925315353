import React, { useState, createContext, useEffect } from "react";
import ManagementModal from "../helperComponents/ManagementModal";
import Form from "../forms/Addon";
import AddonManagementTable from "../tables/Addon";
import { AnimatePresence } from "framer-motion";
import ManagementControl from "../helperComponents/ManagementControl";
import { ProductAddonsApi } from "../apis/Products/Addon";

export const AddonContext = createContext();

const ManageAddon = () => {
  const [showAddonModal, setShowAddonModal] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageLength, setPageLength] = useState(10);
  const [editableAddonId, setEditableAddonId] = useState(null);
  const [editableAddon, setEditableAddon] = useState(null);
  const [searchString, setSearchString] = useState("");

  // const fetchAddon = async () => {
  //   const {data: response} = await ProductAddonsApi.show(editableAddonId)
  //   setEditableAddon(response.data)
  //   setShowAddonModal(true)
  // }

  // useEffect(() => {
  //   if(editableAddonId) {
  //   }
  //   else {setEditableAddon(null)}
  // },[editableAddonId])

  useEffect(() => {
    if (editableAddon) {
      setShowAddonModal(true);
    }
  }, [editableAddon]);

  return (
    <AddonContext.Provider
      value={{
        totalCount,
        setTotalCount,
        pageLength,
        setPageLength,
        searchString,
      }}
    >
      <div className="relative z-10 h-[75%] bg-blue-grey w-full">
        <ManagementControl
          setShowModal={setShowAddonModal}
          heading="Manage Addon"
          buttonText="addon"
          pageLength={pageLength}
          setPageLength={setPageLength}
          totalCount={totalCount}
          searchString={searchString}
          setSearchString={setSearchString}
        />

        <div>
          <AddonManagementTable
            setEditableAddonId={setEditableAddon}
            setShowModal={setShowAddonModal}
            showModal={showAddonModal}
          />
        </div>
        <AnimatePresence>
          {showAddonModal && (
            <ManagementModal
              handleCancel={() => {
                if (editableAddon) {
                  setEditableAddon(null);
                }
                setShowAddonModal(false);
              }}
              form
            >
              <Form
                setShowModal={setShowAddonModal}
                addon={editableAddon}
                setAddon={setEditableAddon}
              />
            </ManagementModal>
          )}
        </AnimatePresence>
      </div>
    </AddonContext.Provider>
  );
};

export default ManageAddon;
