import React, { useState, useEffect } from "react";
import Checkbox from "../utils/form/Checkbox";
// import Upload from "../../../../../assets/Upload";
import Header from "../utils/form/Header";
import InputField from "../utils/form/InputField";
import SelectField from "../utils/form/SelectField";
import TagField from "../utils/form/TagField";
import TextAreaField from "../utils/form/TextAreaField";
import { AnimatePresence, motion } from "framer-motion";
import SelectInfiniteField from "../utils/form/SelectInfiniteField";

const Form = ({
  setAddProductModalIsOpen,
  categoriesCount,
  addonsCount,
  kitchensCount,
  showVariationModal,
  setShowVariationModal,
  categories,
  addons,
  kitchens,
  editableProduct,
  setEditableProduct,
  setKitchens,
  setOptions,
  formData,
  errorObject,
  setErrorObject,
  setFormData,
  fetchCategories,
  fetchAddons,
  fetchKitchens,
  handleSubmit,
  nullKitchenOption,
}) => {
  const [categoriesSelectInput, setCategoriesSelectInput] = useState("");
  const [moreCatetegories, setMoreCategories] = useState(1);
  const [moreAddons, setMoreAddons] = useState(1);
  const [moreKitchen, setMoreKitchen] = useState(1);
  const [addonsSelectInput, setAddonsSelectInput] = useState("");
  const [kitchenSelectedInput, setKitchenSelectedInput] = useState("");
  const [editValue] = useState(Object.keys(formData).length > 2 ? true : false);
  const [fixedCode, setFixedCode] = useState(editValue);

  const handleValueChange = (value, key) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleRemove = (index, key) => {
    formData[key].splice(index, 1);
    handleValueChange([...formData[key]], key);
  };

  const handleNameErrorObject = () => {
    if (errorObject?.title) {
      delete errorObject.title;
      delete errorObject.non_field_errors;
      setErrorObject({ ...errorObject });
    }
  };

  const handleCodeErrorObject = () => {
    if (errorObject?.shortcode) {
      delete errorObject.shortcode;
      delete errorObject.non_field_errors;
      setErrorObject({ ...errorObject });
    }
  };

  const handlePasswordErrorObject = () => {
    if (errorObject?.price) {
      delete errorObject.price;
      delete errorObject.non_field_errors;
      setErrorObject({ ...errorObject });
    }
  };

  const handleKitchenError = () => {
    if (errorObject?.kitchen) {
      delete errorObject.kitchen;
      delete errorObject.non_field_errors;
      setErrorObject({ ...errorObject });
    }
  };

  const handleNameBlur = () => {
    if (fixedCode || (editValue && formData.shortcode.length !== 0)) return;
    const nameArray = formData.title.split(" ");
    let code;
    if (nameArray.length === 1) {
      code = nameArray[0].slice(0, 3).toUpperCase();
      handleValueChange(code, "shortcode");
    } else if (nameArray.length > 1) {
      code = nameArray.map((word) => word[0].toUpperCase()).join("");
      handleValueChange(code, "shortcode");
    }
  };

  useEffect(() => {
    console.log("search - ", categoriesSelectInput);
    fetchCategories({
      params: { search: categoriesSelectInput, page: moreCatetegories },
    });
  }, [categoriesSelectInput, moreCatetegories]);

  useEffect(() => {
    fetchAddons({ params: { search: addonsSelectInput, page: moreAddons } });
  }, [addonsSelectInput]);

  useEffect(() => {
    fetchKitchens({
      params: { search: kitchenSelectedInput, page: moreKitchen },
    });
  }, [kitchenSelectedInput]);

  console.log(formData?.categories);

  // console.log(kitchenSelectedInput,"kitchenSelectedInput");
  return (
    <div className=" w-full h-full flex flex-col justify-between">
      <div className="px-6">
        <Header
          // heading="Add New Product"
          heading={`Edit : ${formData.title ? formData.title : ""}`}
          setModalOpen={() => {
            setShowVariationModal(false);
            setErrorObject({});
            setAddProductModalIsOpen(false);
            setOptions([]);
            setFormData({ categories: [], addons: [] });
          }}
        />
      </div>
      <div className="max-h-[80%] h-[72vh] mb-7 px-6 overflow-y-auto text-sm">
        <InputField
          heading="Name"
          input={formData.title}
          setInput={(value) => handleValueChange(value, "title")}
          errorValue={errorObject.title}
          onBlur={handleNameBlur}
          errorObjectSetter={handleNameErrorObject}
        />
        <TextAreaField
          heading="Description"
          input={formData.description}
          setInput={(value) => handleValueChange(value, "description")}
        />
        <InputField
          heading="Code"
          input={formData.shortcode}
          setInput={(value) => {
            // if(value === "") {
            //   setFixedCode(false)
            // } else {
            //   setFixedCode(true)
            // }
            handleValueChange(value, "shortcode");
          }}
          errorValue={errorObject.shortcode}
          errorObjectSetter={handleCodeErrorObject}
        />
        <InputField
          heading="Price"
          input={formData.price}
          setInput={(val) => handleValueChange(parseInt(val) || "", "price")}
          errorValue={errorObject.price}
          errorObjectSetter={handlePasswordErrorObject}
        />
        <div className="w-full flex justify-between mt-4">
          {/* <div className="w-full mr-2">
            <div className=" font-semibold mb-2">Price</div>
            <div className="w-full">
              <input
                value={formData.price}
                onChange={(e) =>
                  handleValueChange(parseInt(e.target.value) || "", "price")
                }
                disabled={showVariationModal}
                className="bg-blue-grey py-1 px-2 w-full rounded-md outline-blue text-xs"
              />
            </div>
          </div> */}
          {/* <div className="w-full ml-2">
            <div className=" font-semibold mb-2">Cost Price</div>
            <div className="w-full">
              <input
                value={formData.cost_price}
                onChange={(e) =>
                  handleValueChange(
                    parseInt(e.target.value) || "",
                    "cost_price"
                  )
                }
                className="bg-blue-grey py-1 px-2 w-full rounded-md outline-blue text-xs"
              />
            </div>
          </div> */}
        </div>
        <div className="w-full flex justify-between items-center font-semibold mt-7 mb-5">
          <p>Discountable</p>
          <button
            onClick={() =>
              handleValueChange(
                !formData.is_not_discountable,
                "is_not_discountable"
              )
            }
            className={`w-9 h-5 rounded-full flex justify-start ${
              formData.is_not_discountable ? "bg-blue" : "bg-grey"
            } items-center`}
          >
            <motion.div
              animate={
                formData.is_not_discountable ? { x: "110%" } : { x: "10%" }
              }
              className="w-4 h-4 bg-white rounded-full"
            ></motion.div>
          </button>
        </div>
        <div className="w-full">
          <SelectInfiniteField
            count={categoriesCount}
            heading="Category"
            setMoreOptions={setMoreCategories}
            options={categories}
            showOnInput={formData?.categories?.name}
            inputSetter={setCategoriesSelectInput}
            setSelected={(category) =>
              !formData.categories?.includes(category) &&
              handleValueChange(
                [...formData.categories, category],
                "categories"
              )
            }
          />
          <div className="flex flex-wrap mt-1">
            {formData.categories?.map((category, idx) => (
              <TagField
                name={category.name}
                handleClose={() => handleRemove(idx, "categories")}
              />
            ))}
          </div>
        </div>
        <div className="w-full mt-4">
          <SelectInfiniteField
            count={addonsCount}
            setMoreOptions={setMoreAddons}
            heading="Add ons"
            options={addons}
            showOnInput={formData?.addon?.name}
            inputSetter={setAddonsSelectInput}
            setSelected={(addon) =>
              !formData.addons?.includes(addon) &&
              handleValueChange([...formData.addons, addon], "addons")
            }
          />
          <div className="flex flex-wrap mt-1">
            {formData.addons?.map((addon, idx) => (
              <TagField
                name={addon.name}
                handleClose={() => handleRemove(idx, "addons")}
              />
            ))}
          </div>
        </div>
        <SelectInfiniteField
          heading="Select Kitchen"
          count={kitchens.length + 1}
          errorValue={errorObject.kitchen}
          setErrorObject={handleKitchenError}
          options={[nullKitchenOption, ...kitchens]}
          showOnInput={formData?.kitchen?.name}
          inputSetter={setKitchenSelectedInput}
          setSelected={(kitchen) => {
            setFormData((prev) => ({ ...prev, kitchen }));
          }}
        />
        {/* <div className="w-full mt-4">
          <div className="font-semibold mb-1">Upload your image here</div>
          <div className="text-xs text-grey mb-2">
            PNG and JPG files are allowed
          </div>
          <div className="w-full">
            <div className="bg-blue-grey w-full h-40 flex flex-col justify-end items-center rounded-md outline-blue text-xs">
              <Upload />
              <div className="text-grey font-semibold mt-2 mb-6">
                Drag and Drop here or browse to choose a file
              </div>
            </div>
          </div>
        </div> */}
        <AnimatePresence>
          {!showVariationModal && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.6 }}
              className="w-full my-4"
            >
              <div className=" font-semibold mb-2">Option</div>
              <div className="w-full flex items-center">
                <Checkbox
                  checked={showVariationModal}
                  onClick={() => setShowVariationModal(!showVariationModal)}
                />
                <div className="text-xs ml-2 text-grey">
                  This product has options, like size and color
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div
        onClick={handleSubmit}
        className="bg-blue cursor-pointer w-full text-lg font-semibold text-white flex justify-center rounded-lg py-2"
      >
        Save
      </div>
    </div>
  );
};

export default Form;
