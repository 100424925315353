import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { OrderDetailsAtom } from "../../common/Atoms";

const DinningButtons = () => {
  const [selected, setSelected] = useRecoilState(OrderDetailsAtom);
  const style = "px-3 py-1 mr-3 transition ease-in-out delay-50 font-semibold rounded-md w-fit"

  useEffect(() => {
    if(!selected.type) {
      setSelected({ ...selected, type: "DINE_IN"})
    }
  }, [selected])

  return (
    <div className="mt-5 flex mb-8">
      <button
        onClick={() => setSelected({ ...selected, type: "DINE_IN" })}
        className={`${style} ${selected?.type === "DINE_IN" ? "text-white bg-green" : "text-grey bg-blue-grey"}`}
      >
        Dine In
      </button>
      <button
        onClick={() => setSelected({ ...selected, type: "TO_GO" })}
        className={`${style} ${selected?.type === "TO_GO" ? "text-white bg-green" : "text-grey bg-blue-grey"}`}
      >
        To Go
      </button>
      <button
        onClick={() => setSelected({ ...selected, type: "DELIVERY" })}
        className={`${style} ${selected?.type === "DELIVERY" ? "text-white bg-green" : "text-grey bg-blue-grey"}`}
      >
        Delivery
      </button>
    </div>
  );
};

export default DinningButtons;
