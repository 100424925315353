import React, { useState } from "react";
import { toast } from "react-toastify";
import { useSetRecoilState } from "recoil";
import { CustomersApi } from "../../../apis/Customers";
import Back from "../../../assets/Back";
import { OrderPageNewlyAddedCustomerSelect } from "../../../common/Atoms";
import AddressField from "../../../utils/form/AddressField";
import FullNameField from "../../../utils/form/FullNameField";
import InputField from "../../../utils/form/InputField";
import PhoneNumberField from "../../../utils/form/PhoneNumberField";

const Form = ({ setShowCustomerAddModal, editableCustomer }) => {
  const initialName = editableCustomer
    ? {
        firstName: editableCustomer.first_name,
        lastName: editableCustomer.last_name,
      }
    : {};
  const initialId = editableCustomer?.id;
  const initialPhoneNumber = editableCustomer
    ? {
        countryCode: editableCustomer.phone.slice(
          0,
          editableCustomer.phone.length - 10
        ),
        number: editableCustomer.phone.slice(-10),
      }
    : {
        countryCode:"+91",
        number: undefined,
      };

  const initialEmail = editableCustomer ? editableCustomer.email : "";
  const initialAddress = editableCustomer
    ? {
        street1: editableCustomer.street1,
        street2: editableCustomer.street2,
        city: editableCustomer.city,
        state: editableCustomer.state,
        postal: editableCustomer.postal_code,
      }
    : {};
  const [name, setName] = useState(initialName);
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);
  const [email, setEmail] = useState(initialEmail);
  const [id] = useState(initialId);
  const [address, setAddress] = useState(initialAddress);
  const [errorObject, setErrorObject] = useState({});
  const setNewlyAddedCustomer = useSetRecoilState(
    OrderPageNewlyAddedCustomerSelect
  );

  const handleSubmit = async () => {
    const payload = {
      first_name: name.firstName,
      last_name: name.lastName,
      email,
      phone: phoneNumber.countryCode + phoneNumber.number ,
      street1: address.street1,
      street2: address.street2,
      state: address.state,
      city: address.city,
      postal_code: address.postal,
    };
    if (id) {
      const { data: response } = await CustomersApi.update(id, payload);
      if (response.data.message) {
        setErrorObject(response.data.message);
        return;
      }
      toast.success("Customer Updated Successfully");
    } else {
      const { data: response } = await CustomersApi.create(payload);
      if (response.data.message) {
        setErrorObject(response.data.message);
        return;
      }
      const newlyAdded = {
        ...response.data,
        full_name: `${payload.first_name}${
          payload.last_name && " " + payload.last_name
        }`,
      };
      setNewlyAddedCustomer(newlyAdded);
      toast.success("Customer Created Successfully");
    }
    setShowCustomerAddModal(false);
  };

  const handleEmailErrorObject = () => {
    if (errorObject?.email) {
      delete errorObject.email;
      delete errorObject.non_field_errors;
      setErrorObject({ ...errorObject });
    }
  };

  console.log(phoneNumber, "phoneNumber");
  return (
    <div className="w-full h-full flex flex-col justify-between">
      <div className="pr-2 flex justify-end">
        <div
          onClick={() => {
            setShowCustomerAddModal(false);
          }}
          className="cursor-pointer"
        >
          <Back />
        </div>
      </div>
      <div className="flex flex-col justify-center">
        <div className="text-lg font-semibold text-blue">Customer Details</div>
        <FullNameField
          name={name}
          setName={setName}
          errorObject={errorObject}
          setErrorObject={setErrorObject}
          secondary
        />
        <PhoneNumberField
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          errorObject={errorObject}
          setErrorObject={setErrorObject}
          secondary
        />
        <InputField
          heading="Email"
          input={email}
          setInput={setEmail}
          errorValue={errorObject.email}
          errorObjectSetter={handleEmailErrorObject}
          secondary
        />
        <AddressField address={address} setAddress={setAddress} secondary />
      </div>
      <div
        onClick={handleSubmit}
        className="bg-blue w-full cursor-pointer rounded-lg py-2 font-semibold text-white flex justify-center"
      >
        Save
      </div>
    </div>
  );
};

export default Form;
