import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { checkDateIsGreater, checkDateIsLess, checkIfSelectedDate } from "../../../common/DateHelpers";

const DateView = ({
  getDaysArray,
  date,
  otherDate,
  selectedDate,
  setSelectedDate,
  selectedOtherDate,
  from
}) => {
  const [dateArray, setDateArray] = useState(null);

  useEffect(() => {
    setDateArray(getDaysArray());
  }, [date.month]);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.6 }}
      className={`grid grid-cols-7 gap-y-3 text-center mt-[2vh] text-xs px-4 pb-2`}
    >
      {dateArray?.[0]?.map((each) => (
        <p className="opacity-25 py-1">{each}</p>
      ))}
      {dateArray?.[1]?.map((each) => (
        <button className={`p-1 flex justify-center items-center ${
            checkIfSelectedDate(each, date.month, date.year, selectedOtherDate) &&
            `bg-blue text-white ${
              !from ? "rounded-l-md" : "rounded-r-md"
            }`
          } ${
            checkIfSelectedDate(each, date.month, date.year, selectedDate) &&
            `bg-blue text-white ${
              !from ? "rounded-r-md" : "rounded-l-md"
            }`
          }
           ${
            !from
              ? checkDateIsLess(
                  selectedOtherDate,
                  new Date(date.year, date.month, each)
                ) &&
                checkDateIsGreater(
                  selectedDate,
                  new Date(date.year, date.month, each)
                ) &&
                "bg-normal-blue/20 text-black"
              : checkDateIsLess(
                  selectedDate,
                  new Date(date.year, date.month, each)
                ) &&
                checkDateIsGreater(
                  selectedOtherDate,
                  new Date(date.year, date.month, each)
                ) &&
                "bg-normal-blue/20 text-black"
          }
          `}
          onClick={() => {
              setSelectedDate(new Date(date.year, date.month, each))
              date.set(each)
          }}
          >{each}
          </button>
      ))}
      {dateArray?.[2]?.map((each) => (
        <p className="opacity-25 py-1">{each}</p>
      ))}
    </motion.div>
  );
};

export default DateView;
