import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UpwardArrow from "../../assets/UpwardArrow";

import NavigatorModal from "./NavigatorModal";

const ItemLink = ({
  item,
  isOpen,
  setOpenNavigationModal,
  openNavigationModal,
  openDropdown,
  setOpenDropdown,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: "100%", x: 0 }}
        transition={{ delay: 0.2, duration: 0.6 }}
        onClick={() => {
          if (isOpen) {
            if (item.path) {
              setOpenDropdown(item === openDropdown ? {} : item);
            } else {
              setOpenDropdown({});
              navigate(item.to);
            }
          } else {
            setOpenDropdown({});
          }
        }}
        className={`cursor-pointer hover:bg-blue-grey h-12 max-h-12 rounded-lg mt-3 ${
          pathname.includes(item.to)
            ? "stroke-blue bg-blue-grey text-blue"
            : "stroke-grey text-grey"
        } ${
          !isOpen
            ? " w-12 max-w-12"
            : "w-full pl-3 flex items-center justify-start"
        }
        `}
      >
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: "100%", x: 0 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          onClick={() => {
            if (!isOpen) {
              if (!item.path) {
                setOpenNavigationModal({});
                navigate(item.to);
              }else{
                setOpenNavigationModal({});
                navigate(item.to + item.path[0])
              }
            }
          }}
          onMouseEnter={(e) => {
            if (!isOpen) {
              if (item.path) {
                setOpenNavigationModal(item);
              } else {
                setOpenNavigationModal({});
              }
            }
          }}
          className={
            !isOpen ? "w-full h-full flex items-center justify-center" : ""
          }
        >
          {item.svg}
        </motion.div>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, width: 0 }}
            animate={{ opacity: 1, width: "full" }}
            transition={{ delay: 0.3, duration: 0.2 }}
            className="ml-3 whitespace-nowrap font-bold text-sm"
          >
            {item.name}
          </motion.div>
        )}
        {!isOpen && openNavigationModal === item && (
          <NavigatorModal
            sideHeaders={item.sideHeaders}
            basePath={item.to}
            setOpenNavigationModal={setOpenNavigationModal}
            sidePath={item.path}
          />
        )}
        {isOpen && item.path && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.3 }}
            className="ml-36"
          >
            <motion.div
              initial={{ rotate: 180 }}
              animate={openDropdown === item ? { rotate: 0 } : { rotate: 180 }}
            >
              <UpwardArrow />
            </motion.div>
          </motion.div>
        )}
      </motion.div>
      {isOpen && openDropdown === item && (
        <motion.div className="pl-5">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6 }}
            className="pl-3 border-l border-grey"
          >
            {item.sideHeaders.map((route, index) => (
              <div
                key={index}
                onClick={() => {
                  setOpenDropdown({});
                  navigate(item.to + item.path[index]);
                }}
                className={`${window.location.href.includes(item.to + item.path[index]) ? "text-blue font-medium" : "text-grey"} text-xs p-2 hover:bg-blue-grey cursor-pointer`}
              >
                {route}
              </div>
            ))}
          </motion.div>
        </motion.div>
      )}
    </>
  );
};

export default ItemLink;
