import React from 'react'
import { pickerList } from '../../../common/DateHelpers'

const SideValueSetter = ({ setOtherSelectedDate, setAddDateInParam, setPickerOpen }) => {
  return (
    <div className="text-sm border-r w-full">
        {Object.keys(pickerList).map((each) => (
          <div className="pr-3 my-2">
            <button
                onClick={() => {
                    setOtherSelectedDate(each)
                    setPickerOpen(0)
                }}
              className={`w-full py-1 px-2 pr-4 rounded-md hover:bg-blue/10 hover:text-blue text-left`}
            >
              {each}
            </button>
          </div>
        ))}
      </div>
  )
}

export default SideValueSetter