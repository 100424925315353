import React, { useState, useEffect, useMemo } from "react";
import { OrdersApi } from "../apis/Orders.js";
import moment from "moment";
import Printer from "../assets/Printer";
import { useTable, usePagination, useSortBy } from "react-table";
import ManagementTable from "../helperComponents/ManagementTable";
import { useSetRecoilState } from "recoil";
import { InnerHtml } from "../common/Atoms.js";
import TodaysOrder from "../sections/TodaysOrder.js";
import ShowEntries from "../utils/ShowEntries.js";
import Searchbar from "../utils/Searchbar.js";
import Filter from "../helperComponents/Filter/index.js";

const TodaysOrderTable = ({ searchString, filterBy, setPriceTotal,setSearchString,setFilterBy }) => {
  const [totalCount, setTotalCount] = useState(0);
  const [pageLength, setPageLength] = useState(10);
  const [page, setPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initial, setInitial] = useState(true);
  const [sortValue, setSortValue] = useState("");
  const setPrintableValues = useSetRecoilState(InnerHtml);

  const fetchTodaysOrders = async () => {
    const lastMidnight = new Date(new Date().setHours(0, 0, 0, 0));
    const nextMidnight = new Date(new Date().setHours(24, 0, 0, 0));
    const params = {
      date_from: lastMidnight,
      date_to: nextMidnight,
      length: pageLength,
      page,
    };
    if (searchString) {
      params.search = searchString;
    }
    if (filterBy) {
      params.payment_method = filterBy?.id;
    }
    const { data: response } = await OrdersApi.list({ params });

    setOrders(response.data.results);
    setTotalCount(response.data.count);
    setPriceTotal(response.data.price_total);
    setLoading(false);
    console.log(response.data , 'respons-data');
  };

  const handlePrint = async (id) => {
    const { data: response } = await OrdersApi.orderPrint(id);
    setPrintableValues(response);
  };

  const COLUMN = [
    {
      id: "date",
      Header: ({ column }) => (
        <div className="flex justify-start items-center ">
          Date
          {/* <button
            className={`stroke-white ml-2 ${
              sortValue === "-created_at" ? "" : "rotate-180"
            }`}
            onClick={() => {
              setSortValue(sortValue === "created_at" ? "-created_at" : "created_at")
            }}
          >
            <UpwardArrow />
          </button> */}
        </div>
      ),
      accessor: "date",
      Cell: ({ value }) => <p>{moment(value).format("DD/MM/YYYY")}</p>,
    },
    {
      id: "order_no",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          Order no
          {/* <button
            className={`stroke-white ml-2 ${
              sortValue === "-orders__order_no" ? "" : "rotate-180"
            }`}
            onClick={() => setSortValue(sortValue === "orders__order_no" ? "-orders__order_no" : "orders__order_no")}
          >
            <UpwardArrow />
          </button> */}
        </div>
      ),
      accessor: "order_no",
      Cell: ({ row }) => <p>{row.original.order_no}</p>,
    },
    {
      id: "name",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          Name
          {/* <button
            className={`stroke-white ml-2 ${
              sortValue === "name" ? "" : "rotate-180"
            }`}
            onClick={() => setSortValue(sortValue === "name" ? "-name" : "name")}
          >
            <UpwardArrow />
          </button> */}
        </div>
      ),
      accessor: "customer.name",
      Cell: ({ value }) => <div className="font-semibold">{value}</div>,
    },
    {
      id: "payment_type",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          {/* <button
            className={`stroke-white mr-2 ${
              column.isSortedDesc ? "" : "rotate-180"
            }`}
            onClick={() => column.toggleSortBy()}
          >
            <UpwardArrow />
          </button> */}
          Payment type
        </div>
      ),
      accessor: "payment_types",
      Cell: ({ row }) => (
        <p>{row.original.transactions?.payment_types?.join(", ")}</p>
      )
    },
    {
      id: "amount",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          {/* <button
            className={`stroke-white mr-2 ${
              column.isSortedDesc ? "" : "rotate-180"
            }`}
            onClick={() => column.toggleSortBy()}
          >
            <UpwardArrow />
          </button> */}
          Payment amount
        </div>
      ),
      Cell: ({ row }) => new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(row.original.transactions?.amount || 0 )
    },
    {
      id: "buttons",
      Header: () => <div className="flex w-full justify-end pr-8">Action</div>,
      Cell: ({ row }) => (
        <div className="flex justify-end pr-5">
          {/* <div className="stroke-grey cursor-pointer bg-white p-1 mr-4 rounded-md hover:bg-grey hover:stroke-white">
            <Page />
          </div> */}
          <div
            onClick={() => {
              handlePrint(row.original.id);
            }}
            className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white"
          >
            <Printer />
          </div>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMN, [sortValue]);
  const data = useMemo(() => orders, [orders]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page - 1, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
    },
    useSortBy,
    usePagination
  );

  const { gotoPage } = tableInstance;

  useEffect(() => {
    if (!initial) {
      fetchTodaysOrders();
    }
  }, [searchString, filterBy]);

  useEffect(() => {
    fetchTodaysOrders();
    gotoPage(page - 1);
    if (initial) {
      setInitial(false);
    }
  }, [page, pageLength, sortValue]);

  return (
    <>
      <div className="w-full flex justify-between mt-4 ">
        <Searchbar setter={setSearchString} />

        <div className="flex justify-center items-center text-center gap-4">
          <div >
            <Filter filterBy={filterBy} setFilterBy={setFilterBy} />
          </div>

          <div>
            <ShowEntries
              setPageLength={setPageLength}
              totalCount={totalCount}
              pageLength={pageLength}
            />
          </div>
        </div>
      </div>
      <ManagementTable
        tableInstance={tableInstance}
        setPage={setPage}
        page={page}
        totalCount={totalCount}
        loading={loading}
        setPageLength={setPageLength}
        pageLength={pageLength}
      />
    </>
  );
};

export default TodaysOrderTable;