import React, { useEffect } from "react";

const PhoneNumberField = ({
  phoneNumber,
  setPhoneNumber,
  secondary = false,
  errorObject,
  setErrorObject = () => {},
}) => {
  const bgColor = secondary ? "bg-white" : "bg-blue-grey";
  const errorInputStyle = errorObject?.phone
    ? "bg-red/5 border border-red"
    : bgColor;
  const errorParaStyle = errorObject?.phone && "text-red";

  const handleCountryCodeChange = (e) => {
    setPhoneNumber((prev) => ({ ...prev, countryCode: e.target.value }));
  };
  const handleNumberChange = (e) => {
    delete errorObject?.phone;
    delete errorObject?.non_field_errors
    setErrorObject({ ...errorObject });
    setPhoneNumber((prev) => ({ ...prev, number: e.target.value }));
  };
  return (
    <section className="mt-4 w-full">
      <p className={`text-md font-semibold mb-2 ${errorParaStyle}`}>
        Phone Number
      </p>
      <div className="flex justify-between">
        <select
          value={phoneNumber.countryCode}
          onChange={handleCountryCodeChange}
          className={`outline-blue text-xs p-1 mr-2 ${bgColor} rounded-md`}
        >
          <option value="+91" selected>IN +91</option>
          <option value="+1">US +1</option>
        </select>
        <div className="w-full ml-2">
          <input
            value={phoneNumber.number || ""}
            onChange={handleNumberChange}
            className={`${errorInputStyle} p-2 w-full rounded-md outline-blue text-xs`}
          />
        </div>
      </div>
      <p className={`text-xs ${errorParaStyle}`}>
        {errorObject?.phone && errorObject.phone.join(". ")}
      </p>
    </section>
  );
};

export default PhoneNumberField;
