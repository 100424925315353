import React from "react";

const AddUser = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 16.5V14.8333C13.3333 13.9493 12.9821 13.1014 12.357 12.4763C11.7319 11.8512 10.884 11.5 9.99998 11.5H4.16665C3.28259 11.5 2.43475 11.8512 1.80962 12.4763C1.1845 13.1014 0.833313 13.9493 0.833313 14.8333V16.5"
        stroke="#969DB6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08333 8.16667C8.92428 8.16667 10.4167 6.67428 10.4167 4.83333C10.4167 2.99238 8.92428 1.5 7.08333 1.5C5.24238 1.5 3.75 2.99238 3.75 4.83333C3.75 6.67428 5.24238 8.16667 7.08333 8.16667Z"
        stroke="#969DB6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6667 5.66699V10.667"
        stroke="#969DB6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1667 8.16699H14.1667"
        stroke="#969DB6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddUser;
