import React, { useEffect } from "react";
import GPay from "../../assets/GPay";
import Other from "../../assets/Other";
import Paytm from "../../assets/Paytm";

const OnlinePaymentMethods = ({
  small = false,
  setOnlineMethod,
  onlineMethod,
  paymentMethod,
  setConfirmPaymentDisable,
}) => {
  // console.log(onlineMethod)

  return (
    <div className="w-full flex justify-between">
      {paymentMethod.map((method) => (
        <div
          onClick={() => {
            setOnlineMethod(method);
            setConfirmPaymentDisable(true);
          }}
          className={`flex cursor-pointer rounded-lg border border-blue text-sm ${
            onlineMethod?.id === method?.id
              ? "text-white bg-blue"
              : "text-grey bg-white"
          } font-semibold flex-col justify-center items-center p-2 
          ${
            small
              ? "w-[100px] h-[63px] text-xs "
              : "w-[120px] h-[83px] text-sm mt-5"
          }
          `}
        >
          {method?.name === "GPAY" && (
            <>
              <GPay /> <p>GPAY</p>
            </>
          )}
          {method?.name === "PAYTM" && (
            <>
              <div className="bg-white p-2 rounded-sm">
                <Paytm />
              </div>
              <p>PAYTM</p>
            </>
          )}
          {method?.name === "OTHERS" && (
            <>
              <Other />
              <p>OTHERS</p>
            </>
          )}
        </div>
      ))}
      {/* <div
          onClick={() => setOnlineMethod("paytm")}
          className={`flex cursor-pointer rounded-lg border border-blue text-sm ${
            onlineMethod === "paytm"
              ? "text-white bg-blue"
              : "text-grey bg-white"
          } font-semibold flex-col justify-center items-center p-2
          ${small ? "w-[100px] h-[63px] text-xs" : "w-[120px] h-[83px] text-sm mt-5"}
          `}
        >
          <div
          // className="bg-white p-2 rounded-md"
          >
            <Paytm />
          </div>
          PAYTM
        </div>
        <div
          onClick={() => setOnlineMethod("other")}
          className={`flex cursor-pointer rounded-lg border border-blue text-sm ${
            onlineMethod === "other"
              ? "text-white bg-blue"
              : "text-grey bg-white"
          } font-semibold flex-col justify-center items-center p-2
          ${small ? "w-[100px] h-[63px] text-xs" : "w-[120px] h-[83px] text-sm mt-5"}
          `}
        >
          <Other />
          OTHER
        </div> */}
    </div>
  );
};

export default OnlinePaymentMethods;
