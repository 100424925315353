import React, { useContext, useEffect, useState } from "react";
import Searchbar from "../utils/Searchbar";
import UserCard from "../utils/UserCard";
import SidePane from "../SidePane/PayLater";
import { PayLaterApi } from "../apis/Orders.js/PayLater";
import { Payment } from "../screens/Tables";
import { PayableAmountAtom } from "../common/Atoms";
import { useRecoilState } from "recoil";
import OrdersCardLoader from "../Loaders/OrdersCard";

const PayLater = () => {
  const [orders, setOrders] = useState(null);
  const [showableOrder, setShowableOrder] = useState(null);
  const [loading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState("")
  const { setShowPaymentPanel, showPaymentPanel } = useContext(Payment);

  const fetchPayLaterOrders = async () => {
    let params = {}
    if(searchValue) {
       params.search = searchValue
    }
    const { data: response } = await PayLaterApi.list({ params });
    setOrders(response.data);
    setLoading(false)
  };

  const fetchSelectedOrder = async (order) => {
    if(order.id === showableOrder?.[0]?.customer.id) return
    const { data: response } = await PayLaterApi.show(order.id);
    setShowableOrder(response.data);
  };

  useEffect(() => {
    if(orders?.length === 1){
      fetchSelectedOrder(orders[0])
    }
  }, [orders])

  useEffect(() => {
    if (!showPaymentPanel) fetchPayLaterOrders();
  }, [showPaymentPanel, searchValue]);

  return (
    <div className="w-full relative h-[85vh] bg-blue-grey">
      <div className="w-2/3 lg:w-[66%] h-1/2 md:h-4/5">
        <div className="text-3xl font-bold">Pay Later</div>
        <Searchbar setter={val => setSearchValue(val)} />

        <div className="w-full h-full mt-[5vh] overflow-y-scroll scrollbar-hide">
          <div className="w-full grid grid-cols-2 pr-6">
            {loading ? <OrdersCardLoader /> : orders?.map((order, idx) => (
              <UserCard
                order={order}
                fetchSelectedOrder={fetchSelectedOrder}
                showableOrder={showableOrder}
                setShowableOrder={setShowableOrder}
                setShowPaymentPane={setShowPaymentPanel}
              />
            ))}
          </div>
        </div>
      </div>
      <SidePane orders={showableOrder} setOrders={setShowableOrder} />
    </div>
  );
};

export default PayLater;
