import React, { useEffect, useState } from "react";
import Search from "../assets/Search";
import useDebounce from "../hooks/useDebounce";
import { motion, AnimatePresence } from "framer-motion";
import RoundedClose from "../assets/RoundedClose";
import useScrollDirection from "../hooks/useScrollDirection";

const SearchBar = ({
  setSearchValue = () => {},
  selectedCategory = null,
  setSelectedCategory = () => {},
}) => {
  const [value, setter] = useState("");
  const [initial, setInitial] = useState(true);
  const debouncedValue = useDebounce(value, 400);
  const [scrollDirection] = useScrollDirection();

  useEffect(() => {
    if (!initial) {
      setSearchValue(debouncedValue);
    } else {
      setInitial(false);
    }
  }, [debouncedValue]);

  return (
    <motion.div
      animate={{ top: scrollDirection ? "44px" : "0" }}
      transition={{ duration: 0.6 }}
      className="sticky top-0 bg-white"
    >
      <div className="bg-normal-blue/10 px-2 py-2">
        <div className="border flex items-center px-2 gap-x-2 rounded-md bg-white">
          <Search />
          <input
            value={value}
            onChange={(e) => setter(e.target.value)}
            className="w-full outline-none text-xs py-1"
          />
        </div>
      </div>
      <AnimatePresence>
        {selectedCategory && (
          <motion.div
            initial={{ scale: 0.2 }}
            animate={{ scale: 1 }}
            exit={{ opacity: 0 }}
            className="text-sm font-bold w-full stroke-red flex justify-between items-center py-2 px-4 bg-normal-blue/10"
          >
            Category: {selectedCategory.name}
            <RoundedClose
              handleCancel={() => setSelectedCategory(null)}
              size={14}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default SearchBar;
