import React, { useContext, useEffect, useState } from "react";
import { OrdersApi } from "../../apis/Orders.js/index.js";
import AddUser from "../../assets/AddUser";
import Pen from "../../assets/Pen";
import { Payment } from "../../screens/Tables";
import { AnimatePresence, motion } from "framer-motion";
import { useRecoilState } from "recoil";
import {
  OrderDetailsAtom,
  OrderPageNewlyAddedCustomerSelect,
} from "../../common/Atoms.js";
import UpwardArrow from "../../assets/UpwardArrow.js";

const CustomerDetails = ({
  setShowCustomerAddModal,
  setEditableCustomer,
  editableCustomer,
}) => {
  const { setShowPaymentPanel } = useContext(Payment);
  const [searchInput, setSearchInput] = useState("");
  const [initialCustomers, setInitialCustomers] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [showCustomers, setShowCustomers] = useState(false);
  const [selected, setSelected] = useRecoilState(OrderDetailsAtom);
  const [newlyAddedCustomer, setNewlyAddedCustomer] = useRecoilState(
    OrderPageNewlyAddedCustomerSelect
  );

  const fetchCustomers = async () => {
    const { data: response } = await OrdersApi.customerList({
      params: { search: searchInput },
    });
    setCustomers(response.data);
    setShowCustomers(true);
  };

  const initialFetch = async () => {
    const { data: response } = await OrdersApi.customerList({
      params: { search: "" },
    });
    setInitialCustomers(response.data);
  };

  useEffect(() => {
    if (searchInput) {
      fetchCustomers();
    } else {
      setShowCustomers(false);
    }
  }, [searchInput]);

  useEffect(() => {
    if (!selected.customer) {
      setSearchInput(null);
    } else {
      setShowCustomers(false);
    }
  }, [selected]);

  useEffect(() => {
    if (!searchInput) {
      setCustomers(initialCustomers);
    }
  }, [showCustomers]);

  useEffect(() => {
    initialFetch();
  }, []);

  useEffect(() => {
    if (!newlyAddedCustomer) return;
    const alreadyInInitial = initialCustomers.some(
      (each) => each.id === newlyAddedCustomer.id
    );
    if (alreadyInInitial) return;
    setInitialCustomers({ ...initialCustomers, newlyAddedCustomer });
    setSelected({ ...selected, customer: newlyAddedCustomer });
    setNewlyAddedCustomer(null);
  }, [newlyAddedCustomer]);

  return (
    <div>
      <div className="w-full flex justify-between mt-3">
        <div
          className={`w-full relative h-8 text-left bg-blue-grey ${
            showCustomers ? "rounded-t-lg" : "rounded-lg"
          }`}
        >
          <div className="flex justify-between">
            {/* <p className="py-1 px-3 text-grey font-semibold ">
            {selected.customer?.full_name || "Name"}
          </p> */}
            <input
              placeholder="Name"
              value={
                searchInput ||
                selected.customer?.full_name ||
                selected.customer?.name ||
                ""
              }
              onChange={(e) => {
                if (
                  (selected.customer?.full_name !== e.target.value ||
                    selected.customer?.name !== e.target.value) &&
                  e.target.value !== ""
                ) {
                  setSearchInput(e.target.value);
                } else {
                  setSearchInput(null);
                  setSelected({ ...selected, customer: null });
                }
              }}
              className=" px-3 pt-1 text-grey font-semibold bg-blue-grey rounded-l-md outline-none"
            />
            <motion.button
              animate={showCustomers ? { rotate: 0 } : { rotate: 180 }}
              className="stroke-grey mr-3"
              onClick={() => setShowCustomers((prev) => !prev)}
            >
              <UpwardArrow />
            </motion.button>
          </div>
          <AnimatePresence>
            {showCustomers && (
              <motion.div
                initial={{ maxHeight: 0 }}
                animate={{ maxHeight: "30vh", overflow: "scroll" }}
                transition={{ duration: 0.4 }}
                exit={{
                  maxHeight: 0,
                  transition: { delay: 0.2, duration: 0.4 },
                }}
                className="relative z-[59] bg-blue-grey px-1 rounded-b-lg"
              >
                {customers?.map((customer) => (
                  <motion.p
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.1, duration: 0.2 }}
                    exit={{
                      opacity: 0,
                      transition: { duration: 0.2, delay: 0 },
                    }}
                    key={customer.id}
                    onClick={() => {
                      setSearchInput(null);
                      if (selected.customer?.id === customer?.id) {
                        setSelected({ ...selected, customer: null });
                      } else {
                        setSelected({ ...selected, customer });
                      }
                      setShowCustomers(null);
                    }}
                    className="p-2 text-sm cursor-pointer font-medium text-grey hover:text-blue hover:bg-grey/10 transition ease-in-out"
                  >
                    {customer.full_name}
                  </motion.p>
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div
          onClick={() => {
            if (!selected.customer) return;
            if (!editableCustomer) {
              setEditableCustomer(selected.customer);
              return;
            }
            setShowCustomerAddModal(false);
          }}
          className="py-1 px-3 ml-2 cursor-pointer stroke-grey flex items-center bg-blue-grey text-grey font-semibold rounded-lg"
        >
          <Pen />
        </div>
        <div
          onClick={() => {
            setShowCustomerAddModal((prev) => !prev);
            setShowPaymentPanel(false);
          }}
          className="py-1 px-3 ml-2 cursor-pointer bg-blue-grey text-grey font-semibold rounded-lg"
        >
          <AddUser />
        </div>
      </div>
      <div className="ml-3 mt-2 text-blue text-xs font-semibold">
        {" "}
        {/* Balance Due: Rs. 000.00{" "} */}
      </div>
    </div>
  );
};

export default CustomerDetails;
