import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { registerIntercepts } from '../apis/axios';
import { getFromLocalStorage } from '../common/LocalStorage';

const ScreenConfig = ({ children }) => {
    const access = getFromLocalStorage("access");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
  
    // const fetchCategories = async () => {
    //   const response = await ProductCategoriesApi.list();
    // };
  
    useEffect(() => {
      if (!access) {
        setLoading(false)
        navigate("/login");
      } else {
        registerIntercepts(setLoading);
        // fetchCategories();
      }
    }, []);
  
    if (loading) {
      return <div className="flex justify-center mt-10"> loading... </div>;
    }
  
    return (
      <div className="w-full min-h-screen bg-slate-200 flex justify-center">
        <div className="w-full min-h-screen max-w-[1280px]">
            {children}
        </div>
      </div>
    )
  }
  
export default ScreenConfig