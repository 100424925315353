import { AnimatePresence } from "framer-motion";
import React, { createContext, useState, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { OrdersApi } from "../apis/Orders.js";
import { BillPrintableValueAtom, CurrentlyBillingIdAtom, InnerHtml, PayableAmountAtom, PaymentCompleteStatusAtom } from "../common/Atoms";
import MainPage from "../helperComponents/MainPage";
import Modal from "../helperComponents/Modal";
import PaymentPane from "../helperComponents/PaymentPane";
import "../printPage.css"
import PrintablePage from "./PrintablePage";

export const Payment = createContext()

function Tables() {
  const [ showPaymentPanel, setShowPaymentPanel ] = useState(false)
  const [showPrintModal, setShowPrintModal] = useState(false)
  const [printableValues, setPrintableValues] = useRecoilState(InnerHtml)
  const payableAmount = useRecoilValue(PayableAmountAtom)
  const [billableId, setBillableId] = useRecoilState(CurrentlyBillingIdAtom)
  const [billPrintable, setBillPrintable] = useRecoilState(BillPrintableValueAtom)
  const [paymentCompleteStatus, setPaymentCompleteStatus] = useRecoilState(PaymentCompleteStatusAtom)
  const links = ["Order", "Ongoing Orders", "Table View", "Pay Later View", "Today's Order"];
  const urls = [
    "/tables/order",
    "/tables/ongoing-orders",
    "/tables/table-view",
    "/tables/pay-later-view",
    "/tables/today-order"
  ];
  
  useEffect(() => {
    if(printableValues){
      window.print()
      setPrintableValues(null)
      setBillPrintable(null)
    }
  }, [printableValues])

  useEffect(() => {
    if(paymentCompleteStatus){
      setShowPrintModal(true)
      setPaymentCompleteStatus(false)
    }
  }, [paymentCompleteStatus])

  return (
    <Payment.Provider value={{showPaymentPanel, setShowPaymentPanel}}>
      <MainPage links={links} urls={urls} />
      <AnimatePresence>
        {showPaymentPanel && payableAmount && <PaymentPane />}
      </AnimatePresence>
      <PrintablePage />
      <AnimatePresence>
        {showPrintModal && (
          <Modal heading="Print Bill" handleCancel={() => setShowPrintModal(false)}>
            <section className="w-full flex flex-col items-center justify-center my-5">
              <p className="text-center font-semibold text-lg text-green">
                Payment Was Successfully Completed
              </p>
              <p className="text-center font-medium mb-3">
                Do you want to print the bill?
              </p>
              <div className="w-[40%] flex justify-evenly">
                <button
                  onClick={() => {
                    setBillableId(null)
                    setShowPrintModal(false)
                  }}
                  className="px-2 py-1 bg-blue-grey text-grey hover:bg-red hover:text-white rounded-md"
                >
                  Cancel
                </button>
                <button
                  onClick={async () => {
                    if(billableId) {
                      const { data: response } = await OrdersApi.orderPrint(billableId);
                      setPrintableValues(response)
                    } else {
                      setPrintableValues(billPrintable);
                    }
                    setShowPrintModal(false)
                  }}
                  className="px-2 py-1 bg-blue-grey text-grey hover:bg-blue hover:text-white rounded-md"
                >
                  Print
                </button>
              </div>
            </section>
          </Modal>
        )}
      </AnimatePresence>
    </Payment.Provider>
  );
}

export default Tables;
