import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import FilledClose from "../assets/FilledClose";
import Modal from "../helperComponents/Modal";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { OrderDetailsAtom } from "../common/Atoms";
import { OrdersApi } from "../apis/Orders.js";
import { TableAreasApi } from "../apis/TableArea";

const TableTile = ({
  item,
  area,
  selectedTable,
  setOpenReserveModal,
  setOpenSeatNowModal,
  editMode,
  setCustomerData,
  setOpenModal,
  setEditableId,
  handleDeleteTable,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const [deletableId, setDeletableId] = useState(null);
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useRecoilState(OrderDetailsAtom);
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    if (orderDetails && !initial) {
      navigate("/tables/orders");
    }
    setInitial(false);
  }, [orderDetails]);

  return (
    <motion.div
      exit={{ scale: 0, transition: { duration: 0.6 } }}
      className="p-5"
    >
      <div
        onMouseEnter={(e) => item.is_available && handleMouseEnter(e, item)}
        onMouseLeave={handleMouseLeave}
        className={`font-medium h-24 w-24 rounded-lg ${
          item.is_available ? "bg-white" : "bg-red/90 text-white"
        }`}
      >
        {/* <div
          onClick={async () => {
            if (editMode) {
              setEditableId(item);
              setOpenModal(area);
            }
            if(!editMode && !item.is_available){
              const {data: response} = await TableAreasApi.customerDetail(item.id, area.id)
              setCustomerData({...response.data, table: item.name, area: area.name })
              setOpenSeatNowModal(item)
            }
          }}
          className={`w-24 h-24 flex justify-center cursor-pointer items-center rounded-lg hover:shadow-lg ${item.is_available ? "hover:bg-grey/60" : "hover:bg-white/20"} transition duration-200`}
        >
          {item.name}
        </div> */}

        <div
          onClick={async () => {
            if (editMode) {
              setEditableId(item);
              setOpenModal(area);
            }
            if (!editMode && !item.is_available) {
              const { data: response } = await TableAreasApi.customerDetail(
                item.id,
                area.id
              );
              setCustomerData({
                ...response.data,
                table: item.name,
                area: area.name,
              });
              setOpenSeatNowModal(item);
            }
          }}
          className={` w-24 h-24 flex justify-center cursor-pointer items-center rounded-lg hover:shadow-lg ${
            item.is_available ? "hover:bg-grey/60" : "hover:bg-white/20"
          } transition duration-200`}
        >
          {item.name[0].toUpperCase() + item.name.slice(1).toLowerCase()}
        </div>
        {editMode && item.is_available && (
          <button
            onClick={() => setDeletableId({ area, item })}
            className="relative -top-[106px] left-20 w-fit"
          >
            <FilledClose />
          </button>
        )}
        {selectedTable === item.id && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "100%", opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.6 }}
            className="w-full h-full cursor-pointer relative -top-24 left-0"
          >
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "100%" }}
              transition={{ duration: 0.6 }}
              onClick={() =>
                setOrderDetails({ table: { id: item.id, name: item.name } })
              }
              className=" w-full relative top-0 bg-grey/60 text-white hover:bg-blue flex justify-center items-center text-sm rounded-t-lg border-b border-white rounded-b-md"
            >
              Seat now
            </motion.div>
            {/* <motion.div 
              initial={{ height: 0, y: 90 }}
              animate={{ height: "50%", y: 0}}
              transition={{duration: 0.6}}
              onClick={() => setOpenReserveModal(true)}
              className=" relative bottom-0 w-full bg-grey text-white hover:bg-blue flex justify-center items-center text-sm rounded-b-lg">
                Reserve
              </motion.div> */}
          </motion.div>
        )}
        <AnimatePresence>
          {deletableId && (
            <Modal
              heading="Confirm Delete Table"
              handleCancel={() => setDeletableId(null)}
            >
              <section className="p-5">
                <p className="text-sm text-center">
                  Are you sure you want to delete the table:{" "}
                  {deletableId.item.name}
                </p>
                <div className="flex justify-center mt-3">
                  <button
                    onClick={() => setDeletableId(null)}
                    className="py-1 px-4 mr-3 bg-blue-grey text-grey font-medium rounded-md"
                  >
                    cancel
                  </button>
                  <button
                    onClick={() => {
                      handleDeleteTable(deletableId.area, deletableId.item);
                      setDeletableId(null);
                    }}
                    className="ml-3 py-1 px-4 bg-blue rounded-md text-white font-medium"
                  >
                    Delete
                  </button>
                </div>
              </section>
            </Modal>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default TableTile;
