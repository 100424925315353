import { AnimatePresence } from "framer-motion";
import React, { useContext, useState, useEffect } from "react";
import { Payment } from "../../screens/Tables";
import Modal from "../../helperComponents/Modal";
import Cart from "./Cart";
import CustomerDetails from "./CustomerDetails";
import DinningButtons from "./DinningButtons";
import PaymentButton from "./PaymentButton";
import TableName from "./TableName";
import { motion } from "framer-motion";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  InnerHtml,
  PayableAmountAtom,
  PaymentOrderIdAtom,
} from "../../common/Atoms";

const SidePane = ({ orders, setEditableOrders, handleCancel }) => {
  const { showPaymentPanel } = useContext(Payment);
  const [subTotal, setSubTotal] = useState(null);
  const [payableAmount, setPayableAmount] = useRecoilState(PayableAmountAtom);
  const setOrderId = useSetRecoilState(PaymentOrderIdAtom);
  const [printableValues, setPrintableValues] = useRecoilState(InnerHtml);

  useEffect(() => {
    console.log(orders)
    if (Object.keys(orders).length) {
      const total = orders.items.reduce((acc, cur) => {
        const addonsPrice = cur.addons.reduce((ac, cu) => ac + cu.price, 0);
        return (
          acc +
          ((addonsPrice + cur.variant_price) * cur.quantity) *
            ((100 - cur.discount) / 100)
        );
      }, 0);
      setSubTotal(total);
    } else setSubTotal(0);
  }, [orders]);

  // useEffect(() => {
  //   if (showPaymentPanel && !printableValues) {
  //     setPayableAmount(
  //       orders.total_due
  //         ? orders.total_due
  //         : subTotal * ((100 - orders.discount) / 100)
  //     );
  //     setOrderId(orders.id);
  //   }
  // }, [printableValues]);


  return (
    <>
      <motion.div
        initial={{ x: 100 }}
        animate={showPaymentPanel && payableAmount ? { x: "-100%" } : { x: 0 }}
        transition={{ duration: 0.6 }}
        className={`w-[30%] z-20 h-screen bg-white fixed top-0 right-0`}
      >
        <div className="p-5">
          <TableName table={orders?.table?.name} />
          <CustomerDetails name={orders?.customer?.name} />
          <DinningButtons selectedType={orders?.order_type} />
          <Cart cart={orders?.items} />
          <div className="absolute w-full h-[28%] bottom-0 right-0 bg-[#C3CDE4]/[0.35]">
            {orders.note && (
              <div className="select-text w-[90%] text-xs relative -top-[3vh] left-[1vw] border-b outline-none">
                {orders.note}
              </div>
            )}
            <div
              className={`p-5 ${
                !orders?.items?.length ? "text-grey" : "text-base"
              }`}
            >
              <div className={`flex justify-between font-light mb-1`}>
                <div>
                  <p>Sub Total</p>
                  <p>Discount</p>
                </div>
                <div>
                  <p>Rs {subTotal || "0000.00"}</p>
                  <p className="text-right">{orders.discount ? `${orders.discount} %` : "NA"}</p>
                </div>
              </div>
              {/* <div className="flex items-center">
                <input type="checkbox" />
                <span className="text-xs font-light ml-2">Complimentary</span>
              </div> */}
              <div className="flex justify-between text-sm font-bold px-3 pt-2">
                <div>Total Payment</div>
                <div>
                  Rs {orders?.items?.length ? (subTotal * ((100 - orders.discount) / 100)).toFixed(2) : "0000.00"}
                </div>
              </div>
              {(subTotal * ((100 - orders.discount) / 100)) > orders?.total_due ? (
                <div className="flex px-3 justify-between text-sm">
                  <p>total due</p>
                  <p>Rs {orders.total_due}</p>
                </div>
              ) : (
                <div className="h-5"></div>
              )}
              <PaymentButton
                orders={orders}
                handleAddItem={() => setEditableOrders(orders?.id)}
                handleCancel={handleCancel}
                subTotal={subTotal * ((100 - orders.discount) / 100)}
              />
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default SidePane;
