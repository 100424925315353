import { debounce } from "lodash";
import React, { useCallback, useState, useEffect } from "react";
import ManagementTableLoader from "../../Loaders/ManagementTable";
import ManagementTableLayout from "./Layout";
import ShowEntries from "../../utils/ShowEntries";

const ManagementTable = ({
  tableInstance,
  setPage,
  page,
  loading,
  setLoading,
  pageLength,
  totalCount,
  setPageLength,

}) => {
  const [optimisablePage, setOptimisablePage] = useState(page);
  const {
    pageCount,
    canPreviousPage,
    canNextPage,
    pageIndex,
    state: { pageSize },
  } = tableInstance;

  const handlePreviousClick = () => {
    if (optimisablePage <= pageCount && optimisablePage > 1) {
      setOptimisablePage((prev) => (prev === 1 ? 1 : prev - 1));
      setLoading(true);
    }
  };

  const handleNextClick = () => {
    if (optimisablePage < pageCount) {
      setOptimisablePage((prev) => prev + 1);
      setLoading(true);
    }
  };
  const handleChangePage = (e) => {
    setOptimisablePage(Number(e.target.id));
    if (page !== Number(e.target.id)) setLoading(true);
  };

  // const handlePreviousClickOptimised = useCallback(
  //   () => {
  //     debounce(handlePreviousClick, 300)
  //   },
  //   [],
  // )

  const handleOptimisablePageChange = useCallback(
    debounce((settablePage) => {
      setPage(settablePage);
    }, 350),
    []
  );

  useEffect(() => {
    handleOptimisablePageChange(optimisablePage);
  }, [optimisablePage]);

  return (
    <>

    <div className=" w-full h-fit relative -z-0">

      <ManagementTableLayout tableInstance={tableInstance} loading={loading} />
    </div>
      {loading ? (
        <div className="text-sm  w-full h-fit relative top-[-150px] flex justify-center items-center">
          <ManagementTableLoader />
        </div>
      ) : (
        <div className="h-5"></div>
      )}
      <div className="w-full flex justify-between items-center">
        <div className="text-sm text-grey">
          Showing
          {(Number(page) - 1) * Number(pageSize) + 1 === totalCount
            ? ` the last one of ${totalCount} entries`
            : ` ${(Number(page) - 1) * Number(pageSize) + 1} to ${
                Number(page) * Number(pageSize) > totalCount
                  ? totalCount
                  : Number(page) * Number(pageSize)
              } of ${totalCount} entries`}
        </div>
        <div className="flex justify-center text-sm">
          <div
            disabled={!canPreviousPage}
            onClick={handlePreviousClick}
            className={`text-grey bg-white cursor-pointer rounded-l-lg p-2 hover:bg-blue hover:text-white ${
              !canPreviousPage ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            previous
          </div>
          {Array.from({
            length:
              pageCount - (pageCount % 6) >= optimisablePage
                ? 6
                : pageCount % 6,
          }).map((_, index) => {
            let temp = 0;
            if (optimisablePage > 6) {
              temp =
                optimisablePage % 6 === 0
                  ? optimisablePage - 6
                  : parseInt(optimisablePage / 6) * 6;
            }
            return (
              <div
                id={index + 1 + temp}
                key={index}
                onClick={handleChangePage}
                className={`py-2 px-4 mx-[1px] cursor-pointer  ${
                  optimisablePage === index + 1 + temp
                    ? "bg-blue text-white"
                    : " text-grey bg-white"
                }`}
              >
                {index + 1 + temp}
              </div>
            );
          })}
          <div
            disabled={!canNextPage}
            onClick={handleNextClick}
            className={`text-grey bg-white cursor-pointer rounded-r-lg p-2 hover:bg-blue hover:text-white ${
              !canNextPage ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            next
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagementTable;
