import React from "react";

const Edit = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 14 14"
      fill="none"
      className="inline"
    >
      <path
        d="M6.41669 2.3335H2.33335C2.02393 2.3335 1.72719 2.45641 1.5084 2.6752C1.2896 2.894 1.16669 3.19074 1.16669 3.50016V11.6668C1.16669 11.9762 1.2896 12.273 1.5084 12.4918C1.72719 12.7106 2.02393 12.8335 2.33335 12.8335H10.5C10.8094 12.8335 11.1062 12.7106 11.325 12.4918C11.5438 12.273 11.6667 11.9762 11.6667 11.6668V7.5835"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7917 1.45814C11.0238 1.22608 11.3385 1.0957 11.6667 1.0957C11.9949 1.0957 12.3096 1.22608 12.5417 1.45814C12.7738 1.6902 12.9041 2.00495 12.9041 2.33314C12.9041 2.66133 12.7738 2.97608 12.5417 3.20814L7.00002 8.74981L4.66669 9.33314L5.25002 6.99981L10.7917 1.45814Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Edit;
