import React from "react";
import ContentLoader from "react-content-loader";

const ProductLoader = () => {
  return (
    <ContentLoader
      speed={1}
      width="80vw"
      height="80vh"
      backgroundColor="#ffffff"
      foregroundColor="#F3F7FF"
      className="mt-8"
    //  foregroundColor= "#ecebeb"
    >
      <rect x="0" y="5" rx="10" ry="10" height="160" width="144" />
      <rect x="200" y="5" rx="10" ry="10" height="160" width="144" />
      <rect x="400" y="5" rx="10" ry="10" height="160" width="144" />
      <rect x="600" y="5" rx="10" ry="10" height="160" width="144" />
      <rect x="800" y="5" rx="10" ry="10" height="160" width="144" />
      <rect x="1000" y="5" rx="10" ry="10" height="160" width="144" />

      <rect x="0vw" y="180" rx="10" ry="10" height="160" width="144" />
      <rect x="200" y="180" rx="10" ry="10" height="160" width="144" />
      <rect x="400" y="180" rx="10" ry="10" height="160" width="144" />
      <rect x="600" y="180" rx="10" ry="10" height="160" width="144" />
      <rect x="800" y="180" rx="10" ry="10" height="160" width="144" />
      {/* <rect x="1000" y="180" rx="10" ry="10" height="160" width="144" /> */}

      <rect x="0vw" y="360" rx="10" ry="10" height="160" width="144" />
      <rect x="200" y="360" rx="10" ry="10" height="160" width="144" />
      <rect x="400" y="360" rx="10" ry="10" height="160" width="144" />
      <rect x="600" y="360" rx="10" ry="10" height="160" width="144" />
      {/* <rect x="800" y="360" rx="10" ry="10" height="160" width="144" />
      <rect x="1000" y="360" rx="10" ry="10" height="160" width="144" /> */}

      <rect x="0vw" y="540" rx="10" ry="10" height="160" width="144" />
      <rect x="200" y="540" rx="10" ry="10" height="160" width="144" />
      <rect x="400" y="540" rx="10" ry="10" height="160" width="144" />
      {/* <rect x="600" y="540" rx="10" ry="10" height="160" width="144" /> */}
      {/* <rect x="800" y="540" rx="10" ry="10" height="160" width="144" /> */}
      {/* <rect x="1000" y="540" rx="10" ry="10" height="160" width="144" /> */}
    </ContentLoader>
  );
};

export default ProductLoader;
