import React from "react";

const Circle = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        x="1"
        y="1"
        width="22"
        height="22"
        rx="11"
        stroke="#604BE8"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Circle;
