import React from "react";
import Close from "../../assets/Close";

const TagField = ({ name, big=false, handleClose }) => {
  const mainStyle = big
    ? "flex justify-between items-center w-48 text-red bg-red/[15%] rounded-md p-1.5 stroke-red m-1"
    : "flex items-center text-xs text-blue bg-blue/10 p-1 rounded-md m-1";

  return (
    <div className={mainStyle}>
      {name}
      {big ? (
        <Close />
      ) : (
        <button onClick={handleClose} className="ml-2 stroke-blue">
          <Close />
        </button>
      )}
    </div>
  );
};

export default TagField;
