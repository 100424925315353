import React from 'react'
import { createPortal } from 'react-dom'
import { useRecoilValue } from 'recoil'
import { InnerHtml } from '../common/Atoms'

const PrintablePage = () => {
    const htmlString = useRecoilValue(InnerHtml)
  return createPortal(
    <div dangerouslySetInnerHTML={{__html: htmlString}}></div>
  , document.getElementById("print"))
}

export default PrintablePage