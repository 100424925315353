import React from "react";

const InputField = ({
  heading,
  input,
  setInput,
  secondary = false,
  errorValue,
  disable = false,
  onBlur=() => {},
  errorObjectSetter=() => {},
}) => {
  const bgColor = secondary ? "bg-white" : "bg-blue-grey";
  const errorInputStyle = errorValue
    ? "bg-red/5 border border-red"
    : bgColor;
  const errorParaStyle = errorValue && "text-red";
  return (
    <div className="mt-4 w-full">
      <div className={`font-semibold mb-2 ${errorParaStyle}`}>{heading}</div>
      <input
        value={input}
        onChange={(e) => {
          if(disable) return
          errorObjectSetter()
          setInput(e.target.value);
        }}
        onBlur={onBlur}
        disabled={disable}
        className={`${errorInputStyle} py-2 px-2 w-full rounded-md text-xs ${disable ? "outline-none" : "outline-blue"}`}
      />
      {
        errorValue && (
          <p className={`text-xs ${errorParaStyle}`}>
            {errorValue.join(". ")}
          </p>
        )
      }
    </div>
  );
};

export default InputField;
