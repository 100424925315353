import React, { useMemo, useState, useEffect, useRef } from "react";
import Percentage from "../../../assets/Percentage";
import { useTable, useExpanded } from "react-table";
import Edit from "../../../assets/Edit";
import Modal from "../../../helperComponents/Modal";
import CartTableLayout from "../../../helperComponents/CartTableLayout";
import ReactTooltip from "react-tooltip";

const Cart = ({ cart }) => {
  const [openNoteModal, setOpenNoteModal] = useState(null);
  // const [openPercentageModal, setOpenPercentageModal] = useState(null);
  const [orders, setOrders] = useState([]);

  const fetchOrders = () => {
    const orderArray = Array.from({ length: 6 }).map((_, index) => ({
      id: index,
      name: "Cheesy Chickin Pasta",
      qty: 0,
      price: "0",
      singlePrice: "Rs 000.00",
    }));
    setOrders(orderArray);
  };

  // const handleOperation = (id, value) => {
  //   const changedOrders = orders.map((order) =>
  //     order.id === id
  //       ? {
  //           ...order,
  //           qty: order.qty + value >= 0 ? order.qty + value : order.qty,
  //         }
  //       : order
  //   );
  //   setOrders(changedOrders);
  // };

  const data = useMemo(() => {
    const items = cart?.map((item) => ({
      title: `${item.product_title} ${
        !item.variant_options[0].includes("Default")
          ? `(${item.variant_options.join("/")})`
          : ""
      }`,
      qty: item.quantity,
      price:
        item.variant_price +
        item.addons.reduce((acc, cur) => acc + cur.price, 0),
      note: item.note,
      kotTime: item.kot_time,
      addons: item.addons,
      discount: item.discount,
    }));
    const usableItems = items?.reduce((acc, cur) => {
      const kotIndex = acc.findIndex(
        (each) => each.kotTime.slice(0, -8) === cur.kotTime.slice(0, -8)
      );
      if (kotIndex >= 0) acc[kotIndex].subRows.push(cur);
      return kotIndex >= 0
        ? acc
        : [...acc, { kotTime: cur.kotTime, subRows: [cur] }];
    }, []);
    return usableItems || [];
  }, [cart]);

  const columns = useMemo(
    () => [
      {
        id: "name",
        Header: () => <p className="text-left text-base">Items</p>,
        accessor: "title",
        Cell: ({ value, row }) => (
          <div className="mt-1 h-fit">
            <p className="text-sm mr-1">
              {value}<span
                data-tip={row.original.note}
                onClick={() => setOpenNoteModal(row.original.note || null)}
                className={`cursor-pointer inline ml-1  ${
                  row.original.note ? "stroke-yellow" : "stroke-grey"
                }`}
              >
                <Edit />
              </span>
            </p>
            <ReactTooltip />
            {row.original.addons.length ? (
              <div className="text-xs text-grey font-medium">
                toppings:{" "}
                {row.original.addons.map((addon) => addon.name).join(", ")}
              </div>
            ) : (
              <></>
            )}
          </div>
        ),
      },
      {
        id: "qty",
        Header: () => <div className="text-left text-base pl-1">Qty</div>,
        width: 70,
        accessor: "qty",
        Cell: ({ value, row }) => (
          <div className="mr-2 flex select-none justify-center py-1 rounded-md bg-blue">
            {/* <div
              onClick={() => handleOperation(row.original.id, -1)}
              className="cursor-pointer border-r text-xs border-white pr-1 text-white"
            >
              -
            </div> */}
            <div className="flex cursor-default text-xs select-none justify-center px-1 text-white">
              {value}
            </div>
            {/* <div
              onClick={() => handleOperation(row.original.id, 1)}
              className="cursor-pointer select-none text-xs border-l border-white pl-1 text-white"
            >
              +
            </div> */}
          </div>
        ),
      },
      {
        id: "price",
        Header: () => <div className="text-center text-base">Price</div>,
        width: 100,
        accessor: "price",
        Cell: ({ value, row }) => (
          <p className="text-center">{`Rs ${String(
            parseFloat(value).toFixed(2) *
              row.original.qty *
              ((100 - row.original.discount) / 100)
          )}`}</p>
        ),
      },
      // {
      //   id: "buttons",
      //   Header: " ",
      //   width: 20,
      //   Cell: ({ row }) => (
      //     <div className="flex justify-end">
      //       {row.original.discount ? (
      //         <div className="text-xs p-0.5 font-semibold text-green border-b-2">
      //           {row.original.discount}
      //         </div>
      //       ) : (
      //         <div onClick={() => {}} className="cursor-pointer">
      //           <Percentage />
      //         </div>
      //       )}
      //     </div>
      //   ),
      // },
    ],
    [orders, openNoteModal]
  );
  const tableInstance = useTable({ columns, data }, useExpanded);

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    if (data.length === 1) {
      tableInstance.toggleAllRowsExpanded(true);
    } else {
      tableInstance.toggleAllRowsExpanded(false);
    }
  }, [data]);

  return (
    <>
      <div className="mt-5 flex text-sm font-light max-h-[38vh] w-full justify-center overflow-y-scroll scrollbar-hide px-3">
        <CartTableLayout tableInstance={tableInstance} />
      </div>
      {openNoteModal && (
        <Modal heading="Note" handleCancel={() => setOpenNoteModal(null)}>
          <section className="p-5 mb-3 text-sm">
            <p className="font-semibold mb-4">Enter Note</p>
            <textarea value={openNoteModal} className="w-full bg-blue-grey p-2 outline-blue" />
            <div className="w-full flex justify-center mt-3">
              <button
                onClick={() => setOpenNoteModal(null)}
                className="bg-blue px-2 py-1 rounded-md text-white font-medium mr-2"
              >
                Close
              </button>
            </div>
          </section>
        </Modal>
      )}
      {/* {openPercentageModal && (
        <Modal
          heading="Discount"
          handleCancel={() => setOpenPercentageModal(false)}
        >
          <section className="p-5 mb-3 w-full text-sm">
            <p className="font-semibold mb-2">Enter Discount Percentage</p>
            <input className="w-full bg-blue-grey py-1 px-2 rounded outline-blue" />
            <div className="w-full flex justify-center mt-4">
              <button
                onClick={() => setOpenPercentageModal(false)}
                className="bg-blue-grey px-2 py-1 rounded-md text-grey font-medium mr-2"
              >
                Cancel
              </button>
              <button className="bg-blue text-white px-2 py-1 rounded-md font-medium ml-2">
                Save
              </button>
            </div>
          </section>
        </Modal>
      )} */}
    </>
  );
};

export default Cart;
