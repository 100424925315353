import React from "react";
import ContentLoader from "react-content-loader";

const TableAreaLoader = ({ margin=true }) => {
  return (
    <ContentLoader
      speed={2}
      width="full"
      height={50}
      backgroundColor="#ffffff"
      foregroundColor="#ecebeb"
      className= {margin ? "mt-5": ""}
    >
      <rect x="0vw" y="5" rx="5" ry="5" width="6vw" height="28" />
      <rect x="8vw" y="5" rx="5" ry="5" width="6vw" height="28" />
      <rect x="16vw" y="5" rx="5" ry="5" width="6vw" height="28" />
      <rect x="24vw" y="5" rx="5" ry="5" width="6vw" height="28" />
      <rect x="32vw" y="5" rx="5" ry="5" width="6vw" height="28" />
      <rect x="40vw" y="5" rx="5" ry="5" width="6vw" height="28" />

    </ContentLoader>
  );
};

export default TableAreaLoader;
