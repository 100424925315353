import React from "react";
import MainPage from "../helperComponents/MainPage";

const Products = () => {
  const links = ["Manage Category", "Manage Products", "Manage Addon"];
  const urls = [
    "/products/manage-category",
    "/products/manage-product",
    "/products/manage-addon",
  ];

  return <MainPage links={links} urls={urls} />;
};

export default Products;
