import React from "react";
import { NavLink } from "react-router-dom";

function Topbar({ links, urls }) {
  return (
    <div className="py-[2vh]">
      <div className="flex bg-white w-fit rounded-lg">
        {links.map((link, index) => (
          <NavLink
            key={link}
            to={urls[index]}
            className={({ isActive }) =>
              `px-4 py-3 cursor-pointer rounded-lg font-bold text-xl ${
                isActive ? "text-white bg-green" : "text-grey"
              }`
            }
          >
            {link}
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default Topbar;
