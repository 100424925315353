import React from "react";
import AddUser from "../../assets/AddUser";
import Pen from "../../assets/Pen";

const CustomerDetails = ({ name }) => {
  return (
    <div>
        <div className="w-full flex justify-between mt-3">
            <div className={`w-[75%] py-1.5 px-4 ${name ? "bg-blue-grey text-grey" : "bg-blue-grey/40 text-grey/40"}  font-semibold rounded-lg`}>
                {name || "Name"}
            </div>
        </div>
        {/* <div className="ml-3 mt-2 text-blue text-xs font-semibold" > Balance Due: Rs. 000.00 </div> */}
    </div>
  );
};

export default CustomerDetails;
