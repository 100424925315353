import React from "react";
import Trash from "../../assets/Trash";
import { motion } from "framer-motion";

const VariantInputField = ({ inputRows, setInputRows, index, last, delay }) => {
  const handleDelete = () => {
    const data = inputRows;
    data.splice(index, 1);
    setInputRows([...data]);
  };

  const handleChange = (e) => {
    const data = inputRows;
    data[index] = e.target.value;
    setInputRows([...data]);
  };

  const handleAdd = (e) => {
    const data = inputRows;
    data[index] = e.target.value;
    setInputRows((prev) => [...prev, ""]);
  };

  return (
    <>
      {index < 5 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay, duration: 0.4 }}
          className="w-full flex mb-4"
        >
          <input
            value={inputRows[index]}
            placeholder={last && "Add Another Value"}
            onChange={last ? handleAdd : handleChange}
            className="bg-blue-grey py-1 px-2 w-full rounded-md outline-blue text-xs mr-4"
          />
          {inputRows.length > 2 && !last ? (
            <div
              onClick={handleDelete}
              className="stroke-grey cursor-pointer mt-1"
            >
              <Trash />
            </div>
          ) : (
            <div className="w-4"></div>
          )}
        </motion.div>
      )}
    </>
  );
};

export default VariantInputField;
