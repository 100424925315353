import React from "react";

const FilledCircle = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 14 14"
      fill="none"
    >
      <rect width="14" height="14" rx="7" fill="#604BE8" />
    </svg>
  );
};

export default FilledCircle;
