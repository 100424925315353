import React, { useState, useEffect, useCallback } from "react";
import UpwardArrow from "../../assets/UpwardArrow";
import { motion } from "framer-motion";
import { debounce } from "lodash";

const SelectField = ({
  heading,
  options,
  errorValue,
  setErrorObject,
  setSelected,
  showOnInput,
  inputSetter = () => {},
  colored = false,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectInputValue, setSelectInputValue] = useState("");
  const [shouldShowValue, setShouldShowValue] = useState(showOnInput);

  const handleOptimisedChange = useCallback(
    debounce((text) => {
      inputSetter(text);
      if (text) {
        setShowOptions(true);
      } else {
        setShowOptions(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    handleOptimisedChange(selectInputValue);
    setShouldShowValue(null);
  }, [selectInputValue]);

  useEffect(() => {
    setShouldShowValue(showOnInput);
  }, [showOnInput]);

  return (
    <div className="w-full mt-4">
      <div className={`font-semibold mb-2 ${errorValue && "text-red"}`}>
        {heading}
      </div>
      <div className={`w-full flex focus:outline-1 focus:outline-blue active:outline-blue active:outline-1 ${ errorValue ? "bg-red/5 border border-red" : "bg-blue-grey" } items-center rounded-md `}>
        <input
          value={selectInputValue || shouldShowValue}
          onChange={(e) => {
            if(errorValue) setErrorObject()
            setSelectInputValue(e.target.value)
          }}
          className={`bg-transparent py-1 px-2 w-full rounded-md outline-none text-xs`}
        />
        {options.length ? (
          <motion.button
            animate={{ rotate: showOptions ? 0 : 180 }}
            onClick={() => {
              if(errorValue) setErrorObject()
              setShowOptions(!showOptions)
            }}
            className="stroke-black px-2"
          >
            <UpwardArrow />
          </motion.button>
        ) : (
          ""
        )}
      </div>
      {showOptions &&
        options?.slice(0, 3)?.map((item, index) => (
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: index * 0.2 }}
            key={item.name ? item.name : item.title ? item.title : item}
            onClick={() => {
              setSelected(item);
              setShowOptions(false);
              if (showOnInput) setShouldShowValue(item.name || item.title);
              setSelectInputValue("");
            }}
            className={`text-grey text-xs p-2 w-full border-y ${
              colored
                ? item.type === "category"
                  ? "bg-red/5"
                  : " bg-normal-blue/5"
                : "bg-blue-grey"
            }`}
          >
            {colored && item.type + ": "}
            {item.name ? item.name : item.title ? item.title : item}
          </motion.button>
        ))}
      <p className="text-xs text-red">{errorValue}</p>
    </div>
  );
};

export default SelectField;
