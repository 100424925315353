import { authenticatedAxiosInstance as axios } from "../axios"

const list = (params) => axios.get("/products/addons", params)
const create = payload => axios.post("/products/addons", payload)
const show = id => axios.get(`/products/addons/${id}`)
const update = (id, payload) => axios.patch(`/products/addons/${id}`, payload)
const destroy = id => axios.delete(`/products/addons/${id}`)

export const ProductAddonsApi = {
    list,
    create,
    show,
    update,
    destroy
}