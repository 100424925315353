import React from "react";

const Form = ({
  name,
  setName,
  code,
  setCode,
  errorObject,
  setErrorObject,
  setOpenModal,
  handleSave,
}) => {
  return (
    <section className="w-full text-sm">
      <div className="px-10 w-full py-8">
        <p
          className={`flex justify-center rounded-md py-2 px-2 ${
            errorObject.name ? "bg-red/5" : "bg-blue-grey"
          }`}
        >
          Name
        </p>
        <input
          value={name}
          autoComplete="off"
          onChange={(e) => {
            setName(e.target.value);
            if (errorObject.name) {
              delete errorObject.name
              setErrorObject({...errorObject});
            }
          }}
          className={`mt-4 text-center w-full py-2 px-2 flex justify-center rounded-md ${
            errorObject.name ? "bg-red/5 border border-red" : "bg-blue-grey"
          }`}
        />
        {errorObject.name && (
          <p className="text-xs text-red">{errorObject.name.join("")}</p>
        )}
      </div>
      <div className="px-10 w-full pb-6">
        <p
          className={`flex justify-center bg-blue-grey rounded-md py-2 px-2 ${
            errorObject.code ? "bg-red/5" : "bg-blue-grey"
          }`}
        >
          Code
        </p>
        <input
          value={code}
          autoComplete="off"
          onChange={(e) => {
            setCode(e.target.value);
            if (errorObject.code) {
              delete errorObject.code
              setErrorObject({...errorObject})
            }
          }}
          className={`mt-4 text-center w-full py-2 px-2 flex justify-center rounded-md ${
            errorObject.code ? "bg-red/5 border border-red" : "bg-blue-grey"
          }`}
        />
        {errorObject.code && (
          <p className="text-xs text-red">{errorObject.code.join("")}</p>
        )}
      </div>
      <div className="w-full bg-blue-grey rounded-lg flex justify-center py-3 font-medium">
        <button
          onClick={() => setOpenModal(false)}
          className="p-2 bg-white text-grey font-medium rounded-md mx-2"
        >
          Cancel
        </button>
        <button
          onClick={handleSave}
          className="p-2 bg-blue text-white font-medium rounded-md mx-2"
        >
          Save
        </button>
      </div>
    </section>
  );
};

export default Form;
