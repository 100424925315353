import React from "react";
import Printer from "../assets/Printer";
import UpwardArrow from "../assets/UpwardArrow";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CartHistoryAtom, InnerHtml } from "../common/Atoms";
import { OrdersApi } from "../apis/Orders.js";

function CartTableLayout({ tableInstance, isPayLater = false }) {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    toggleRowExpanded,
  } = tableInstance;
  const order = useRecoilValue(CartHistoryAtom);
  const setPrintableValues = useSetRecoilState(InnerHtml)

  const handleClick = async (row, kotTime) => {
    let orderId
    if(isPayLater){
      orderId = row.orderId
    } else {
      orderId = order.id
    }
    const {data: response} = await OrdersApi.kotPrint(orderId, kotTime);
    let printHtml = response.data.print_list.join(
      `<p style="page-break-before: always">`
    );
    setPrintableValues(printHtml);
  };
  // const handleClick = () => {}

  return (
    <table className="w-full" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="sticky top-0 bg-white"
          >
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps({
                  style: { minWidth: column.minWidth, width: column.width },
                })}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          const date = row.original.kotTime
            ? new Date(row.original.kotTime)
            : new Date();
          const showableTime = moment(date).format("hh:mm");
          const showableDate = moment(date).format("DD/MM/YYYY");
          return (
            <>
              {
                row.original.subRows?.[0].subRows && (
                  <tr colSpan={4}>
                    <td>
                      <p className="text-lg font-semibold mt-4">Order.no {row.original.order_no}</p>
                    </td>
                  </tr>
                )
              }
              {row.canExpand && !row.original.subRows?.[0].subRows && (
                <tr>
                  <td colSpan={4}>
                    <div className="w-full flex justify-between mt-2">
                      <div
                        onClick={() => toggleRowExpanded(row.id)}
                        className="w-full bg-blue-grey cursor-pointer rounded-md px-2 py-1 text-xs flex justify-between items-center"
                      >
                        <p className="text-xs inline overflow-hidden whitespace-nowrap">
                          <span className="font-semibold">
                            KOT {row.index + 1}
                          </span>
                          <span> | Time- {showableTime} | </span>
                          <span> Date- {showableDate} </span>
                        </p>
                        <motion.div
                          animate={
                            row.isExpanded ? { rotate: 180 } : { rotate: 0 }
                          }
                          className="stroke-grey"
                        >
                          <UpwardArrow />
                        </motion.div>
                      </div>
                      {row.original.kotTime && (
                        <button
                          onClick={() => handleClick(row.original, row.original.kotTime)}
                          className="w-fit h-fit p-1 stroke-grey bg-blue-grey cursor-pointer transition ease-in-out delay-50 hover:bg-red hover:stroke-white ml-2 rounded-md"
                        >
                          <Printer />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              )}
              {!row.canExpand && (
                <motion.tr
                  {...row.getRowProps()}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.08 * row.id.split(".")[1] }}
                  className="even:stroke-yellow odd:stroke-grey"
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps({
                        style: {
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                        },
                      })}
                      className="py-2"
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </motion.tr>
              )}
            </>
          );
        })}
      </tbody>
    </table>
  );
}

export default CartTableLayout;
