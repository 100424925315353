import React from "react";
import { createPortal } from "react-dom";
import { motion } from "framer-motion";

const ModalBg = ({ handleCancel, children }) => {
  return createPortal(
    <>
      <div onClick={handleCancel} className="pointer-events-auto">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          transition={{ duration: 0.2 }}
          exit={{ opacity: 0 }}
          className="w-screen h-screen fixed top-0 left-0 z-20 bg-grey"
        ></motion.div>
        <div className="w-screen h-screen fixed top-0 left-0 z-30 backdrop-blur-[1px]"></div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.2 }}
        onClick={handleCancel}
        className="select-none fixed bottom-0 pointer-events-auto w-full flex justify-center h-fit z-50 rounded-lg shadow-lg"
      >
        {children}
      </motion.div>
    </>,
    document.getElementById("modal")
  );
};

export default ModalBg;
