import React, { useState } from "react";

const ProductSelect = ({
  errorObject,
  setErrorObject,
  variantsAddonsSelectable,
  selectedProductOptions,
  setSelectedProductOptions,
  setVariantsAddonsSelectable,
  handleProductWithOptions,
}) => {
  const priceObject = {
    variant: variantsAddonsSelectable.variants[0]?.options?.[0]?.includes(
      "Default"
    )
      ? Number(variantsAddonsSelectable.price)
      : 0,
    addons: 0,
  };
  const [price, setPrice] = useState(priceObject);


  return (
    <section className="text-sm">
      {variantsAddonsSelectable.variants.length > 0 &&
        !variantsAddonsSelectable.variants[0].options[0].includes(
          "Default"
        ) && (
          <section className="mt-4 mb-2 mx-3">
            <p className="w-full text-center text-base font-semibold">
              Variants
            </p>
            {errorObject.variants && (
              <p className="text-red text-center">{errorObject.variants}</p>
            )}
            <div className="grid grid-cols-3 gap-4 mt-2">
              {variantsAddonsSelectable.variants.map((variant) => (
                <button
                  onClick={() => {
                    setPrice({ ...price, variant: variant.price });
                    setErrorObject({});
                    return selectedProductOptions.variant === variant
                      ? setSelectedProductOptions((prev) => ({
                          ...prev,
                          selectedVariant: null,
                        }))
                      : setSelectedProductOptions((prev) => ({
                          ...prev,
                          selectedVariant: variant,
                        }));
                  }}
                  key={variant.id}
                >
                  <div
                    className={`${
                      selectedProductOptions.selectedVariant === variant
                        ? "bg-green/80 text-white"
                        : "bg-blue-grey"
                    } transition ease-in-out p-1 m-2 rounded-md font-medium`}
                  >
                    {variant.options.join("/")}
                  </div>
                  <div
                    className={`${
                      selectedProductOptions.selectedVariant === variant
                        ? "bg-green/80 text-white"
                        : "bg-blue-grey text-grey"
                    } transition ease-in-out p-1 m-2 rounded-md`}
                  >
                    Rs. {variant.price}
                  </div>
                </button>
              ))}
            </div>
          </section>
        )}

      {variantsAddonsSelectable.addons.length > 0 && (
        <section className="mt-2 mb-4 mx-3">
          <p className="w-full text-center text-base mb-2 font-semibold">
            Toppings
          </p>
          <div className="grid grid-cols-3 gap-4">
            {variantsAddonsSelectable.addons.map((addon) => (
              <button
                onClick={() => {
                  if (
                    selectedProductOptions.selectedAddons.some(
                      (each) => each === addon
                    )
                  ) {
                    setPrice({ ...price, addons: price.addons - addon.price });
                    setSelectedProductOptions((prev) => ({
                      ...prev,
                      selectedAddons: prev.selectedAddons.filter(
                        (each) => each !== addon
                      ),
                    }));
                  } else {
                    setPrice({ ...price, addons: price.addons + addon.price });
                    setSelectedProductOptions((prev) => ({
                      ...prev,
                      selectedAddons: prev.selectedAddons.concat(addon),
                    }));
                  }
                }}
                key={addon.id}
                className={`${
                  selectedProductOptions.selectedAddons.some(
                    (each) => each === addon
                  )
                    ? "bg-blue/70 text-white"
                    : "bg-blue-grey text-grey"
                } transition ease-in-out p-1 m-2 rounded-md font-medium`}
              >
                {addon.name} (Rs.{addon.price})
              </button>
            ))}
          </div>
        </section>
      )}

      <footer className="bg-blue-grey flex justify-between items-center p-3 rounded-b-md">
        <p className="text-base font-semibold">{`Rs. ${(
          price.variant + price.addons
        ).toFixed(2)}`}</p>
        <div className="flex justify-evenly">
          <button
            onClick={() => setVariantsAddonsSelectable(null)}
            className="bg-white text-grey py-3 px-2 rounded-md mr-6 font-semibold"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              handleProductWithOptions(price)
              if(!errorObject) {
                setVariantsAddonsSelectable(null)
              }
            }}
            className="bg-blue text-white px-2 py-3 rounded-md font-semibold"
          >
            Save
          </button>
        </div>
      </footer>
    </section>
  );
};

export default ProductSelect;
