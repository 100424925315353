import React from 'react'

const DayHeader = () => {
  return (
    <div className="pt-2 grid grid-cols-7 text-xs text-center gap-3 px-4">
        <p>Su</p>
        <p>Mo</p>
        <p>Tu</p>
        <p>We</p>
        <p>Th</p>
        <p>Fr</p>
        <p>Sa</p>
    </div>
  )
}

export default DayHeader