import React from "react";
import moment from "moment";
import { checkDateIsLess } from "../../../common/DateHelpers";
import FullArrow from "../../../assets/FullArrow";

const BottomControlBar = ({
  inputStartDate,
  tempStartDate,
  setInputStartDate,
  setTempStartDate,
  tempEndDate,
  setAddDateInParam,
  setTempEndDate,
  setInputEndDate,
  inputEndDate,
  setStartDate,
  setEndDate,
  setPickerOpen,
  setOtherSelectedDate,
}) => {
  return (
    <div className="w-full flex justify-between mt-4 text-sm">
      <div className="flex items-center">
        <input
          value={inputStartDate}
          onChange={(e) => {
            const val = e.target.value;
            const regex = /^[0-9]{0,2}\/[0-9]{0,2}\/[0-9]{0,2}$/;
            const match = val.match(regex)?.join("");
            if (!match) return;
            console.log(match, val);
            setInputStartDate(match);
          }}
          onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
          onBlur={() => {
            const settableDate = new Date(
              moment(inputStartDate, "DD/MM/YYYY").toDate()
            );
            const newDate = new Date();
            const check = checkDateIsLess(newDate, settableDate);
            if (!settableDate || check) {
              setInputStartDate(moment(tempStartDate).format("DD/MM/YY"));
              return;
            }
            setTempStartDate(settableDate);
          }}
          className="bg-blue-grey p-2 rounded-lg text-center w-32 text-sm mr-2 focus:bg-white focus:outline-blue/50"
        />
        <FullArrow />
        <input
          value={inputEndDate}
          onChange={(e) => {
            const val = e.target.value;
            const regex = /^[0-9]{0,2}\/[0-9]{0,2}\/[0-9]{0,2}$/;
            const match = val.match(regex)?.join("");
            if (!match) return;
            console.log(match, val);
            setInputEndDate(match);
          }}
          onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
          onBlur={() => {
            const settableDate = new Date(
              moment(inputEndDate, "DD/MM/YYYY").toDate()
            );
            const newDate = new Date();
            const check = checkDateIsLess(newDate, settableDate);
            if (!settableDate || check) {
              setInputEndDate(moment(tempEndDate).format("DD/MM/YY"));
              return;
            }
            setTempEndDate(settableDate);
          }}
          className="ml-1 bg-blue-grey p-2 rounded-lg text-center w-32 text-sm mr-2 focus:bg-white focus:outline-blue/50"
        />
      </div>
      <div>
        <button onClick={() => {
          setAddDateInParam(false)
          setPickerOpen(0)
          }} className="mr-3 p-2 bg-background text-cyan rounded-md">
          Cancel
        </button>
        <button onClick={() => {
            setOtherSelectedDate("")
            setStartDate(tempStartDate)
            setEndDate(tempEndDate)
            setAddDateInParam(true)
            setPickerOpen(0)
        }} className="p-2 bg-blue text-white rounded-md">Set Date</button>
      </div> 
    </div>
  );
};

export default BottomControlBar;
