import React from 'react'
import CheckboxTick from '../../assets/CheckboxTick'

const Checkbox = ({ checked, onClick }) => {
  return (
    <div onClick={onClick} className="w-5 h-5 cursor-pointer border border-blue rounded-md flex justify-center items-center">
       { checked && <CheckboxTick /> }
    </div>
  )
}

export default Checkbox