import React from 'react'
import ContentLoader from 'react-content-loader'

const KitchenLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="full"
      height="70vh"
      backgroundColor="#ffffff"
      foregroundColor="#F3F7FF"
      className="mt-5"
    >
      <rect x="0vw" y="5" rx="5" ry="5" width="170" height="160" />
      <rect x="180" y="5" rx="5" ry="5" width="170" height="160" />
      <rect x="360" y="5" rx="5" ry="5" width="170" height="160" />
      <rect x="540" y="5" rx="5" ry="5" width="170" height="160" />
      
      <rect x="0" y="180" rx="5" ry="5" width="170" height="160" />
      <rect x="180" y="180" rx="5" ry="5" width="170" height="160" />
      <rect x="369" y="180" rx="5" ry="5" width="170" height="160" />

      <rect x="0vw" y="360" rx="5" ry="5" width="170" height="160" />
      <rect x="180" y="360" rx="5" ry="5" width="170" height="160" />

    </ContentLoader>
  )
}

export default KitchenLoader