import React from "react";
import ContentLoader from "react-content-loader";

const CategoryLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="full"
      height={50}
      backgroundColor="#ffffff"
      foregroundColor="#ecebeb"
    >
      <rect x="0vw" y="5" rx="5" ry="5" width="4vw" height="16" />
      <rect x="8vw" y="5" rx="5" ry="5" width="6vw" height="16" />
      <rect x="18vw" y="5" rx="5" ry="5" width="4vw" height="16" />
      <rect x="24vw" y="5" rx="5" ry="5" width="6vw" height="16" />
      <rect x="34vw" y="5" rx="5" ry="5" width="4vw" height="16" />
      <rect x="40vw" y="5" rx="5" ry="5" width="6vw" height="16" />

      <rect x="2vw" y="34" rx="5" ry="5" width="6vw" height="16" />
      <rect x="12vw" y="34" rx="5" ry="5" width="4vw" height="16" />
      <rect x="18vw" y="34" rx="5" ry="5" width="6vw" height="16" />
      <rect x="28vw" y="34" rx="5" ry="5" width="4vw" height="16" />
      <rect x="34vw" y="34" rx="5" ry="5" width="6vw" height="16" />
      <rect x="44vw" y="34" rx="5" ry="5" width="4vw" height="16" />
    </ContentLoader>
  );
};

export default CategoryLoader;
