import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { navItems, reportsNavObject } from "./NavItems";
import ItemLink from "./ItemLink";
import { AuthApi } from "../../apis/Accounts/Auth";
import { debounce } from "lodash";
import { ProfileApi } from "../../apis/Accounts/Profile";
import ClipLoader from "react-spinners/ClipLoader";
import { useRecoilState } from "recoil";
import { IsOwnerAtom } from "../../common/Atoms";
import logo from "../../assets/poslogo.png";
function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const [openNavigationModal, setOpenNavigationModal] = useState({});
  const [openDropdown, setOpenDropdown] = useState({});
  const [mouseMovedValue, setMouseMovedValue] = useState(null);
  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOwner, setIsOwner] = useRecoilState(IsOwnerAtom)

  const handleSignOut = async () => {
    setLoading(true);
    await AuthApi.logout();
    localStorage.removeItem("access");
    window.location.href = "/";
  };

  const fetchUserDetails = async () => {
    const { data: response } = await ProfileApi.show();
    const firstName = response.data.first_name;
    const lastName = response.data.last_name;
    const userNameVal = response.data.username;
    setIsOwner(response.data.is_owner);
    setFullName(`${firstName} ${lastName}`);
    setUsername(userNameVal);
  };

  const delayedSetter = useCallback(
    debounce(
      (openNavigationModal, mouseMovedValue) =>
        Object.keys(openNavigationModal).length > 0 &&
        mouseMovedValue === null &&
        setOpenNavigationModal({}),
      100
    ),
    []
  );

  useEffect(() => {
    delayedSetter(openNavigationModal, mouseMovedValue);
  }, [mouseMovedValue]);

  // useEffect(() => {
  //   if(isOpen) {
  //     const currentUrl = navItems.filter((nav) => window.location.href.includes(nav.to))
  //     setOpenDropdown(currentUrl?.[0])
  //   }
  // }, [isOpen])

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <motion.div
      animate={{ width: isOpen ? "15rem" : "5rem" }}
      transition={{ delay: 0.1, duration: 0.3 }}
      className="w-fit h-screen bg-white sticky top-0 left-0 z-20 flex flex-col items-center"
    >
      <motion.button
        onClick={() => {
          setIsOpen(!isOpen);
          setOpenNavigationModal({});
          setOpenDropdown({});
        }}
        animate={{ width: isOpen ? "10rem" : "3rem" }}
        transition={{ delay: 0.1, duration: 0.3 }}
        className=" my-4 h-[60px]  rounded-lg text-white mx-8 "
      >
        {
          isOpen ? <img className="overflow-hidden h-full w-full pt-2 object-cover" src={logo} alt="logo" /> : <p className="text-[#442512] text-3xl font-bold font-['cursive'] ">TSB</p>
        }

      </motion.button>
      <motion.div
        onMouseLeave={() => !isOpen && setMouseMovedValue(null)}
        onMouseMove={(e) => !isOpen && setMouseMovedValue(e)}
        animate={{ width: isOpen ? "15rem" : "5rem" }}
        transition={{ delay: 0.1, duration: 0.3 }}
        className="px-4 flex-col items-center  h-[1500vh] overflow-auto scrollbar-hide"
      >
        {(isOwner ? navItems.concat(reportsNavObject) : navItems).map((item) => (
          <ItemLink
            key={item.name}
            item={item}
            isOpen={isOpen}
            setOpenNavigationModal={setOpenNavigationModal}
            openNavigationModal={openNavigationModal}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          />
        ))}
      </motion.div>

      <div className="h-full"></div>

      <motion.div
        animate={{ borderRadius: isOpen ? 0 : "50%" }}
        transition={{ delay: 0.2, duration: 0.3 }}
        className="bg-blue-grey w-fit mb-4 flex flex-col items-center px-3 py-4 rounded-xl"
      >
        <div className="h-12 w-12 bg-white rounded-full "></div>
        {isOpen && (
          <>
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "fit-content" }}
              transition={{ delay: 0.4, duration: 0.2 }}
              className="text-black font-semibold mt-3"
            >
              {fullName}
            </motion.div>
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "fit-content" }}
              transition={{ delay: 0.4, duration: 0.2 }}
              className="text-grey"
            >
              {username}
            </motion.div>
          </>
        )}
      </motion.div>

      {isOpen ? (
        <>
          {!loading ? (
            <motion.div
              onClick={handleSignOut}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
              className="mb-6 text-grey font-bold cursor-pointer"
            >
              Sign out
            </motion.div>
          ) : (
            <div className="mb-6">
              {" "}
              <ClipLoader size="15" />
            </div>
          )}
        </>
      ) : (
        <div className="mb-16"></div>
      )}
    </motion.div>
  );
}

export default Sidebar;
