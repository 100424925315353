import { authenticatedAxiosInstance as axios } from "../axios"

const create = ( tableAreaId, payload) => axios.post(`/areas/${tableAreaId}/tables`, payload)
const update = (tableAreaId, id, payload) => axios.patch(`/areas/${tableAreaId}/tables/${id}`, payload)
const destroy = (tableAreaId, id) => axios.delete(`/areas/${tableAreaId}/tables/${id}`)

export const TablesApi = {
    create,
    update,
    destroy
}