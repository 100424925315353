import React from "react";
import DropdownField from "../../utils/form/DropdownField";
import { motion } from "framer-motion";

const FilterModal = ({ setFilterBy, filterBy, filterOptions }) => {
  return (
    <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.2 }}
    exit={{ opacity: 0 }}
    className="relative top-2 -left-[240px] z-[61] bg-white w-80 h-fit p-4 rounded text-sm">
      <DropdownField
        heading="Payment Method"
        options={filterOptions}
        selected={filterBy}
        setSelected={value => setFilterBy(prev => prev?.id === value?.id ? null : value)}
        fromFilter
      />
    </motion.div>
  );
};

export default FilterModal;
