import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ModalBg from "../helperComponents/ModalBg";
import RoundedClose from "../assets/RoundedClose";
import ItemControl from "../utils/ItemControl";
import useCart from "../hooks/useCart";
import CreateCombination from "../utils/CreateCombination";

const AddonManage = ({ product, variant = null }) => {
  const [showModal, setShowModal] = useState(false);
  const [combinationAddons, setCombinationAddons] = useState([]);
  const [showCreateComb, setShowCreateComb] = useState(false);
  const variantName = variant?.options.join(", ");
  const productName = `${product.title} ${variant ? `(${variantName})` : ""}`;
  const settedVariant = variant || product.variants[0];
  const [cart, setCart, operations] = useCart();
  const productQuantity = operations.getQuantity("variant", settedVariant.id);
  const realAddons = product.addons;
  const addons = realAddons.map((each) => ({ ...each, id: [each.id] }));

  const handleClose = () => setShowModal(false);

  useEffect(() => {
    document.body.style.overflow = showModal ? "hidden" : "auto";
  }, [showModal]);

  useEffect(() => {
    if (addons.length === 2) {
      const id = addons.map((each) => each.id).sort();
      const name = addons.map((each) => each.name).join(" and ");
      const price = String(
        addons.reduce((acc, cur) => Number(acc) + Number(cur.price), 0)
      );
      setCombinationAddons([{ id, name, price }]);
    }
  }, [cart]);

  return (
    <>
      <div className="w-fit px-2 pt-4">
        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setShowModal(true)}
          // transition={{ duration: 0.4 }}
          className="bg-yellow text-white font-semibold px-2 py-1 rounded-lg text-xxs"
        >
          + Toppings
        </motion.button>
      </div>
      {showModal && (
        <div className="h-0">
          <ModalBg handleCancel={handleClose}>
            <div
              onClick={(e) => e.stopPropagation()}
              className="bg-white max-w-2xl w-full h-[80vh] rounded-md pointer-events-auto mx-3 my-5 overflow-y-auto"
            >
              <div className="flex bg-yellow font-semibold pb-4 px-3 pt-3 mb-3 text-white justify-between sticky top-0 items-center text-bold stroke-white">
                Add Toppings
                <RoundedClose handleCancel={handleClose} size={16} />
              </div>
              <div className="my-2 flex px-3 justify-between items-center text-xs font-semibold">
                <p>{productName}</p>
                <ItemControl
                  dontShowAdd
                  value={productQuantity}
                  increment={() =>
                    operations.incrementQuantity("variant", settedVariant.id)
                  }
                  decrement={() => {
                    operations.decrementQuantity("variant", settedVariant.id);
                    if (productQuantity === 1) {
                      setShowModal(false);
                    }
                  }}
                />
              </div>
              <div className="pb-3 px-3 text-xs">
                <p className="flex justify-center font-bold text-sm pb-5">
                  Toppings
                </p>
                {product.addons.length > 2 &&
                  (showCreateComb ? (
                    <CreateCombination
                      list={product.addons}
                      value={combinationAddons}
                      setter={setCombinationAddons}
                      showComb={setShowCreateComb}
                    />
                  ) : (
                    <button
                      onClick={() => setShowCreateComb(true)}
                      className="mb-5 px-2 bg-tuxedo/80 text-white rounded-md py-1"
                    >
                      + Create Toppings Combination
                    </button>
                  ))}
                {addons.map((addon) => (
                  <div className="flex justify-between py-1">
                    <div>
                      <p>{addon.name}</p>
                      <p className="text-xxs">Rs {addon.price}</p>
                    </div>
                    <ItemControl
                      small
                      value={operations.getThisAddonQuantity(
                        "variant",
                        settedVariant.id,
                        addon.id
                      )}
                      cannotAdd={
                        operations.getAddonsQuantity(settedVariant.id) >=
                        productQuantity
                      }
                      add={() => {
                        if (
                          operations.getAddonsQuantity(settedVariant.id) <
                          productQuantity
                        ) {
                          operations.addAddon(
                            "variant",
                            settedVariant.id,
                            addon
                          );
                        }
                      }}
                      decrement={() => {
                        operations.decrementAddonQuantity(
                          "variant",
                          settedVariant.id,
                          addon.id
                        );
                      }}
                      increment={() => {
                        if (
                          operations.getAddonsQuantity(settedVariant.id) <
                          productQuantity
                        ) {
                          operations.incrementAddonQuantity(
                            "variant",
                            settedVariant.id,
                            addon.id
                          );
                        }
                      }}
                    />
                  </div>
                ))}
                {combinationAddons.map((addon) => (
                  <div className="flex justify-between py-1">
                    <div>
                      <p>{addon.name}</p>
                      <p className="text-xxs">Rs {addon.price}</p>
                    </div>
                    <ItemControl
                      small
                      value={operations.getThisAddonQuantity(
                        "variant",
                        settedVariant.id,
                        addon.id
                      )}
                      cannotAdd={
                        operations.getAddonsQuantity(settedVariant.id) >=
                        productQuantity
                      }
                      add={() => {
                        if (
                          operations.getAddonsQuantity(settedVariant.id) <
                          productQuantity
                        ) {
                          operations.addAddon(
                            "variant",
                            settedVariant.id,
                            addon
                          );
                        }
                      }}
                      decrement={() => {
                        operations.decrementAddonQuantity(
                          "variant",
                          settedVariant.id,
                          addon.id
                        );
                      }}
                      increment={() => {
                        if (
                          operations.getAddonsQuantity(settedVariant.id) <
                          productQuantity
                        ) {
                          operations.incrementAddonQuantity(
                            "variant",
                            settedVariant.id,
                            addon.id
                          );
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </ModalBg>
        </div>
      )}
    </>
  );
};

export default AddonManage;
