import React, { useEffect, useState } from "react";
import RoundedTable from "../../assets/RoundedTable";
import AreaTile from "../../utils/AreaTile";
import { AnimatePresence, motion } from "framer-motion";
import Modal from "../../helperComponents/Modal";
import { TableAreasApi } from "../../apis/TableArea";
import Form from "../../forms/TableArea";
import { toSafeInteger } from "lodash";

const TableAreaList = ({
  tableArea,
  editMode,
  selectedArea,
  setEditMode,
  setSelectedArea,
  setTableArea,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [errorObject, setErrorObject] = useState({})

  const handleAreaTileClick = (area) => {
    if (!editMode) {
      setSelectedArea(area.name);
      return;
    }
    setOpenModal(area.id);
  };

  const handleCreate = async () => {
    const payload = {
      name,
      code,
    };
    const { data: response } = await TableAreasApi.create(payload);
    if (!response.data.message) {
      setOpenModal(false);
      setTableArea((prev) => [
        ...prev,
        { id: response.data.id, name, code, tables: [] },
      ]);
    } else {
      setErrorObject(response.data.message)
    }
  };

  const handleDeleteArea = async (id) => {
    const filteredArea = tableArea.filter((area) => area.id !== id);
    setTableArea(filteredArea);
    await TableAreasApi.destroy(id);
  };

  const handleUpdate = async () => {
    const payload = {
      name,
      code,
    };
    const { data: response } = await TableAreasApi.update(openModal, payload);
    if (!response.data.message) {
    const updatedTableArea = tableArea.map((area) =>
      area.id === openModal ? { ...area, name, code } : area
    );
    setTableArea(updatedTableArea);
    setOpenModal(false);
    } else {
      setErrorObject(response.data.message)
    }
  };

  useEffect(() => {
    if (openModal && typeof openModal !== "boolean") {
      const editableArea = tableArea.find((area) => area.id === openModal);
      setName(editableArea.name);
      setCode(editableArea.code);
    } else {
      setName("");
      setCode("");
    }
    setErrorObject({})
  }, [openModal]);

  return (
    <section className="w-full flex justify-between mt-6">
      <div className="flex">
        <AnimatePresence>
          {tableArea.map((area) => (
            <AreaTile
              key={area.id}
              area={area.name}
              onClick={() => handleAreaTileClick(area)}
              selected={selectedArea === area.name}
              editMode={editMode}
              id={area.id}
              handleDeleteArea={handleDeleteArea}
            />
          ))}
        </AnimatePresence>
        {editMode && (
          <motion.button
            whileHover={{ scale: 1.1 }}
            onClick={() => setOpenModal(true)}
            className="h-8 px-4 flex items-center justify-center bg-grey/60 text-white rounded-lg text-xl "
          >
            +
          </motion.button>
        )}
      </div>
      {selectedArea === "All" && (
        <button
          onClick={() => setEditMode((prev) => !prev)}
          className="h-8 bg-blue py-1 px-2 flex justify-between items-center rounded-md"
        >
          <RoundedTable />
          <p className="text-sm text-white font-semibold ml-2">{editMode ? "Save" : "Edit"}</p>
        </button>
      )}
      <AnimatePresence>
        {openModal && (
          <Modal
            heading="Add New Area"
            handleCancel={() => setOpenModal(false)}
          >
            <Form
              name={name}
              setName={setName}
              code={code}
              setCode={setCode}
              errorObject={errorObject}
              setErrorObject={setErrorObject}
              setOpenModal={setOpenModal}
              handleSave={
                typeof openModal === "boolean" ? handleCreate : handleUpdate
              }
            />
          </Modal>
        )}
      </AnimatePresence>
    </section>
  );
};

export default TableAreaList;
