import Login from "./screens/Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "./Navigation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OrderOnTable from "./screenOnMobile/OrderOnTable";
import { RecoilRoot } from "recoil";

function App() {
  return (
    <div className="App select-none">
      <ToastContainer
        position="bottom-left"
        theme="colored"
        newestOnTop={false}
        closeOnClick
        pauseOnHover
        rtl={false}
      />
      <RecoilRoot>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/customer-order-test" element={<OrderOnTable />} />
            <Route path="/*" element={<Navigation />} />
          </Routes>
        </Router>
      </RecoilRoot>
    </div>
  );
}

export default App;
