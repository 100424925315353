import React from "react";
import Back from "../../assets/Back";

const Header = ({ heading, setModalOpen }) => {
  return (
    <div className="flex justify-between items-center">
      <div className="text-blue font-semibold text-lg">{heading}</div>
      <div className="cursor-pointer" onClick={setModalOpen}>
        <Back />
      </div>
    </div>
  );
};

export default Header;
