import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { motion } from "framer-motion";
import Form from "./Form";

const AddCustomerModal = ({ setShowCustomerAddModal, editableCustomer }) => {
  return createPortal(
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.4 }}
        exit={{ opacity: 0 }}
      >
        <div className="w-screen h-screen fixed top-0 left-0 z-[30] bg-grey "></div>
        <div onClick={() => setShowCustomerAddModal(false)} className="w-screen h-screen fixed top-0 left-0 z-[32] backdrop-blur-sm"></div>
      </motion.div>

      <motion.div 
      initial={{ x: "100%"}}
      animate={{ x: 0 }}
      transition={{ duration: 0.6 }}
      exit={{ x: "100%" }}
      className="w-[30%] h-screen fixed top-0 right-[30%] bg-blue-grey z-[15] p-10">
        <Form setShowCustomerAddModal={setShowCustomerAddModal} editableCustomer={editableCustomer} />
      </motion.div>
    </>,
    document.getElementById("modal")
  );
};

export default AddCustomerModal;
