import React, { useContext, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { Payment } from "../../screens/Tables";
import CardSection from "./Section/Card";
import CashSection from "./Section/Cash";
import OnlineSection from "./Section/Online";
import SplitSection from "./Section/SplitView";
import { AnimatePresence, motion } from "framer-motion";
import { AppApi } from "../../apis/Core";
import {
  BillPrintableValueAtom,
  InnerHtml,
  PayableAmountAtom,
  PaymentCompleteStatusAtom,
  PaymentFromPayLater,
  PaymentOrderIdAtom,
} from "../../common/Atoms";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { PaymentApi } from "../../apis/Payment";
import { PayLaterApi } from "../../apis/Orders.js/PayLater";
import { toast } from "react-toastify";
import Modal from "../Modal";

const PaymentPane = () => {
  const [paymentMethod, setPaymentMethod] = useState();
  const [availableMethods, setAvailableMethods] = useState([]);

  const [onlineMethod, setOnlineMethod] = useState();
  const [people, setPeople] = useState([{}, {}]);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [confirmPaymentDisable, setConfirmPaymentDisable] = useState(false);
  const { setShowPaymentPanel } = useContext(Payment);
  const [payableAmount, setPayableAmount] = useRecoilState(PayableAmountAtom);
  const [orderId, setOrderId] = useRecoilState(PaymentOrderIdAtom);
  const [fromPayLater, setFromPayLater] = useRecoilState(PaymentFromPayLater);
  const setPaymentCompleteStatus = useSetRecoilState(PaymentCompleteStatusAtom);
  const [payedAmount, setPayedAmount] = useState(payableAmount);
  const fetchAppInfo = async () => {
    const { data: response } = await AppApi.info();
    
    let splitObject = { name: "SPLIT" };
    if (!fromPayLater) {
      response.data.payment_types.forEach((type) => {
        splitObject = { ...splitObject, [type.name]: type };
      });
    }
    const settable = fromPayLater
      ? response.data.payment_types
      : response.data.payment_types.concat(splitObject);
    setAvailableMethods(settable);
    setPaymentMethod(response.data.payment_types[0]);
  };

  const clearData = () => {
    setShowPrintModal(false);
    setPayableAmount(0);
    setOrderId(null);
    setShowPaymentPanel(false);
    // setPrintableValues(billPrintable)
  };

  const handleCashPayment = () => [
    {
      payment_type: paymentMethod.id,
      amount: payedAmount > payableAmount ? payableAmount : payedAmount,
      
    },
    
  ];

  const handleCardPayment = () => [
    {
      payment_type: paymentMethod.id,
      amount: payedAmount > payableAmount ? payableAmount : payedAmount,
    },
  ];

  const handleOnlinePayment = () => [
    {
      payment_type: onlineMethod.id,
      amount: payedAmount > payableAmount ? payableAmount : payedAmount,
    },
  ];

  const x = availableMethods.map((el, i) => {
    return el.childrens;
  });


  const handleSplitPayment = () => {
    const tempPeople = people.filter(
      (each) => JSON.stringify(each) !== JSON.stringify({})
    );
    console.log(tempPeople, "tempPeople");
    return tempPeople.map((person) => ({
      payment_type: person.type?.id,
      amount: person.amount,
    }));
  };

  const handlePayment = async () => {
    // if (confirmPaymentDisable) return;
    // setConfirmPaymentDisable(true);
    let paymentObjectsList;
    if (paymentMethod.name === "CASH") paymentObjectsList = handleCashPayment();
    else if (paymentMethod.name === "CARD")
      paymentObjectsList = handleCardPayment();
    else if (paymentMethod.name === "ONLINE")
      paymentObjectsList = handleOnlinePayment();
    else if (paymentMethod.name === "SPLIT")
      paymentObjectsList = handleSplitPayment();
    const amountIsNotSet = paymentObjectsList.some(
      (paymentObject) => !paymentObject.amount
    );
    const typeIsNotSet = paymentObjectsList.some(
      (paymentObject) => !paymentObject.payment_type
    );
    if (amountIsNotSet) toast.error("Payment amount is not specified");
    if (typeIsNotSet) toast.error("Payment type is not specified");
    if (amountIsNotSet || typeIsNotSet) {
      setConfirmPaymentDisable(false);
      return;
    }
    let response;
    if (fromPayLater) {
      const responseData = await PayLaterApi.pay(fromPayLater, {
        payments: paymentObjectsList,
      });
      response = responseData.data;
    } else {
      const responseData = await PaymentApi.pay(orderId, {
        payments: paymentObjectsList,
      });
      response = responseData.data;

    }
    console.log(response ,'response');
    if (response.data.message) {
      toast.error(JSON.stringify(response.data.message));
      return;
    } else {
      if (fromPayLater) setFromPayLater("");
      setPaymentCompleteStatus(true);
      setConfirmPaymentDisable(false);
      clearData();
    }
  };

  useEffect(() => {
    setPayableAmount(Math.round(payableAmount).toFixed(2));
    fetchAppInfo();
  }, []);

  useEffect(() => {
    if (paymentMethod) {
      // console.log("here it is working");
      const method = paymentMethod.childrens?.find(
        (each) => each.name === "OTHERS"
      );
      setOnlineMethod(method);
    }
  }, [paymentMethod]);


  
  return createPortal(
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.4 }}
        exit={{ opacity: 0 }}
      >
        <div className="w-screen h-screen fixed top-0 left-0 z-[30] bg-grey "></div>
        <div className="w-screen h-screen fixed top-0 left-0 z-[32] backdrop-blur-sm"></div>
      </motion.div>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: 0 }}
        transition={{ duration: 0.6 }}
        exit={{ x: "100%" }}
        className="w-[30%] h-screen bg-blue-grey flex flex-col fixed top-0 right-0 z-50 p-5"
      >
        <p className="font-semibold mt-5 mb-1">Payment</p>
        <p className="text-xs text-blue mb-5">4 payment methods available</p>
        <div className="border-t py-10 ">
          <p className="font-semibold text-grey mb-4">Payment methods</p>
          <div className="flex justify-between w-[75%]">
            {availableMethods?.map((method) => (
              <div
                onClick={() =>{console.log('dvkdfnjv'); setPaymentMethod(method)}}
                className={`py-1 px-4 cursor-pointer ${
                  paymentMethod?.id === method?.id &&
                  paymentMethod?.name === method?.name
                    ? "bg-green text-white"
                    : "bg-white text-grey"
                } text-sm rounded-md`}
              >
                {method?.name}
              </div>
            ))}
            {/* <div
              onClick={() => setPaymentMethod("cash")}
              className={`py-1 px-4 cursor-pointer ${
                paymentMethod === "cash"
                  ? "bg-green text-white"
                  : "bg-white text-grey"
              } text-sm rounded-md`}
            >
              Cash
            </div>
            <div
              onClick={() => setPaymentMethod("card")}
              className={`py-1 px-4 cursor-pointer ${
                paymentMethod === "card"
                  ? "bg-green text-white"
                  : "bg-white text-grey"
              } text-sm rounded-md`}
            >
              Card
            </div>
            <div
              onClick={() => setPaymentMethod("online")}
              className={`py-1 px-4 cursor-pointer ${
                paymentMethod === "online"
                  ? "bg-green text-white"
                  : "bg-white text-grey"
              } text-sm rounded-md`}
            >
              Online
            </div>
            <div
              onClick={() => setPaymentMethod("split")}
              className={`py-1 px-4 cursor-pointer ${
                paymentMethod === "split"
                  ? "bg-green text-white"
                  : "bg-white text-grey"
              } text-sm rounded-md`}
            >
              Split
            </div> */}
          </div>
          <div className="mt-16">
            {paymentMethod?.name === "CASH" && (
              <CashSection
                payedAmount={payedAmount}
                setPayedAmount={setPayedAmount}
                setConfirmPaymentDisable={setConfirmPaymentDisable}
              />
            )}
            {paymentMethod?.name === "CARD" && (
              <CardSection
                setPayedAmount={setPayedAmount}
                payedAmount={payedAmount}
                setConfirmPaymentDisable={setConfirmPaymentDisable}
              />
            )}
            {paymentMethod?.name === "ONLINE" && (
              <OnlineSection
                payedAmount={payedAmount}
                setPayedAmount={setPayedAmount}
                paymentMethod={paymentMethod}
                onlineMethod={onlineMethod}
                setOnlineMethod={setOnlineMethod}
                setConfirmPaymentDisable={setConfirmPaymentDisable}
              />
            )}
            {paymentMethod?.name === "SPLIT" && (
              <SplitSection
                paymentMethod={paymentMethod}
                people={people}
                setPeople={setPeople}
                setConfirmPaymentDisable={setConfirmPaymentDisable}
              />
            )}
          </div>
        </div>
        <div className="flex justify-center items-end flex-grow">
          <div
            onClick={() => {
              if (fromPayLater) setFromPayLater("");
              setShowPaymentPanel(false);
              setPayableAmount(null);
            }}
            className="py-3.5 px-5 mr-6 cursor-pointer bg-white text-grey font-semibold hover:bg-red hover:text-white rounded-lg"
          >
            Cancel
          </div>
          <div
            onClick={handlePayment}
            className={`py-3.5 px-5 font-semibold ${
              !confirmPaymentDisable
                ? "bg-white/40 text-grey/30 cursor-not-allowed"
                : "bg-white text-grey hover:bg-blue hover:text-white cursor-pointer"
            } rounded-lg`}
          >
            Confirm Payment
          </div>
        </div>
      </motion.div>
    </>,
    document.getElementById("modal")
  );
};

export default PaymentPane;
