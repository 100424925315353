import React from "react";

const NoView = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M7.60008 3.82701C8.05897 3.7196 8.52879 3.6659 9.00008 3.66701C13.6667 3.66701 16.3334 9.00034 16.3334 9.00034C15.9287 9.75741 15.4461 10.4702 14.8934 11.127M10.4134 10.4137C10.2303 10.6102 10.0095 10.7678 9.76418 10.8771C9.51885 10.9864 9.25402 11.0452 8.98547 11.0499C8.71693 11.0547 8.45019 11.0053 8.20115 10.9047C7.95212 10.8041 7.7259 10.6544 7.53598 10.4644C7.34606 10.2745 7.19634 10.0483 7.09575 9.79927C6.99516 9.55023 6.94577 9.28349 6.9505 9.01495C6.95524 8.74641 7.01402 8.48157 7.12333 8.23624C7.23264 7.99091 7.39025 7.77011 7.58675 7.58701M12.9601 12.9603C11.8205 13.829 10.4328 14.3103 9.00008 14.3337C4.33341 14.3337 1.66675 9.00034 1.66675 9.00034C2.49601 7.45494 3.64617 6.10475 5.04008 5.04034L12.9601 12.9603Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66675 1.66699L16.3334 16.3337"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NoView;
