import React, { useState } from "react";
import VariantInputField from "../utils/form/VariantInputField";
import InputField from "../utils/form/InputField";
import { motion } from "framer-motion";

const VariationForm = ({ options, setOptions, setVariantsData, setShowAddVariant, editableId, setEditableId }) => {
  const [inputRows, setInputRows] = useState(editableId !== null ? options[editableId].values : ["", ""]);
  const [optionName, setOptionName] = useState(editableId !== null ? options[editableId].name: "");
  const handleSubmit = () => {
    if (inputRows.length > 0 && optionName !== "") {
      const newOption = {
        name: optionName,
        values: inputRows,
      };
      if(editableId !== null){
        const changableVariants = options.map((variant, id) => editableId === id ? newOption : variant)
        // setVariantsData([])
        setOptions(changableVariants)
      } else {
        // setVariantsData([])
        setOptions((prev) => [...prev, newOption]);
      }
    }
    setShowAddVariant(false);
    setEditableId(null)
  };

  return (
    <motion.div
      initial={{ height: 0 }}
      animate={{ height: "fit-content" }}
      transition={{ duration:0.2 }}
      className="px-3 w-full border-b border-gray-300 py-5"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{duration: 0.2 }}
        className="w-full"
      >
        <div className=" font-semibold mb-2 text-grey">Option Name</div>
        <InputField input={optionName} setInput={setOptionName} />
      </motion.div>
      <div className="w-full mt-2">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
          className=" font-semibold mb-2 text-grey"
        >
          Option Value
        </motion.div>
        {inputRows.map((value, index) => (
          <VariantInputField
            inputRows={inputRows}
            // delay={inputRows.length === 1 || editableId !== null ? 0.2 * (index + 1) + (0.2/(index+1)) : 0}
            setInputRows={setInputRows}
            index={index}
            last={index === inputRows.length - 1}
          />
        ))}
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.2 }}
        onClick={handleSubmit}
        className="px-2 py-1 bg-blue text-white font-semibold cursor-pointer w-fit rounded-md mt-4"
      >
        Done
      </motion.div>
    </motion.div>
  );
};

export default VariationForm;
