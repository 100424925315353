import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import Pen from "../assets/Pen";
import Trash from "../assets/Trash";
import ManagementTable from "../helperComponents/ManagementTable";
import { customerAtom, SalesCustomerAtom } from "../common/Atoms";
import { useRecoilState } from "recoil";
import { CustomersApi } from "../apis/Customers";
import Modal from "../helperComponents/Modal";
import { AnimatePresence } from "framer-motion";
import { CustomersContext } from "../screens/Customers";
import UpwardArrow from "../assets/UpwardArrow";
import Info from "../assets/Info";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CustomerManagementTable = ({ setEditableCustomer, setShowModal }) => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [initial, setInitial] = useState(true);
  const [sortValue, setSortValue] = useState("");
  const [customers, setCustomers] = useRecoilState(customerAtom);
  const [handleDeletePrompt, setHandleDeletePrompt] = useState(null);
  const [salesReportCustomer, setSalesReportCustomer] =
    useRecoilState(SalesCustomerAtom);
  const { totalCount, setTotalCount, pageLength, searchString } =
    useContext(CustomersContext);
  const navigate = useNavigate();

  const handleDelete = async (id) => {
    try {
      await CustomersApi.destroy(id);
      fetchCustomers();
      toast.success("Deleted Successfully");
    } catch (e) {
      toast.error(`Some issue, ${e}`);
    }
    setHandleDeletePrompt(null);
  };

  const fetchCustomers = useCallback(async () => {
    let params = { length: pageLength, page, search: searchString };
    if (sortValue) params.sort_by = sortValue;
    const { data: response } = await CustomersApi.list({
      params,
    });
    setCustomers(response.data.results);
    setTotalCount(response.data.count);
    setLoading(false);
  }, [page, pageLength, searchString, handleDeletePrompt, sortValue]);

  const CUSTOMERS = customers.map((customer) => ({
    id: customer.id,
    customer: customer,
    name: customer.first_name + " " + customer.last_name,
    address: `${customer.street1 || ""} ${customer.street2 || ""}${
      customer.city ? ", " : ""
    }${customer.city || ""}${customer.state ? ", " : ""}${
      customer.state || ""
    }`,
    phone: customer.phone,
    email: customer.email,
  }));

  const COLUMN = [
    {
      id: "name",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          Name
          <button
            className={`stroke-white ml-2 ${
              sortValue === "-first_name" ? "" : "rotate-180"
            }`}
            onClick={() =>
              setSortValue((prev) =>
                prev === "first_name" ? "-first_name" : "first_name"
              )
            }
          >
            <UpwardArrow />
          </button>
        </div>
      ),
      accessor: "name",
      Cell: ({ value }) => <div className="font-semibold">{value}</div>,
    },
    {
      id: "address",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          {/* <button
            className={`stroke-white mr-2 ${
              column.isSortedDesc ? "" : "rotate-180"
            }`}
            onClick={() => column.toggleSortBy()}
            >
            <UpwardArrow />
          </button> */}
          Address
        </div>
      ),
      accessor: "address",
    },
    {
      id: "phone",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          {/* <button
            className={`stroke-white mr-2 ${
              column.isSortedDesc ? "" : "rotate-180"
            }`}
            onClick={() => column.toggleSortBy()}
            >
            <UpwardArrow />
          </button> */}
          Phone
        </div>
      ),
      accessor: "phone",
    },
    {
      id: "email",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          Email
          <button
            className={`stroke-white ml-2 ${
              sortValue === "-email" ? "" : "rotate-180"
            }`}
            onClick={() =>
              setSortValue(sortValue === "email" ? "-email" : "email")
            }
          >
            <UpwardArrow />
          </button>
        </div>
      ),
      accessor: "email",
    },
    {
      id: "buttons",
      Header: "Action",
      Cell: ({ row }) => (
        <div className="flex">
          <div
            onClick={() => {
              setSalesReportCustomer(row.original.id);
            }}
            className="stroke-grey cursor-pointer bg-white p-1 mr-4 rounded-md hover:bg-red hover:stroke-white"
          >
            <Info />
          </div>
          <div
            onClick={() => {
              setEditableCustomer(row.original.customer);
              setShowModal(true);
            }}
            className="stroke-grey cursor-pointer bg-white p-1 mr-4 rounded-md hover:bg-red hover:stroke-white"
          >
            <Pen />
          </div>
          <div
            onClick={() => setHandleDeletePrompt(row.original.id)}
            className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white"
          >
            <Trash />
          </div>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMN, [customers]);
  const data = useMemo(() => CUSTOMERS, [customers]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page - 1, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
    },
    useSortBy,
    usePagination
  );

  const { gotoPage, setPageSize } = tableInstance;

  useEffect(() => {
    if (sortValue && !initial) {
      fetchCustomers();
    }
  }, [sortValue]);

  useEffect(() => {
    if (!initial) {
      fetchCustomers();
      gotoPage(page - 1);
    }
  }, [page]);

  useEffect(() => {
    setPageSize(pageLength);
    if (page === 1) {
      fetchCustomers();
    } else {
      setPage(1);
    }
    setInitial(false);
  }, [pageLength, searchString]);

  useEffect(() => {
    if (salesReportCustomer) {
      navigate("/report");
    }
  }, [salesReportCustomer]);

  return (
    <>
      <ManagementTable
        tableInstance={tableInstance}
        setPage={setPage}
        page={page}
        loading={loading}
        setLoading={setLoading}
        totalCount={totalCount}
      />
      <AnimatePresence>
        {handleDeletePrompt !== null && (
          <Modal
            handleCancel={() => setHandleDeletePrompt(null)}
            heading="Confirm Delete"
          >
            <section className="pt-10 px-5">
              <p className="font-semibold">
                Are you sure you want to delete the customer ?
              </p>
              <div className="flex justify-center mb-10 mt-5">
                <button
                  onClick={() => setHandleDeletePrompt(null)}
                  className="px-3 py-2 mr-3 font-semibold rounded-md bg-blue-grey text-grey"
                >
                  Cancel
                </button>
                <button
                  onClick={() => handleDelete(handleDeletePrompt)}
                  className="bg-red font-semibold text-white rounded-md px-3 py-2"
                >
                  Delete
                </button>
              </div>
            </section>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default CustomerManagementTable;
