import { authenticatedAxiosInstance as axios } from "../axios";

const create = payload => axios.post(`/kitchens`, payload)
const list = () => axios.get("/kitchens")
const show = id => axios.get(`/kitchens/${id}`)
const update = (id, payload) => axios.patch(`/kitchens/${id}`, payload)
const destroy = id => axios.delete(`/kitchens/${id}`)
const addProduct = (kitchen_id, product_id, payload) => axios.delete(`/kitchens/${kitchen_id}/product/${product_id}`)

export const KitchensApi = {
    create,
    list,
    show,
    update,
    destroy,
    addProduct
}
  