import React from "react";
import { useRecoilValue } from "recoil";
import { PayableAmountAtom, PaymentFromPayLater } from "../../../common/Atoms";

const CardSection = ({ payedAmount, setPayedAmount,setConfirmPaymentDisable }) => {
  const payableAmount = useRecoilValue(PayableAmountAtom);
  const fromPayLater = useRecoilValue(PaymentFromPayLater);
  return (
    <div className="w-full pr-5">
      <div className="flex justify-between">
        <div className="text-grey text-sm">Amt. to be paid</div>
        <div className="text-black text-sm w-40">Rs. {payableAmount}</div>
      </div>
      {(
        <div className="flex justify-between mt-5">
          <div className="text-grey text-sm">Amount paid</div>
          <input
            className="text-black text-xs w-40 outline-blue bg-white px-2"
            placeholder="Input amount"
            value={payedAmount}
            onClick={() => setPayedAmount("")}
            onChange={(e) => {
              setPayedAmount(parseFloat(e.target.value) || "")
              setConfirmPaymentDisable(true)
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CardSection;
