import React from "react";
import moment from "moment";

const SeatNow = ({ handleCancel, data, handleFunction = () => {} }) => {
  return (
    <div className="w-full text-sm">
      <div className="px-10 w-full py-8">
        <div className="flex justify-between">
          <div className="w-[200px]">
            <div className="flex justify-center bg-blue-grey rounded-md py-2 px-2">
              Name
            </div>
            <div className="mt-4 bg-blue-grey w-full py-2 px-2 text-grey flex justify-center rounded-md">
              {data.customer_name}
            </div>
          </div>
          <div onClick={handleFunction} className="w-[200px] cursor-pointer">
            <div className="flex justify-center bg-blue-grey rounded-md py-2 px-2">
              No. of Ordered Items
            </div>
            <div className="mt-4 bg-blue-grey w-full py-2 px-2  text-grey flex justify-center rounded-md firstName">
              {data.item_count}
            </div>
          </div>
        </div>
      </div>
      <div className="px-10 w-full pb-6">
        <div className="flex justify-between">
          <div className="w-[200px]">
            <div className="flex justify-center bg-blue-grey rounded-md py-2 px-2">
              Mobile No.
            </div>
            <div className="mt-4 bg-blue-grey w-full py-2 px-2 text-grey flex justify-center rounded-md">
              {data.customer_phone}
            </div>
          </div>
          <div className="w-[200px]">
            <div className="flex justify-center bg-blue-grey rounded-md py-2 px-2">
              Time
            </div>
            <div className="mt-4 bg-blue-grey w-full py-2 px-2  text-grey flex justify-center rounded-md firstName">
              {moment(new Date().getTime() - new Date(data.created_at).getTime()).format("hh:mm")}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full cursor-pointer font-medium bg-blue-grey rounded-lg flex justify-center py-3">
        <div onClick={handleCancel} className="p-2 bg-white text-grey rounded-md mx-2">
            Cancel
        </div>
        <div className="p-2 bg-blue text-white rounded-md mx-2">
            Save
        </div>
      </div>
    </div>
  );
};

export default SeatNow;
