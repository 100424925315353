import React from "react";
import AddCustomer from "../assets/AddCustomer";
import Searchbar from "../utils/Searchbar";
import ShowEntries from "../utils/ShowEntries";

const ManagementControl = ({
  setShowModal = null,
  heading,
  buttonText,
  pageLength,
  setPageLength,
  totalCount,
  setSearchString,
}) => {
  return (
    <>
      <div className="text-3xl font-bold">{heading} </div>
      {setShowModal && (
        <div
          onClick={() => setShowModal(true)}
          className="text-white cursor-pointer bg-blue mt-[1vh] mb-[3vh] font-semibold w-fit flex p-1 rounded-md"
        >
          <AddCustomer />
          <div className="ml-2">Add new {buttonText} </div>
        </div>
      )}
      <div className="w-full flex justify-between">
        <Searchbar setter={setSearchString} />
        <ShowEntries
          setPageLength={setPageLength}
          totalCount={totalCount}
          pageLength={pageLength}
        />
      </div>
    </>
  );
};

export default ManagementControl;
