import { normalAxiosInstance, authenticatedAxiosInstance } from "../axios"

const signup = payload => normalAxiosInstance.post("/accounts/signup", payload)
const login = payload => normalAxiosInstance.post("/accounts/login", payload)
const logout = payload => authenticatedAxiosInstance.post("/accounts/logout", payload)
const refreshToken = payload => normalAxiosInstance.post("/accounts/token/refresh", payload)

export const AuthApi = {
    signup,
    login,
    logout,
    refreshToken
}