import React from "react";
import { useNavigate } from "react-router-dom";
import Triangle from "../../assets/Triangle";
import { motion } from "framer-motion";

const NavigatorModal = ({
  sideHeaders,
  basePath,
  sidePath,
  setOpenNavigationModal,
}) => {
  const navigate = useNavigate();


  return (
    <>
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3, delay: 0.6 }}
        exit={{ opacity: 0, scale: 0, transition:{delay: 0} }}
        className="relative left-20 -top-20 w-fit px-3 pb-3 rounded-lg z-10 bg-blue text-white"
      >
        <div className="top-12 -left-5 relative z-30 stroke-blue">
          <Triangle />
        </div>
        {sideHeaders.map((item, index) => {
          return(
          <div
            id={index}
            key={index}
            onClick={(e) => {
              setOpenNavigationModal({});
              navigate(basePath + sidePath[Number(e.target.id)]);
            }}
            className={`w-full h-full whitespace-nowrap px-3 py-2 hover:bg-[#7763FC] rounded-md `}
          >
            {item}
          </div>
        )})}
      </motion.div>
    </>
  );
};

export default NavigatorModal;
