import React from "react";
import { AnimatePresence, motion } from "framer-motion";

const ManagementTableLayout = ({ tableInstance, loading }) => {
  const { getTableProps, getTableBodyProps, prepareRow, headerGroups, page } =
    tableInstance;

  const mainDivStyle =
    "mt-5 text-sm font-light w-full max-h-[50vh] h-[55vh] overflow-y-scroll mx-auto rounded-xl";
  const tableStyle = "w-full ";
  const theadStyle =
    "bg-green text-white font-semibold rounded-t-xl sticky top-0 z-[48]";
  const thDivStyle = "flex w-full justify-start py-3 px-3";
  const tdDivStyle = `p-[1vh] pl-4 ${!loading && "border-b"}`;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{stiffness: 60, duration: 0.2 }}
      exit={{ opacity: 0 }}
      className={mainDivStyle}
    >
      <table className={tableStyle} {...getTableProps()}>
        <thead className={theadStyle}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div className={thDivStyle}>{column.render("Header")}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <AnimatePresence>
          {loading ? (
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="invisible">
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} className={tdDivStyle}>
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} className={tdDivStyle}>
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          )}
        </AnimatePresence>
      </table>
    </motion.div>
  );
};

export default ManagementTableLayout;
