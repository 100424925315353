import { AnimatePresence } from "framer-motion";
import React from "react";
import useCart from "../hooks/useCart";
import ItemControl from "../utils/ItemControl";
import AddonManage from "./AddonManage";

const ProductsCard = ({ product }) => {
  const onlyOneVariant = product.variants.length === 1;
  const firstVariantIsDefault =
    product.variants[0].options[0] === "Default Title";
  const condition = onlyOneVariant && firstVariantIsDefault;
  const HasVariant = condition ? false : true;
  const HasAddon = !!product.addons.length;
  const [cart, setCart, operations] = useCart();
  const productQuantity = operations.getQuantity(
    "variant",
    product.variants[0].id
  );

  const addVariantToCart = ({ variant, name }) => {
    const settableObj = {
      variant: variant.id,
      variant_data: variant,
      name: name,
      qty: 1,
      addons: []
    };
    console.log(settableObj);
    setCart((prev) => [...prev, settableObj]);
  };

  return (
    <div className="w-full border-y shadow-lg bg-white py-4 rounded border-collapse">
      <div className="flex justify-between px-3 pb-1">
        <div className="text-sm">
          <p className="font-bold mb-2 max-w-[40vw]">{product.title}</p>
          {!HasVariant && <p className="text-xxs">Rs. {product.price}</p>}
          <AnimatePresence>
            {!!productQuantity && !HasVariant && product.addons.length ? (
              <AddonManage product={product} />
            ) : null}
          </AnimatePresence>
        </div>
        {!HasVariant && (
          <ItemControl
            value={productQuantity}
            add={() =>
              addVariantToCart({
                variant: product.variants[0],
                name: product.title,
              })
            }
            increment={() =>
              operations.incrementQuantity("variant", product.variants[0].id)
            }
            decrement={() =>
              operations.decrementQuantity("variant", product.variants[0].id)
            }
          />
        )}
      </div>
      {HasVariant &&
        product.variants.map((variant) => (
          <div className="border-t p-3 text-xs flex justify-between">
            <div className=" w-[40vw]">
              <p className="font-semibold text-xs">
                {variant.options.join(", ")}
              </p>
              <p className="text-xxs mt-2">Rs. {variant.price}</p>
              <AnimatePresence>
                {!!operations.getQuantity("variant", variant.id) &&
                product.addons.length ? (
                  <AddonManage product={product} variant={variant} />
                ) : null}
              </AnimatePresence>
            </div>
            <ItemControl
              value={operations.getQuantity("variant", variant.id)}
              add={() => addVariantToCart({ variant, name: product.title })}
              increment={() =>
                operations.incrementQuantity("variant", variant.id)
              }
              decrement={() =>
                operations.decrementQuantity("variant", variant.id)
              }
            />
          </div>
        ))}
    </div>
  );
};

export default ProductsCard;
