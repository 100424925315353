import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { PayLaterApi } from "../apis/Orders.js/PayLater";
import Info from "../assets/Info";
import Printer from "../assets/Printer";
import { BillPrintableValueAtom, InnerHtml, SalesCustomerAtom } from "../common/Atoms";

const UserCard = ({
  order,
  fetchSelectedOrder,
  showableOrder,
  setShowableOrder,
  setShowPaymentPane,
}) => {
  const [billPrintable, setBillPrintable] = useRecoilState(BillPrintableValueAtom);
  const [printValue, setPrintValue] = useRecoilState(InnerHtml)
  const [customer, setCustomer] = useRecoilState(SalesCustomerAtom)
  const navigate = useNavigate()

  const printSetter = async (e, id, method) => {
    e.stopPropagation()
    const { data: response } = await PayLaterApi.print(id);
    const printData = response.data.print_list.join(
      `<p style="page-break-before: always">`
    );
    if(method === "pay"){
      setBillPrintable(printData);
      return
    }
    setPrintValue(printData)
  };

  useEffect(() => {
    if(customer) {
      navigate("/report")
    }
  }, [customer])

  return (
    <div
      onClick={() => fetchSelectedOrder(order)}
      className={`w-[24vw] h-[17vh] cursor-pointer my-3 text-sm ${showableOrder?.[0]?.customer.id === order.id ? "shadow-xl" : "shadow"} bg-white rounded-xl p-[2vh] flex flex-col justify-evenly`}
    >
      <p className="font-semibold">Name : {order.customer_name}</p>
      <div className="w-full flex justify-between text-xs">
        <p>Amount Due</p>
        <p className="font-semibold">Rs.{order.amount_due}</p>
      </div>
      <div className="w-full flex justify-between mt-[2vh]">
        <button
          onClick={async (e) => {
            await printSetter(e, order.id, "pay")
            setShowPaymentPane(true);
            if( order.id === showableOrder?.[0]?.customer.id){
              setShowableOrder([...showableOrder])
              return
            }
            fetchSelectedOrder(order)
          }}
          className={`w-[50%] ${order.id === showableOrder?.[0]?.customer.id ? "bg-blue text-white" :"bg-blue-grey text-grey hover:bg-blue hover:text-white"}  rounded-md  font-semibold transition ease-in-out delay-50  py-1 px-2`}
        >
          Pay
        </button>
        <div className="">
          <button
            onClick={() => {
              setCustomer(order.id)
            }}
            className="py-1 px-2 bg-blue-grey rounded-md stroke-grey transition ease-in-out delay-50 hover:bg-normal-blue hover:stroke-white mr-2"
          >
            <Info />
          </button>
        <button
          onClick={(e) => printSetter(e, order.id)}
          className="py-1 px-2 bg-blue-grey rounded-md stroke-grey transition ease-in-out delay-50 hover:bg-yellow hover:stroke-white"
        >
          <Printer />
        </button>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
