import React, { useContext } from "react";
import { useSetRecoilState } from "recoil";
import { PayLaterApi } from "../../apis/Orders.js/PayLater";
import { BillPrintableValueAtom } from "../../common/Atoms";
import { Payment } from "../../screens/Tables";

const PaymentButton = ({ handleCancel, orders, setOrders }) => {
  const { setShowPaymentPanel, showPaymentPanel } = useContext(Payment);
  const setBillPrintable = useSetRecoilState(BillPrintableValueAtom)

  return (
    <div className="flex mt-3.5 justify-center">
      <div
        onClick={async () => {
          if(!orders) return;
          const { data: response } = await PayLaterApi.print(orders[0].customer.id);
          const printData = response.data.print_list.join(
            `<p style="page-break-before: always">`
          );
          setBillPrintable(printData)
          setShowPaymentPanel(prev => !prev)
          setOrders([...orders])
        }}
        className={`py-3.5 px-5 w-[60%] flex justify-center transition ease-in-out delay-50 ${ showPaymentPanel ? "bg-blue text-white" : orders ? "hover:bg-blue hover:text-white bg-white text-grey cursor-pointer" : "bg-white text-grey" }  rounded-lg font-semibold`}
      >
        Pay
      </div>
    </div>
  );
};

export default PaymentButton;
