import React, { useState } from "react";
import Filter from "../helperComponents/Filter";
import PrintablePage from "../screens/PrintablePage";
import TodaysOrderTable from "../tables/TodaysOrder";
import Searchbar from "../utils/Searchbar";
import ShowEntries from "../utils/ShowEntries";

const TodaysOrder = () => {
  const [searchString, setSearchString] = useState("");
  const [filterBy, setFilterBy] = useState(null);
  const [priceTotal, setPriceTotal] = useState(null);

  return (
    <>
      <section className="relative z-10 bg-blue-grey w-full h-fit flex flex-col">
        <p className="text-3xl font-bold bg-blue-grey">Today's Order</p>
        {/* <div className="w-full flex justify-between mt-4 ">
          <Searchbar setter={setSearchString} />

          <div className="flex justify-center items-center text-center gap-4">
            <div className="mt-2">
              <Filter filterBy={filterBy} setFilterBy={setFilterBy} />
            </div>

            <div>
              <ShowEntries
                setPageLength={setPageLength}
                totalCount={totalCount}
                pageLength={pageLength}
              />
            </div>
          </div>
        </div> */}
        <TodaysOrderTable
          searchString={searchString}
          filterBy={filterBy}
          setPriceTotal={setPriceTotal}
          setSearchString={setSearchString}
          setFilterBy={setFilterBy}
        />
        <div className="sticky z-20 bg-white/40 bottom-0 left-0 h-fit w-full py-[2vh] ">
          <div className="flex flex-col items-end justify-start">
            <p className="text-lg font-semibold mr-4">
              Total Amount:{" "}
              <span className="ml-5 text-blue">
                Rs{" "}
                {new Intl.NumberFormat("en-IN", {
                  maximumSignificantDigits: 3,
                }).format(priceTotal?.all)}
              </span>{" "}
            </p>
            <div className="w-full flex justify-end mt-2">
              <div className="bg-white px-4 py-2 mr-1 rounded-md">
                <p>
                  Cash:{" "}
                  <span className="ml-2">
                    Rs{" "}
                    {new Intl.NumberFormat("en-IN", {
                      maximumSignificantDigits: 3,
                    }).format(priceTotal?.payment_types?.CASH || "0000.00")}
                  </span>
                </p>
              </div>
              <div className="bg-white px-4 py-2 mr-1 rounded-md">
                <p>
                  Card:{" "}
                  <span className="ml-2">
                    Rs{" "}
                    {new Intl.NumberFormat("en-IN", {
                      maximumSignificantDigits: 3,
                    }).format(priceTotal?.payment_types?.CARD || "0000.00")}
                  </span>
                </p>
              </div>
              <div className="bg-white px-4 py-2 rounded-md">
                <p>
                  Online:{" "}
                  <span className="ml-2">
                    Rs{" "}
                    {new Intl.NumberFormat("en-IN", {
                      maximumSignificantDigits: 3,
                    }).format(
                      (priceTotal &&
                        Object.values(
                          priceTotal.payment_types?.ONLINE || 0
                        ).reduce((acc, cur) => acc + (Number(cur) || 0), 0)) ||
                        "0000.00"
                    )}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PrintablePage />
    </>
  );
};

export default TodaysOrder;
