import React, { useContext, useState, useEffect } from "react";
import { Payment } from "../../screens/Tables";
import Cart from "./Cart";
import PaymentButton from "./PaymentButton";
import TableName from "./TableName";
import { motion } from "framer-motion";
import { useRecoilState, useSetRecoilState } from "recoil";
import { PayableAmountAtom, PaymentFromPayLater } from "../../common/Atoms";

const SidePane = ({ orders, setOrders }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { showPaymentPanel } = useContext(Payment)
  const [payableAmount, setPayableAmount] = useRecoilState(PayableAmountAtom)
  const setAsPayLater = useSetRecoilState(PaymentFromPayLater)
  const [initial, setInitial] = useState(true)

  const handleCancel = () => {
    setModalIsOpen(!modalIsOpen);
  };

  useEffect(() => {
    if(orders && showPaymentPanel){
      setAsPayLater(orders[0].customer.id)
      setPayableAmount(orders?.reduce((acc, cur) => acc + cur.total_due, 0))
    }
  }, [orders])

  useEffect(() => {
    if(!payableAmount && !showPaymentPanel && !initial){
      setOrders(null)
    }
    else{
      setInitial(false)
    }
  },[payableAmount, showPaymentPanel])

  return (
    <>
      <motion.div
      initial={{ x: 100 }}
      animate={showPaymentPanel && payableAmount ? { x: "-100%"} : {x: 0}}
      transition={{ duration: 0.6 }}
      className={`w-[30%] z-20 h-screen bg-white fixed top-0 right-0`}>
        <div className="p-5">
          <TableName name={orders?.[0].customer.name} />
          <Cart orders={orders} />
          <div className="absolute w-full h-[20%] bottom-0 right-0 bg-[#C3CDE4]/[0.35]">
            <div className="p-5">
              <div className={`flex justify-between mt-3 text-sm font-bold p-3 ${!orders && "text-grey" }`}>
                <div>Total Payment</div>
                <div>Rs {orders?.reduce((acc, cur) => acc + cur.total_due, 0) || "0.00"}</div>
              </div>
              <PaymentButton handleCancel={handleCancel} orders={orders} setOrders={setOrders} />
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default SidePane;
