import React from "react";
import { useRecoilState } from "recoil";
import { CartAtom } from "../common/Atoms";

const useCart = () => {
  const [cart, setCart] = useRecoilState(CartAtom);

  const findIndex = (key, value) => {
    return cart.findIndex((each) => each[key] === value);
  };

  const find = (key, value) => {
    return cart.find((each) => each[key] === value);
  };

  const findAddon = (variant, addon) => {
    return variant?.addons.find((each) => {
      return each.id.join("") === addon.join("");
    });
  };

  const findAddonIndex = (variant, addon) => {
    return variant.addons.findIndex(
      (each) => each.id.join("") === addon.join("")
    );
  };

  const normalize = () =>
    cart.map((each) => ({
      ...each,
      addons: each.addons.map((addon) => ({ ...addon })),
    }));

  const getQuantity = (key, value) => {
    const index = findIndex(key, value);
    return index >= 0 ? cart[index].qty : 0;
  };

  const incrementQuantity = (key, value) => {
    const index = findIndex(key, value);
    const normalizedCart = normalize();
    normalizedCart[index].qty += 1;
    setCart([...normalizedCart]);
  };

  const removeVariant = (index) => {
    const filteredCart = cart.filter((_, id) => id !== index);
    setCart(filteredCart);
  };

  const decrementQuantity = (key, value) => {
    const index = findIndex(key, value);
    const normalizedCart = normalize();
    normalizedCart[index].qty = normalizedCart[index].qty - 1;
    normalizedCart[index].addons = []
    if (normalizedCart[index].qty <= 0) {
      removeVariant(index);
    } else {
      setCart([...normalizedCart]);
    }
  };

  const getThisAddonQuantity = (key, value, addonId) => {
    const variant = find(key, value);
    const addon = findAddon(variant, addonId);
    return addon ? addon.qty : 0;
  };

  const addAddon = (key, value, addonObj) => {
    const addon = { ...addonObj, qty: 1 };
    const variantIndex = findIndex(key, value);
    const normalizedCart = normalize();
    normalizedCart[variantIndex].addons.push(addon);
    setCart([...normalizedCart]);
  };

  const incrementAddonQuantity = (key, value, addonId, addQty = false) => {
    const variantIndex = findIndex(key, value);
    const variant = cart[variantIndex];
    const addonIndex = findAddonIndex(variant, addonId);
    const normalizedCart = normalize();
    normalizedCart[variantIndex].addons[addonIndex].qty += 1;
    if (addQty) {
      normalizedCart[variantIndex].qty += 1;
    }
    setCart([...normalizedCart]);
  };

  const filterAddons = (variantIndex, addonIndex) => {
    const filteredAddons = cart[variantIndex].addons.filter(
      (_, index) => index !== addonIndex
    );
    return filteredAddons;
  };

  const getAddonsQuantity = (variantId) => {
    const variant = find("variant", variantId);
    console.log(variant, "variant")
    if(!variant.addons) return(0)
    return variant.addons.reduce((acc, cur) => acc + cur.qty, 0)
  }

  const decrementAddonQuantity = (key, value, addonId, subQty = false) => {
    const variantIndex = findIndex(key, value);
    const variant = cart[variantIndex];
    const addonIndex = findAddonIndex(variant, addonId);
    const normalizedCart = normalize();
    normalizedCart[variantIndex].addons[addonIndex].qty -= 1;
    if(subQty) {
        normalizedCart[variantIndex].qty -= 1
    }
    if(normalizedCart[variantIndex].qty <= 0) {
        removeVariant(variantIndex)
        return
    }
    if (normalizedCart[variantIndex].addons[addonIndex].qty <= 0) {
        const filteredAddons = filterAddons(variantIndex, addonIndex)
        normalizedCart[variantIndex].addons = filteredAddons
    }
    setCart([...normalizedCart])
  };

  const formatCartToNormal = () => {
    const nCart = normalize(cart);
    const realCart = [];
    nCart.forEach((each) => {
      let qty = each.qty;
      const item = { ...each };
      each.addons.forEach((addon) => {
        const product = { ...each };
        const addonQty = addon.qty;
        const price =
          addonQty * (Number(product.variant_data.price) + Number(addon.price));
        realCart.push({ ...product, qty: addonQty, addon, price });
        qty = qty - addonQty;
      });
      if (qty) {
        realCart.push({
          ...item,
          qty,
          addon: null,
          price: Number(item.variant_data.price),
        });
      }
    });
    return realCart;
  };

  return [
    cart,
    setCart,
    {
      findIndex,
      find,
      getQuantity,
      incrementQuantity,
      decrementQuantity,
      getThisAddonQuantity,
      incrementAddonQuantity,
      decrementAddonQuantity,
      addAddon,
      getAddonsQuantity,
      formatCartToNormal,
    },
  ];
};

export default useCart;
