export const setToLocalStorage = (access, refresh, issue = null) => {
  if (access) {
    localStorage.setItem("access", access);
  }
  if (refresh) {
    localStorage.setItem("refresh", refresh);
  }
  if(issue) {
    localStorage.setItem("do-refresh", issue)
  }
};

export const getFromLocalStorage = (key) => {
  return localStorage.getItem(key);
};
