import { AnimatePresence } from "framer-motion";
import React, { createContext, useState } from "react";
import Form from "../forms/Customer";
import CustomerManagementTable from "../tables/Customer";
import ManagementControl from "../helperComponents/ManagementControl";
import ManagementModal from "../helperComponents/ManagementModal";

export const CustomersContext = createContext();

const Customers = () => {
  const [addUserModalIsOpen, setAddUserModalIsOpen] = useState(false);
  const [editableCustomer, setEditableCustomer] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageLength, setPageLength] = useState(10);
  const [searchString, setSearchString] = useState("");

  return (
    <CustomersContext.Provider
      value={{
        totalCount,
        setTotalCount,
        pageLength,
        setPageLength,
        searchString,
      }}
    >
      <div className="relative z-10 pt-20 px-10 bg-blue-grey w-full">
        <ManagementControl
          setShowModal={setAddUserModalIsOpen}
          heading="Manage Customers"
          buttonText="customer"
          pageLength={pageLength}
          setPageLength={setPageLength}
          totalCount={totalCount}
          setSearchString={setSearchString}
        />
        <CustomerManagementTable
          setShowModal={setAddUserModalIsOpen}
          setEditableCustomer={setEditableCustomer}
        />
        <AnimatePresence>
          {addUserModalIsOpen && (
            <ManagementModal
              handleCancel={() => {
                if (editableCustomer) {
                  setEditableCustomer(null);
                }
                setAddUserModalIsOpen(false);
              }}
              form
              small
            >
              <Form
                setAddUserModalIsOpen={setAddUserModalIsOpen}
                editableCustomer={editableCustomer}
                setEditableCustomer={setEditableCustomer}
              />
            </ManagementModal>
          )}
        </AnimatePresence>
      </div>
    </CustomersContext.Provider>
  );
};

export default Customers;
