import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ModalBg from "../helperComponents/ModalBg";
import RoundedClose from "../assets/RoundedClose";
import useCart from "../hooks/useCart";
import ItemControl from "../utils/ItemControl";

const CartManage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [cart, setCart, operations] = useCart();
  const fCart = operations.formatCartToNormal();

  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    document.body.style.overflow = openModal ? "hidden" : "auto";
  }, [openModal]);

  useEffect(() => {
    if (!fCart.length) {
      setOpenModal(false);
    }
  }, [cart]);

  return (
    <>
      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        exit={{ y: "100%" }}
        //  transition={{ duration: 0.6 }}
        className="w-full bg-white sticky bottom-0 px-4 py-2"
      >
        <button
          onClick={() => setOpenModal((prev) => !prev)}
          className="w-full bg-normal-blue font-bold shadow-lg shadow-normal-blue text-white py-2 rounded-full"
        >
          Cart
        </button>
      </motion.div>
      {openModal && (
        <ModalBg>
          <div
            onClick={(e) => e.stopPropagation()}
            className="bg-white max-w-2xl w-full max-h-[80vh] rounded-md pointer-events-auto overflow-y-auto"
          >
            <div className="flex bg-normal-blue font-semibold pb-4 px-3 pt-3 mb-3 text-white justify-between sticky top-0 items-center text-bold stroke-white">
              Cart
              <RoundedClose handleCancel={handleClose} size={16} />
            </div>
            <div className="mb-5 px-3 flex flex-col gap-y-5">
              {fCart.map((eachItem) => {
                const condition =
                  eachItem.variant_data.options[0] === "Default Title";
                console.log(eachItem);
                return (
                  <div className="w-full grid grid-cols-4">
                    <div className="w-full col-span-2 text-sm flex h-full items-center">
                      {!condition &&
                        eachItem.variant_data.options.join(", ") + " "}
                      {eachItem.name}
                      {eachItem.addon &&
                        ` with ${eachItem.addon.name} toppings`}
                    </div>
                    <div className="w-full">
                      <ItemControl
                        value={eachItem.qty}
                        dontShowAdd
                        increment={() => {
                          if (eachItem.addon) {
                            operations.incrementAddonQuantity(
                              "variant",
                              eachItem.variant,
                              eachItem.addon.id,
                              true
                            );
                          } else {
                            operations.incrementQuantity(
                              "variant",
                              eachItem.variant
                            );
                          }
                        }}
                        decrement={() => {
                          if (eachItem.addon) {
                            operations.decrementAddonQuantity(
                              "variant",
                              eachItem.variant,
                              eachItem.addon.id,
                              true
                            );
                          } else {
                            operations.decrementQuantity(
                              "variant",
                              eachItem.variant
                            );
                          }
                        }}
                      />
                    </div>
                    <div className="w-full flex justify-end text-sm">
                      Rs. {eachItem.addon ? eachItem.price : eachItem.price * eachItem.qty}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex sticky bottom-0 bg-white justify-center px-4 py-3">
              <button className="bg-blue w-full text-white font-semibold py-2 rounded-md">
                Place Order
              </button>
            </div>
          </div>
        </ModalBg>
      )}
    </>
  );
};

export default CartManage;
