import React, { useContext, useState, useEffect } from "react";
import Checkbox from "../utils/form/Checkbox";
import Header from "../utils/form/Header";
import InputField from "../utils/form/InputField";
import SelectField from "../utils/form/SelectField";
import TagField from "../utils/form/TagField";
import { ProductCategoriesApi } from "../apis/Products/Category";
import { CategoriesContext } from "../sections/ManageCategory";
import { ProductsApi } from "../apis/Products";
import { toast } from "react-toastify";
import SelectInfiniteField from "../utils/form/SelectInfiniteField";
const Form = ({
  setShowModal,
  fetchKitchens,
  kitchens,
  category = null,
  setCategory = () => {},
}) => {
  const [name, setName] = useState(category?.name || "");
  const [visible, setVisible] = useState(category?.is_visible || false);
  const [products, setProducts] = useState([]);
  const [errorObject, setErrorObject] = useState({});
  const [productsSelectInput, setProductsSelectInput] = useState("");
  const [kitchenSelectedInput, setKitchenSelectedInput] = useState("");
  const [selectedKitchen, setSelectedKitchen] = useState(null);
  const [moreKitchen, setMoreKitchen] = useState(1);
  const [selectedProducts, setSelectedProducts] = useState(
    category?.products || []
  );

  const { setTotalCount } = useContext(CategoriesContext);
  const nullKitchenOption = { id: null, name: "---" };
  const handleNameError = () => {
    if (errorObject?.name) {
      delete errorObject.name;
      delete errorObject.non_field_errors;
      setErrorObject({ ...errorObject });
    }
  };

  const handleSubmit = async () => {
    const payload = {
      name,
      is_visible: visible,
      products: selectedProducts.map((product) => product.id),
      kitchen: selectedKitchen?.id,
    };

    if (selectedKitchen === nullKitchenOption) {
      payload.kitchen = null;
    }
    if (category) {
      const { data: response } = await ProductCategoriesApi.update(
        category.id,
        payload
      );
      if (response.data.message) {
        setErrorObject(response.data.message);
        return;
      }
      toast.success("Category Updated Successfully");
      setCategory(null);
    } else {
      const { data: response } = await ProductCategoriesApi.create(payload);
      if (response.data.message) {
        if (
          typeof response.data.message === "object" &&
          !Array.isArray(response.data.message)
        ) {
          setErrorObject(response.data.message);
        } else {
          toast.error(response.data.message);
        }
        return;
      }
      toast.success("Category Created Successfully");
      setTotalCount((prev) => prev + 1);
    }
    setShowModal(false);
  };

  const handleClose = (index) => {
    selectedProducts.splice(index, 1);
    setSelectedProducts([...selectedProducts]);
  };

  const fetchProducts = async () => {
    const { data: response } = await ProductsApi.search({
      params: { kind: "product", q: productsSelectInput },
    });
    setProducts(response.data.products);
  };

  useEffect(() => {
    if (productsSelectInput !== "") {
      fetchProducts();
    } else {
      setProducts([]);
    }
  }, [productsSelectInput]);

  useEffect(() => {
    if (category) {
      setName(category.name);
      setVisible(category.is_visible);
      setProducts(category.products);
      setSelectedKitchen(category.kitchen);
    }
  }, [category]);

  const handleKitchenError = () => {
    if (errorObject?.kitchen) {
      delete errorObject.kitchen;
      delete errorObject.non_field_errors;
      setErrorObject({ ...errorObject });
    }
  };
  useEffect(() => {
    fetchKitchens({
      params: { search: kitchenSelectedInput, page: moreKitchen },
    });
  }, [kitchenSelectedInput]);

  return (
    <div className=" w-full h-full flex flex-col justify-between">
      <Header
        heading={`Edit : ${name}`}
        setModalOpen={() => {
          setCategory(null);
          setShowModal(false);
        }}
      />
      <div className="max-h-[80%] h-[70%] mb-7 overflow-y-scroll scrollbar-hide text-sm">
        <InputField
          errorValue={errorObject.name}
          errorObjectSetter={handleNameError}
          heading="Name"
          input={name}
          setInput={setName}
        />
        {/* <InputField heading="Kitchen" /> */}
        <div className="w-full mt-4">
          <div className="w-full flex items-center">
            <Checkbox
              checked={visible}
              onClick={() => setVisible((prev) => !prev)}
            />
            <div className="text-xs ml-2">Visible</div>
          </div>
        </div>
        <SelectField
          heading="Products"
          options={products}
          inputSetter={setProductsSelectInput}
          setSelected={(product) => {
            const test = !selectedProducts.some(
              (productCheck) => product.id === productCheck.id
            );
            if (test) setSelectedProducts((prev) => [...prev, product]);
          }}
        />

        <div className="flex flex-wrap">
          {selectedProducts?.map((product, idx) => (
            <TagField
              key={product.id}
              name={product.title}
              handleClose={() => handleClose(idx)}
            />
          ))}
        </div>
        {/* <SelectInfiniteField
          heading="Select Kitchen"
          count={kitchens.length}
          errorValue={errorObject.kitchen}
          setErrorObject={handleKitchenError}
          options={kitchens}
          showOnInput={selectedKitchen?.name}
          inputSetter={setKitchenSelectedInput}
          setSelected={(kitchen) => {
            setSelectedKitchen(kitchen)
          }}
        /> */}

        <SelectInfiniteField
          heading="Select Kitchen"
          count={kitchens.length + 1}
          errorValue={errorObject.kitchen}
          setErrorObject={handleKitchenError}
          options={[nullKitchenOption, ...kitchens]}
          showOnInput={selectedKitchen?.name}
          inputSetter={setKitchenSelectedInput}
          setSelected={(kitchen) => {
            setSelectedKitchen(kitchen);
          }}
        />
      </div>
      <div
        onClick={handleSubmit}
        className="bg-blue cursor-pointer w-full text-lg font-semibold text-white flex justify-center rounded-lg py-2"
      >
        Save
      </div>
    </div>
  );
};

export default Form;
