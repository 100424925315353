import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { PayableAmountAtom } from "../../../common/Atoms";

const CashSection = ({payedAmount, setPayedAmount , setConfirmPaymentDisable}) => {

  console.log(payedAmount , 'payedAmount');
  const payableAmount = useRecoilValue(PayableAmountAtom);
  return (
    <div className="w-full pr-5">
      <div className="flex justify-between">
        <div className="text-grey text-sm">Total amount</div>
        <div className="text-black text-sm w-40">Rs. {payableAmount}</div>
      </div>
      <div className="flex justify-between mt-5">
        <div className="text-grey text-sm">Amount paid</div>
        <input
          className="text-black text-xs w-40 outline-blue bg-white px-2"
          placeholder="Input amount"
          value={payedAmount}
          onClick={() => setPayedAmount("")}
          onChange={e => {
            setPayedAmount(parseFloat(e.target.value) || "" )
            setConfirmPaymentDisable(true)
          }}
        />
      </div>
      <div className="flex justify-between mt-5">
        <div className="text-grey text-sm">Change</div>
        <div className="text-black text-sm w-40">Rs {payedAmount - payableAmount > 0 ? payedAmount - payableAmount : "0.00"}</div>
      </div>
    </div>
  );
};

export default CashSection;
