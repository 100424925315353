import React, { useState } from "react";
import UpwardArrow from "../../assets/UpwardArrow";
import { motion } from "framer-motion";

const DropdownField = ({ heading, options, selected, setSelected, fromFilter }) => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <div className="w-full mt-4">
      <div className=" font-semibold mb-2">{heading}</div>
      <div onClick={() => setShowOptions(!showOptions)} className="w-full flex focus:outline-1 focus:outline-blue active:outline-blue active:outline-1 bg-blue-grey items-center rounded-md ">
        <div className="bg-blue-grey h-8 w-full rounded-md outline-none text-xs flex items-center pl-3 text-grey font-medium">
          {selected?.name || "Select payment method"}
        </div>
        <motion.button
          animate={{ rotate: showOptions ? 0 : 180 }}
          className="stroke-black px-2"
        >
          <UpwardArrow />
        </motion.button>
      </div>
      {showOptions &&
        options?.slice(0, 3)?.map((item, index) => (
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: index * 0.2 }}
            key={item.name ? item.name : item}
            onClick={() => {
              setSelected(item);
              setShowOptions(false);
            }}
            className="text-grey text-xs bg-blue-grey p-2 w-full border-y"
          >
            {item.name ? item.name : item}
          </motion.button>
        ))}
    </div>
  );
};

export default DropdownField;
