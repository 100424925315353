import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { motion } from "framer-motion";

const ManagementModal = ({ handleCancel, form = false, small = false, children }) => {

  // const handleEscapePress = (e) => e.key === "Escape" && handleCancel

  // useEffect(() => {
  //   const keypressEvent = document.addEventListener("keydown", handleEscapePress)

  //   return () => {document.removeEventListener("keydown", handleEscapePress)}
  // }, [])

  return createPortal(
    <>
      <div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          transition={{stiffness: 60, duration: 0.2}}
          exit={{ opacity: 0 }}
          className="w-screen h-screen fixed top-0 left-0 z-20 bg-grey"
        ></motion.div>
        <div onClick={handleCancel} className="w-screen h-screen fixed top-0 left-0 z-30 backdrop-blur-[1px]"></div>
      </div>
      {form ? (
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: 0 }}
          exit={{ x: "100%" }}
          transition={{ duration: 0.2 }}
          className={`select-none ${
            small ? "w-[25%]" : "w-[30%]"
          } h-screen fixed top-0 right-0 z-50 bg-white p-10`}
        >
          {children}
        </motion.div>
      ) : (
        <>{children}</>
      )}
    </>,
    document.getElementById("modal")
  );
};

export default ManagementModal;
