import React, { useEffect, useState } from "react";
import { OrdersApi } from "../../apis/Orders.js/index.js";
import { AnimatePresence, motion } from "framer-motion";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  CartHistoryAtom,
  OrderDetailsAtom,
  CartAtom,
} from "../../common/Atoms.js";
import UpwardArrow from "../../assets/UpwardArrow.js";

const TableName = () => {
  const [openTableList, setOpenTableList] = useState(false);
  const [tableList, setTableList] = useState(null);
  const [selected, setSelected] = useRecoilState(OrderDetailsAtom);
  const cart = useRecoilValue(CartAtom);
  const cartHistory = useRecoilValue(CartHistoryAtom);
  
  const fetchTableArea = async () => {
    const { data: response } = await OrdersApi.tablesList();
    setTableList(response.data);
  };

  const handleTableSelect = (table) => {
    if (selected.table?.id === table.id) {
      setSelected({ ...selected, table: null });
    } else {
      setSelected({ ...selected, table });
    }
    setOpenTableList(false);
  };

  useEffect(() => {
    fetchTableArea();
  }, [cart, cartHistory]);

  return (
    <>
      <div
        className={`cursor-pointer w-full h-8 bg-blue text-white font-semibold ${
          openTableList ? "rounded-t-lg" : "rounded-lg"
        }`}
      >
        <div
          onClick={() => setOpenTableList((prev) => !prev)}
          className=" py-1 px-3 flex justify-between"
        >
          <p>Table No. {selected.table?.name}</p>
          <motion.button
            animate={openTableList ? { rotate: 0 } : { rotate: 180 }}
            className="stroke-white"
          >
            <UpwardArrow />
          </motion.button>
        </div>
        <AnimatePresence>
          {openTableList && (
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: "25vh" }}
              exit={{ height: 0, transition: { duration: 0.4, delay: 0.2 } }}
              transition={{ duration: 0.4 }}
              className="relative box-content top-0 left-0 z-[60] w-full pt-2 overflow-y-auto rounded-b-lg bg-blue-grey"
            >
              {tableList?.map((tableArea) => (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0, transition: { duration: 0.1, delay: 0 } }}
                  transition={{ duration: 0.1, delay: 0.4 }}
                  key={tableArea}
                >
                  <p className="text-sm font-semibold pl-4 text-blue">
                    {tableArea.name}
                  </p>
                  <div className="flex justify-start px-2 flex-wrap">
                    {tableArea.tables.map((table, index) => (
                      <button
                        key={table.id}
                        onClick={() =>
                          table.is_available && handleTableSelect(table)
                        }
                        className={` min-w-[2vw] p-1 mx-2 my-2 text-sm rounded-md ${
                          table.is_available
                            ? "bg-white text-grey"
                            : "bg-red text-white"
                        }`}
                      >
                        {table.name}
                      </button>
                    ))}
                  </div>
                </motion.div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default TableName;
