import React from "react";

const Trash = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 18 20"
      fill="none"
    >
      <path
        d="M1.5 5H3.16667H16.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.66669 4.99984V3.33317C5.66669 2.89114 5.84228 2.46722 6.15484 2.15466C6.4674 1.8421 6.89133 1.6665 7.33335 1.6665H10.6667C11.1087 1.6665 11.5326 1.8421 11.8452 2.15466C12.1578 2.46722 12.3334 2.89114 12.3334 3.33317V4.99984M14.8334 4.99984V16.6665C14.8334 17.1085 14.6578 17.5325 14.3452 17.845C14.0326 18.1576 13.6087 18.3332 13.1667 18.3332H4.83335C4.39133 18.3332 3.9674 18.1576 3.65484 17.845C3.34228 17.5325 3.16669 17.1085 3.16669 16.6665V4.99984H14.8334Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33331 9.1665V14.1665"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6667 9.1665V14.1665"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Trash;
