import React, { useState, useEffect } from "react";
import Trash from "../../../assets/Trash";
import Radio from "../../../utils/form/Radio";
import OnlinePaymentMethods from "../OnlinePaymentMethods";
import { useRecoilValue } from "recoil";
import { PayableAmountAtom } from "../../../common/Atoms";

const SplitSection = ({ paymentMethod, people, setPeople ,setConfirmPaymentDisable}) => {
  const payableAmount = useRecoilValue(PayableAmountAtom);
  const [initialValue, setInitialValue] = useState(people.map(() => true));

  const handlePaymentSelect = (id, types, isOnline= false) => {
    let type
    if(isOnline) {
      type = types[0]
    } else {
      type = types
    }
    const changedPeople = people.map((_, idx) =>
      idx === id ? { ...people[id], type } : people[idx]
    );
    setPeople([...changedPeople]);
    setConfirmPaymentDisable(true)
   
  };

  const handleValueChange = (value, id) => {
    initialValue[id] = false;
    people[id].amount = parseInt(value) || "";
    setPeople([...people]);
    setInitialValue([...initialValue]);
  };



  useEffect(() => {
    initialValue.forEach((checkValue, idx) => {
      if(checkValue){
        const dollar = initialValue.reduce((acc,cur,id) => !cur ? acc.concat(id) : acc , [])
        let total = 0
        dollar?.forEach((eachId) => {
          total = total + people[eachId].amount
        })
        people[idx].amount = (idx === people.length - 1) ? (payableAmount - total) -
        ((
            people.length -dollar.length - 1
          ) * Math.round((payableAmount - total) / (people.length - dollar.length)))
      : Math.round((payableAmount - total) / (people.length - dollar.length))
      }
    })
    setPeople([...people])
  }, [initialValue])

  return (
    <>
      <div className="max-h-[48vh] overflow-y-auto scrollbar-hide">
        <table className="w-full text-sm">
          <thead>
            <tr className="sticky top-0 bg-blue-grey">
              <th className="flex">Amount</th>
              {paymentMethod.CASH && <th>Cash</th>}
              {paymentMethod.CARD && <th>Card</th>}
              {paymentMethod.ONLINE && <th>Online</th>}
            </tr>
          </thead>
          <tbody>
            {people.map((_, id) => (
              <>
                <tr>
                  <td>
                    <input
                      value={
                        people[id].amount
                      }
                      // onFocus={() => {
                      //   const allSum = people.reduce(
                      //     (acc, cur, idx) => idx !== id ? acc + (parseInt(cur.amount) || 0) : acc,
                      //     0
                      //   );
                      //   if(!allSum) return
                      //   handleValueChange(payableAmount - allSum, id);
                      // }}
                      onChange={(e) => handleValueChange(e.target.value, id)}
                      className="rounded-md px-2 outline-blue text-sm py-0.5"
                    />
                  </td>
                  {paymentMethod.CASH && (
                    <td>
                      <button
                        onClick={() =>
                          handlePaymentSelect(id, paymentMethod.CASH)
                        }
                        className="w-full flex justify-center pt-1.5"
                      >
                        <Radio checked={people[id].type?.name === "CASH"} />
                      </button>
                    </td>
                  )}
                  {paymentMethod.CARD && (
                    <td>
                      <button
                        onClick={() =>
                          handlePaymentSelect(id, paymentMethod.CARD)
                        }
                        className="w-full flex justify-center pt-1.5"
                      >
                        <Radio checked={people[id].type?.name === "CARD"} />
                      </button>
                    </td>
                  )}
                  {paymentMethod.ONLINE && (
                    <td>
                      <button
                        onClick={(e) =>
                          handlePaymentSelect(
                            id,
                            paymentMethod.ONLINE.childrens,
                            true
                          )
                        }
                        className="w-full flex justify-center pt-1.5"
                      >
                        <Radio
                          checked={["gpay", "paytm", "others"].includes(
                            people[id].type?.name?.toLowerCase()
                          )}
                        />
                      </button>
                    </td>
                  )}
                  {people.length > 2 && (
                    <button
                      onClick={() => {
                        people.splice(id, 1);
                        initialValue.splice(id, 1);
                        setPeople([...people]);
                        setInitialValue([...initialValue]);
                      }}
                      className="w-full flex justify-center stroke-grey hover:stroke-red pt-4"
                    >
                      <Trash />
                    </button>
                  )}
                </tr>
                {["gpay", "paytm", "others"].includes(
                  people[id].type?.name?.toLowerCase()
                ) && (
                  <tr>
                    <td colSpan={4}>
                      <OnlinePaymentMethods
                        small
                        onlineMethod={people[id].type}
                        setOnlineMethod={(method) =>
                          handlePaymentSelect(id, method)
                        }
                        paymentMethod={paymentMethod.ONLINE.childrens}
                      />
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-around mt-2">
        <div className="text-grey text-sm font-semibold">Amt.to be paid</div>
        <div className="text-sm font-semibold">Rs. {payableAmount}</div>
      </div>
      <button
        onClick={() => {
          setPeople((prev) => [...prev, {}]);
          setInitialValue((prev) => [...prev, true]);
        }}
        className="text-blue text-xs mt-5 border-b w-fit border-blue border-opacity-50"
      >
        Add a person
      </button>
    </>
  );
};

export default SplitSection;
