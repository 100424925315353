import React, { useContext, useState } from "react";
import { CustomersApi } from "../apis/Customers";
import Back from "../assets/Back";
import { customerAtom } from "../common/Atoms";
import AddressField from "../utils/form/AddressField";
import FullNameField from "../utils/form/FullNameField";
import InputField from "../utils/form/InputField";
import PhoneNumberField from "../utils/form/PhoneNumberField";
import { useRecoilState } from "recoil";
import { CustomersContext } from "../screens/Customers";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

const Form = ({
  setAddUserModalIsOpen,
  editableCustomer = null,
  setEditableCustomer = () => {},
}) => {
  const initialName = editableCustomer
    ? {
        firstName: editableCustomer.first_name,
        lastName: editableCustomer.last_name,
      }
    : {};
  const initialPhoneNumber = editableCustomer
    ? {
        countryCode: editableCustomer.phone ? editableCustomer.phone?.slice(
          0,
          editableCustomer.phone?.length - 10
        ) : "+91",
        number: editableCustomer.phone?.slice(-10),
      }
    : {};
  const initialEmail = editableCustomer ? editableCustomer.email : "";
  const initialAddress = editableCustomer
    ? {
        street1: editableCustomer.street1,
        street2: editableCustomer.street2,
        city: editableCustomer.city,
        state: editableCustomer.state,
        postal: editableCustomer.postal_code,
      }
    : {};
  const [name, setName] = useState(initialName);
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);
  const [email, setEmail] = useState(initialEmail);
  const [address, setAddress] = useState(initialAddress);
  const [errorObject, setErrorObject] = useState({});
  const [customers, setCustomers] = useRecoilState(customerAtom);
  const { setTotalCount } = useContext(CustomersContext);

  const handleSubmit = async () => {
    const payload = {
      first_name: name.firstName,
      last_name: name.lastName,
      email,
      phone: (phoneNumber.countryCode || "+91") + phoneNumber.number,
      street1: address.street1,
      street2: address.street2,
      state: address.state,
      city: address.city,
      postal_code: address.postal,
    };
    try {
      if (editableCustomer) {
        const { data: response } = await CustomersApi.update(
          editableCustomer.id,
          payload
        );
        if (response.data.message) {
          setErrorObject(response.data.message);
          return;
        }
        toast.success("Customer Updated Successfully")
        const updatedCustomerInfo = customers.map((eachCustomer) =>
          eachCustomer.id === editableCustomer.id
            ? { ...payload, id: eachCustomer.id }
            : eachCustomer
        );
        setEditableCustomer(null);
        setCustomers(updatedCustomerInfo);
      } else {
        const { data: response } = await CustomersApi.create(payload);
        if (response.data.message) {
          setErrorObject(response.data.message);
          return;
        }
        toast.success("Customer Created Successfully")
        setCustomers((prev) => [...prev, payload]);
        setTotalCount((prev) => prev + 1);
      }
      setAddUserModalIsOpen(false);
    } catch (error) {
    }
  };

  const handleEmailErrorObject = () => {
    if (errorObject?.email) {
      delete errorObject.email;
      delete errorObject.non_field_errors
      setErrorObject({ ...errorObject });
    }
  }

  return (
    <div className="w-full h-full flex flex-col justify-between">
      <div className="pr-2 flex justify-end">
        <div
          onClick={() => {
            if (editableCustomer) {
              setEditableCustomer(null);
            }
            setAddUserModalIsOpen(false);
          }}
          className="cursor-pointer"
        >
          <Back />
        </div>
      </div>
      <div className="flex flex-col justify-center">
        <div className="text-lg font-semibold text-blue">Customer Details</div>
        <div className="mt-2 max-h-[70vh] overflow-y-auto scrollbar-hide">
        <FullNameField
          name={name}
          setName={setName}
          errorObject={errorObject}
          setErrorObject={setErrorObject}
        />
        <PhoneNumberField
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          errorObject={errorObject}
          setErrorObject={setErrorObject}
        />
        <InputField
          heading="Email"
          input={email}
          setInput={setEmail}
          errorValue={errorObject.email}
          errorObjectSetter={handleEmailErrorObject}
        />
        <AddressField
          address={address}
          setAddress={setAddress}
          errorObject={errorObject}
          setErrorObject={setErrorObject}
        />
        </div>
        {errorObject.non_field_errors && (
          <motion.p
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
            className={`mt-4 py-2 text-center bg-red/5 text-red border border-red text-sm rounded`}
          >{errorObject.non_field_errors.join(", ")}</motion.p>
        )}
      </div>
      <div
        onClick={handleSubmit}
        className="bg-blue w-full cursor-pointer rounded-lg py-2 font-semibold text-white flex justify-center"
      >
        Save
      </div>
    </div>
  );
};

export default Form;
