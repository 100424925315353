import axios from "axios";
import { getFromLocalStorage, setToLocalStorage } from "../common/LocalStorage";
import { AuthApi } from "./Accounts/Auth";
// https://pos-api.pixelotechapp.dev/
export const BASE_URL = "https://pos-api.pixelotech.com/api/v1/";

export const normalAxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const authenticatedAxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let isRefreshing = false;
let requestsToRefresh = [];

const handleErrorResponse = async (axiosErrorObject, setInternalServerError) => {
  const prevReq = axiosErrorObject?.config;
  // if(axiosErrorObject.response?.status === 401 && prevReq.sent){
  // localStorage.removeItem("access");
  // localStorage.removeItem("refresh");
  // window.location.href = "/login";
  // }
  if (axiosErrorObject.response?.status === 401 && !prevReq.sent) {
    prevReq.sent = true;
    if (!isRefreshing) {
      isRefreshing = true;
      const refresh = getFromLocalStorage("refresh");
      const access = getFromLocalStorage("access");
      try {
        const { data: response } = await AuthApi.refreshToken({ refresh });
        setToLocalStorage(response.data.access, response.data.refresh);
        prevReq.headers["Authorization"] = `Bearer ${getFromLocalStorage(
          "access"
        )}`;
        requestsToRefresh.forEach((cb) => cb(response.data.access));
        requestsToRefresh = [];
        isRefreshing = false;
        return authenticatedAxiosInstance(prevReq);
      } catch (e) {
        requestsToRefresh.forEach((cb) => cb(null));
        requestsToRefresh = [];
        isRefreshing = false;
        return(Promise.reject(axiosErrorObject));
      }
      
    } else {
      return new Promise((resolve, reject) => {
        requestsToRefresh.push((token) => {
          if (token) {
            resolve(authenticatedAxiosInstance(prevReq));
          }
          reject(axiosErrorObject);
        });
      });
    }
  }
  if (
    axiosErrorObject.response?.status === 423 ||
    axiosErrorObject.response?.status === 500
  ) {
    setInternalServerError("Unknown error, please try again later")
  }
  if (axiosErrorObject.response?.status === 422) {
    return axiosErrorObject.response;
  }
  return Promise.reject(axiosErrorObject);
};

const handleErrorResponseNormal = async (axiosErrorObject) => {
  if (
    axiosErrorObject.response.status === 422 &&
    axiosErrorObject.request.responseURL.includes("/refresh")
  ) {
      localStorage.removeItem("refresh");
      localStorage.removeItem("access");
      window.location.href = "/login";
      return Promise.reject(axiosErrorObject);
  }
  return axiosErrorObject.response;
};

// const handleSuccessResponseNormal = async (config) => {
//   if (config.request.responseURL.includes("/refresh")) {
//     setToLocalStorage(null, null, "no");
//   }
//   return config;
// };

// const handleSuccessResponseAuth = async (config) => {
//   if (getFromLocalStorage("do-refresh") === "yes") {
//     setToLocalStorage(null, null, "no");
//   }
//   return config;
// };

export const registerIntercepts = (setLoading, setInternalServerError) => {
  authenticatedAxiosInstance.interceptors.response.use(
    (response) => (response),
    (error) => handleErrorResponse(error, setInternalServerError)
  );
  authenticatedAxiosInstance.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `Bearer ${getFromLocalStorage(
        "access"
      )}`;
      return config;
    },
    (error) => Promise.reject(error)
  );
  normalAxiosInstance.interceptors.response.use(
    (response) => (response),
    (error) => handleErrorResponseNormal(error)
  );
  setLoading(false);
};
