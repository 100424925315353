import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import ProductManagementTable from "../../tables/Product";
import { motion } from "framer-motion";
import Form from "../../forms/Product";
import Variation from "./Variation";
import ManagementModal from "../../helperComponents/ManagementModal";
import ManagementControl from "../../helperComponents/ManagementControl";
import { useRecoilState } from "recoil";
import {
  addonAtom,
  categoryAtom,
  kitchenAtom,
  productAtom,
} from "../../common/Atoms";
import { ProductCategoriesApi } from "../../apis/Products/Category";
import { ProductAddonsApi } from "../../apis/Products/Addon";
import { ProductsApi } from "../../apis/Products";
import { KitchensApi } from "../../apis/Kitchens";
import { toast } from "react-toastify";
//bb801af6-6f67-43d7-9289-b757d68ee51f - kitchen id
const ManageProduct = () => {
  const [addProductModalIsOpen, setAddProductModalIsOpen] = useState(false);
  const [showVariationModal, setShowVariationModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [variantsData, setVariantsData] = useState([]);
  const [editableProduct, setEditableProduct] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageLength, setPageLength] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [errorObject, setErrorObject] = useState({});
  const [referenceVariants, setReferenceVariants] = useState(null);
  const [formData, setFormData] = useState({ categories: [], addons: [] });
  const [categories, setCategories] = useRecoilState(categoryAtom);
  const [addons, setAddons] = useRecoilState(addonAtom);
  const [kitchens, setKitchens] = useRecoilState(kitchenAtom);
  const [categoriesCount, setCategoriesCount] = useState(-1);
  const [addonsCount, setAddonsCount] = useState(-1);
  const [kitchensCount, setKitchensCount] = useState(-1);
  const nullKitchenOption = { id: null, name: "---" };

  const fetchCategories = async (params) => {
    // console.log("params", params, categoriesCount, categories.length)
    // if(categoriesCount === categories.length && categoriesCount >= 0){
    //   return
    // }
    const { data: response } = await ProductCategoriesApi.list(params);
    if (params.params.page === 1 || categoriesCount === -1) {
      setCategories(response.data.results);
    } else {
      setCategories((prev) => [...prev, ...response.data.results]);
    }
    setCategoriesCount(response.data.count);
  };

  const fetchAddons = async (params) => {
    const { data: response } = await ProductAddonsApi.list(params);
    if (params.params.page === 1 || addonsCount === -1) {
      setAddons(response.data.results);
    } else {
      setAddons((prev) => [...prev, ...response.data.results]);
    }
    setAddonsCount(response.data.count);
  };

  const fetchKitchens = async (params) => {
    const { data: response } = await KitchensApi.list(params);
    setKitchens(response.data);
  };

  const fetchProduct = async () => {
    const { data: response } = await ProductsApi.show(editableProduct);
    // console.log(response,"response" );
    if (
      response.data.variants?.[0]?.options[0] !== "Default Title" &&
      response.data.options?.[0]?.name !== "Default"
    ) {
      setShowVariationModal(true);
      const optionsToBe = response.data.options.map((option) => {
        option.values.push("");
        return option;
      });
      setReferenceVariants(response.data.variants);
      setVariantsData(response.data.variants);
      setOptions(optionsToBe);
    } else {
      const price = response.data.variants?.[0]?.price;
      response.data.price = price;
    }
    setFormData(response.data);
    setAddProductModalIsOpen(true);
  };

  const handleCreate = async () => {
    if (!showVariationModal && formData.title && !formData.price) {
      setErrorObject({ price: ["Price is not set"] });
      return;
    }
    const payload = {
      ...formData,
      categories: formData.categories.map((category) => category.id),
      addons: formData.addons.map((addon) => addon.id),
      kitchen: formData.kitchen?.id,
      options: showVariationModal
        ? options.map((eachVariant) => eachVariant.name)
        : ["Default"],
      variants: showVariationModal
        ? variantsData
        : [{ options: ["Default title"], price: formData.price }],
    };
    if (
      formData.kitchen &&
      formData.kitchen.id === nullKitchenOption.id
    ) {
      payload.kitchen = null;
    }
    delete payload.price;
    if (editableProduct) {
      const { data: response } = await ProductsApi.update(
        editableProduct,
        payload
      );
      if (response.data.message) {
        setErrorObject(response.data.message);
        return;
      }
      toast.success("Category Updated Successfully");
    } else {
      const { data: response } = await ProductsApi.create(payload);
      if (response.data.message) {
        setErrorObject(response.data.message);
        return;
      } else {
        toast.error(response.data.message);
      }
    }
    setFormData({ categories: [], addons: [] });

    setAddProductModalIsOpen(false);
    setShowVariationModal(false);
  };

  useEffect(() => {
    if (categories.length === 0) {
      fetchCategories();
    }
    if (addons.length === 0) {
      fetchAddons();
    }
    if (kitchens.length === 0) {
      fetchKitchens();
    }
  }, []);

  useEffect(() => {
    if (!showVariationModal) {
      setVariantsData([]);
      setOptions([]);
      setReferenceVariants(null);
    }
  }, [showVariationModal]);

  useEffect(() => {
    if (editableProduct) {
      fetchProduct();
    }
  }, [editableProduct]);

  useEffect(() => {
    if (!addProductModalIsOpen && editableProduct) {
      setEditableProduct(null);
    }
  }, [addProductModalIsOpen]);
  console.log(formData, "formData");
  return (
    <div className="relative z-10 h-[80%] bg-blue-grey w-full">
      <ManagementControl
        setShowModal={setAddProductModalIsOpen}
        heading="Manage Product"
        buttonText="product"
        pageLength={pageLength}
        setPageLength={setPageLength}
        totalCount={totalCount}
        searchString={searchString}
        setSearchString={setSearchString}
      />
      <ProductManagementTable
        totalCount={totalCount}
        setTotalCount={setTotalCount}
        pageLength={pageLength}
        searchString={searchString}
        kitchens={kitchens}
        setEditableProduct={setEditableProduct}
        setShowModal={setAddProductModalIsOpen}
        showModal={addProductModalIsOpen}
      />
      <AnimatePresence>
        {showVariationModal && (
          <ManagementModal>
            <Variation
              options={options}
              setOptions={setOptions}
              showVariationModal={showVariationModal}
              setShowVariationModal={setShowVariationModal}
              variantsData={variantsData}
              setVariantsData={setVariantsData}
              referenceVariants={referenceVariants}
            />
          </ManagementModal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {addProductModalIsOpen && (
          <ManagementModal
            handleCancel={() => {
              setShowVariationModal(false);
              setErrorObject({});
              setAddProductModalIsOpen(false);
              setOptions([]);
              setFormData({ categories: [], addons: [] });
            }}
          >
            <motion.div
              initial={{ x: "100%" }}
              animate={showVariationModal ? { x: "-100%" } : { x: 0 }}
              exit={{ x: "100%" }}
              transition={{ duration: 0.6 }}
              className="w-[30%] h-screen fixed top-0 right-0 z-50 bg-white py-10 px-4"
            >
              <Form
                setAddProductModalIsOpen={setAddProductModalIsOpen}
                categoriesCount={categoriesCount}
                addonsCount={addonsCount}
                kitchensCount={kitchensCount}
                showVariationModal={showVariationModal}
                setShowVariationModal={setShowVariationModal}
                categories={categories}
                addons={addons}
                kitchens={kitchens}
                editableProduct={editableProduct}
                setEditableProduct={setEditableProduct}
                setKitchens={setKitchens}
                setOptions={setOptions}
                formData={formData}
                errorObject={errorObject}
                setErrorObject={setErrorObject}
                setFormData={setFormData}
                fetchCategories={fetchCategories}
                fetchAddons={fetchAddons}
                fetchKitchens={fetchKitchens}
                handleSubmit={handleCreate}
                nullKitchenOption={nullKitchenOption}
              />
            </motion.div>
          </ManagementModal>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ManageProduct;
