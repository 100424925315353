import { atom } from "recoil";

export const categoryAtom = atom({
  key: "category",
  default: [],
});

export const productAtom = atom({
  key: "products",
  default: [],
});

export const customerAtom = atom({
  key: "customers",
  default: [],
});

export const addonAtom = atom({
  key: "addon",
  default: [],
});

export const variantAtom = atom({
  key: "variant",
  default: []
})

export const kitchenAtom = atom({
  key: "kitchen",
  default: []
})

export const CartAtom = atom({
  key: "cart",
  default: []
})

export const OrderDetailsAtom = atom({
  key: "orderDetails",
  default: {}
})

export const CartHistoryAtom = atom({
  key: "cartHistory",
  default: {}
})

export const ShowCartHistoryAtom = atom({
  key:"showHistoryCart",
  default: false
})

export const InnerHtml = atom({
  key:"InnerHtml",
  default: ""
})

export const PayableAmountAtom = atom({
  key: "payableAmount",
  default: 0
})

export const PaymentOrderIdAtom = atom({
  key: "paymentOrderId",
  default: ""
})

export const PaymentFromPayLater = atom({
  key: "paymentFromPayLater",
  default: ""
})

export const BillPrintableValueAtom = atom({
  key: "billprintable",
  default: ""
})

export const PaymentCompleteStatusAtom = atom({
  key: "paymentCompleteStatusAtom",
  default: false
})

export const TableNameSelectToOngoingOrdersAtom = atom({
  key: "tableSelectToOngoingAtom",
  default: ""
})

export const OrderPageNewlyAddedCustomerSelect = atom({
  key: "newlyAddedCustomerSelect",
  default: null
})

export const IsOwnerAtom = atom({
  key: "isOwner",
  default: false
})

export const SalesCustomerAtom = atom({
  key: "salesCustomer",
  default: null,
})

export const CurrentlyBillingIdAtom = atom({
  key: "currentlyBillingId",
  default: null,
})
