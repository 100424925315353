import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { OrdersApi } from "../apis/Orders.js";
import { CartAtom } from "../common/Atoms.js";
import useArrayOfObjs from "../hooks/useArrayOfObjs.js";
import CartManage from "./CartManage.js";
import CategoryMenu from "./CategoryMenu.js";
import Header from "./Header";
import ProductsRoll from "./ProductsRoll";
import ScreenConfig from "./ScreenConfig";
import SearchBar from "./SearchBar.js";

const OrderOnTable = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const cart = useRecoilValue(CartAtom);

  const fetchCategories = async () => {
    const { data: response } = await OrdersApi.categoriesList();
    setCategories(response.data);
  };

  useEffect(() => {
    fetchCategories();
  }, [searchValue]);

  return (
    <ScreenConfig>
      <Header />
      <SearchBar
        setSearchValue={setSearchValue}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <ProductsRoll searchValue={searchValue} category={selectedCategory} />
      <CategoryMenu
        hasCart={cart?.length}
        categories={categories}
        setSelectedCategory={setSelectedCategory}
      />
      <AnimatePresence>{cart?.length ? <CartManage /> : null}</AnimatePresence>
    </ScreenConfig>
  );
};

export default OrderOnTable;
