import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Dashboard from "../screens/Tables";
import Products from "../screens/Products";
import Customers from "../screens/Customers";
import Sidebar from "../helperComponents/Sidebar";
import Vendors from "../screens/Vendors";
import VendorManagement from "../screens/Vendors/sections/VendorManagement";
import PurchaseOrder from "../screens/Vendors/sections/PurchaseOrder";
import { getFromLocalStorage } from "../common/LocalStorage";
import { registerIntercepts } from "../apis/axios";
import { RecoilRoot } from "recoil";
import Report from "../screens/Report";
import Kitchen from "../screens/Kitchen";
import ManageCategory from "../sections/ManageCategory";
import ManageAddon from "../sections/ManageAddon";
import Order from "../sections/Order";
import OngoingOrders from "../sections/OngoingOrders";
import Table from "../sections/Table";
import PayLater from "../sections/PayLater";
import ManageProduct from "../sections/ManageProduct";
import TodaysOrder from "../sections/TodaysOrder";
import { toast } from "react-toastify";

const Navigation = () => {
  const access = getFromLocalStorage("access");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [internalServerError, setInternalServerError] = useState("")

  // const fetchCategories = async () => {
  //   const response = await ProductCategoriesApi.list();
  // };

  useEffect(() => {
    if (!access) {
      setLoading(false)
      navigate("/login");
    } else {
      registerIntercepts(setLoading, setInternalServerError);
      // fetchCategories();
    }
  }, []);

  useEffect(() => {
    if(internalServerError) {
      toast.error(internalServerError)
      setInternalServerError("")
    }
  }, [internalServerError])

  if (loading) {
    return <div className="flex justify-center mt-10"> loading... </div>;
  }

  return (
    <div className="h-fit flex relative">
      {/* Left */}
      <Sidebar />
        <Routes>
          <Route path="/tables" element={<Dashboard />}>
            <Route path="order" element={<Order />} />
            <Route path="ongoing-orders" element={<OngoingOrders />} />
            <Route path="table-view" element={<Table />} />
            <Route path="pay-later-view" element={<PayLater />} />
            <Route path="today-order" element={<TodaysOrder />} />
          </Route>
          <Route path="/customers" element={<Customers />} />
          <Route path="/products" element={<Products />}>
            <Route path="manage-category" element={<ManageCategory />} />
            <Route path="manage-product" element={<ManageProduct />} />
            <Route path="manage-addon" element={<ManageAddon />} />
            <Route
              path=""
              element={<Navigate to="/products/manage-product" />}
            />
            <Route
              path="*"
              element={<Navigate to="/products/manage-product" />}
            />
          </Route>
          <Route path="/report" element={<Report />} />
          <Route path="/kitchen" element={<Kitchen />} />
          <Route path="/vendors" element={<Vendors />}>
            <Route path="vendor-management" element={<VendorManagement />} />
            <Route path="purchase-order" element={<PurchaseOrder />} />
            <Route
              path=""
              element={<Navigate to="/vendors/vendor-management" />}
            />
            <Route
              path="*"
              element={<Navigate to="/vendors/vendor-management" />}
            />
          </Route>
          <Route path="" element={<Navigate to="/tables/order" />} />
          <Route path="*" element={<Navigate to="/tables/order" />} />
        </Routes>
    </div>
  );
};

export default Navigation;
