import React, { useContext } from "react";
import { useSetRecoilState } from "recoil";
import { OrdersApi } from "../../apis/Orders.js";
import { BillPrintableValueAtom, CurrentlyBillingIdAtom, InnerHtml, PayableAmountAtom, PaymentOrderIdAtom } from "../../common/Atoms";
import { Payment } from "../../screens/Tables";

const PaymentButton = ({ orders, handleCancel, handleAddItem, subTotal }) => {
  const { setShowPaymentPanel, showPaymentPanel } = useContext(Payment);
  const setPayableAmount = useSetRecoilState(PayableAmountAtom)
  const setOrderId = useSetRecoilState(PaymentOrderIdAtom)
  const setBillPrintable = useSetRecoilState(BillPrintableValueAtom)
  const setBillableId = useSetRecoilState(CurrentlyBillingIdAtom)

  return (
    <div className="flex mt-3.5 justify-center">
      <div
        onClick={orders?.items?.length && (orders?.payment_status === "UNPAID") && handleCancel}
        className={`py-3.5 px-5 mr-6 transition ease-in-out delay-50 cursor-pointer rounded-lg font-semibold ${orders?.items?.length && (orders?.payment_status === "UNPAID") ? "hover:bg-blue hover:text-white bg-white text-grey" : "bg-white/40 text-grey/40" }`}
      >
        Cancel
      </div>
      <div onClick={orders?.items?.length && (orders?.total_due === subTotal) && handleAddItem}  className={`py-3.5 px-5 mr-6 transition ease-in-out delay-50 cursor-pointer rounded-lg font-semibold ${orders?.items?.length && (orders?.total_due === subTotal) ? "hover:bg-blue hover:text-white bg-white text-grey" : "bg-white/40 text-grey/40" }`}>
        Add Item
      </div>
      <div
        onClick={async () => {
          if(orders?.items?.length){
            setShowPaymentPanel(true)
            // const {data: response} = await OrdersApi.orderPrint(orders.id)
            // setBillPrintable(response)
            setBillableId(orders.id)
            setPayableAmount(orders.total_due);
            setOrderId(orders.id);
          }
        }}
        className={`py-3.5 px-5 transition ease-in-out delay-50 cursor-pointer ${ showPaymentPanel ? "bg-blue text-white" : orders?.items?.length ? "hover:bg-blue hover:text-white bg-white text-grey" : "bg-white/40 text-grey/40" }  rounded-lg font-semibold`}
      >
        Pay
      </div>
    </div>
  );
};

export default PaymentButton;
