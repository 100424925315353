import React, { useState, useEffect } from "react";
import KitchenIcon from "../assets/KitchenIcon";
import FilledClose from "../assets/FilledClose";
import { KitchensApi } from "../apis/Kitchens";
import { AnimatePresence, motion } from "framer-motion";
import Modal from "../helperComponents/Modal";
import Form from "../forms/Kitchen";
import KitchenLoader from "../Loaders/Kitchen";
import { toast } from "react-toastify";

const Kitchen = () => {
  const [editMode, setEditMode] = useState(false);
  const [kitchens, setKitchens] = useState([]);
  const [input, setInput] = useState("");
  const [editableKitchen, setEditableKitchen] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchKitchen = async () => {
    const { data: response } = await KitchensApi.list();
    setKitchens(response.data);
    setLoading(false);
  };

  const handleDelete = async ({ dish, idx }) => {
    const { data: response, status } = await KitchensApi.destroy(dish.id);
    kitchens.splice(idx, 1);
    setKitchens([...kitchens]);
    if (response.success) {
      toast.success("Deleted Successfully");
    } else{
      toast.error("Something went wrong");
      return;
    }
  };

  const handleSubmit = async () => {
    if (editableKitchen) {
      const { data: response } = await KitchensApi.update(editableKitchen.id, {
        name: input,
      });
      if (response.data.message) {
        setErrorMessage(response.data.message.name);
        return;
      }
      const updatableKitchen = kitchens.map((kitchen) =>
        kitchen.id === editableKitchen.id
          ? { id: kitchen.id, name: input }
          : kitchen
      );
      setKitchens([...updatableKitchen]);
      setShowModal(false);
      return;
    }
    const { data: response } = await KitchensApi.create({ name: input });
    if (response.success) {
      setKitchens([...kitchens, { id: response.data.id, name: input }]);
      setShowModal(false);
      toast.success("Kitchen Created Successfully");
    }

    if (response.data.message) {
      setErrorMessage(response.data.message.name);
      toast.error("Something went wrong");
      return;
    }
  };

  useEffect(() => {
    fetchKitchen();
  }, []);

  useEffect(() => {
    if (!showModal) setInput("");
  }, [showModal]);

  useEffect(() => {
    if (editableKitchen) {
      setInput(editableKitchen.name);
      setShowModal(true);
    }
  }, [editableKitchen]);

  return (
    <main className="relative z-10 pt-20 px-10 bg-blue-grey w-full">
      <div className="text-3xl font-bold">Kitchen Settings</div>
      <div className="w-full flex justify-end">
        <button
          onClick={() => {
            setEditMode((prev) => !prev);
          }}
          className="text-white bg-blue mt-[1vh] mb-[3vh] font-semibold w-fit flex items-center p-1 rounded-md"
        >
          <KitchenIcon />
          <div className="ml-2">{editMode ? "Save" : "Edit  Kitchen"}</div>
        </button>
      </div>
      <section className="w-full h-0 flex flex-wrap">
        <AnimatePresence>
          {loading ? (
            <KitchenLoader />
          ) : (
            kitchens.map((dishVariety, idx) => (
              <motion.div
                exit={{ scale: 0, opacity: 0, transition: { duration: 0.6 } }}
                key={dishVariety.id}
                className="h-[176px]"
              >
                <div
                  onClick={() => editMode && setEditableKitchen(dishVariety)}
                  className={`${
                    editMode && "cursor-pointer"
                  } mr-5 w-[170px] mb-5 h-[160px] p-1 text-center bg-white text-grey rounded-xl flex justify-center items-center`}
                >
                  {dishVariety.name}
                </div>
                {editMode && (
                  <button
                    // onClick={() => handleDelete(dishVariety, idx)}
                    onClick={() =>
                      setDeleteConfirmation({ dish: dishVariety, idx })
                    }
                    className="relative h-0 w-0 -top-[190px] left-40 border-4 border-yellow"
                  >
                    
                    <FilledClose />
                  </button>
                )}
              </motion.div>
            ))
          )}
        </AnimatePresence>
        {editMode && (
          <button
            onClick={() => setShowModal(true)}
            className="mr-5 w-[170px] mb-5 h-[160px] p-1 text-center bg-grey/60 text-white text-5xl rounded-xl flex justify-center items-center "
          >
            +
          </button>
        )}
      </section>
      <AnimatePresence>
        {showModal && (
          <Modal
            heading={editableKitchen ? "Edit Kitchen" : "New Kitchen"}
            handleCancel={() => {
              setErrorMessage(null);
              setEditableKitchen(null);
              setShowModal(false);
            }}
          >
            <Form
              setShowModal={() => {
                setErrorMessage(null);
                setEditableKitchen(null);
                setShowModal(false);
              }}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              input={input}
              setInput={setInput}
              handleSubmit={handleSubmit}
            />
          </Modal>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {deleteConfirmation && (
          <Modal
            heading="Delete"
            handleCancel={() => setDeleteConfirmation(null)}
          >
            <section className="p-5 flex flex-col justify-center items-center">
              <p>
                Are you sure to delete the kitchen{" "}
                {deleteConfirmation.dish.name}
              </p>
              <div className="w-[35%] flex justify-between mt-5">
                <button
                  onClick={() => setDeleteConfirmation(null)}
                  className="px-2 py-1 bg-blue-grey text-grey rounded-md"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    handleDelete(deleteConfirmation);
                    setDeleteConfirmation(null);
                  }}
                  className="px-2 py-1 bg-blue text-white rounded-md"
                >
                  Delete
                </button>
              </div>
            </section>
          </Modal>
        )}
      </AnimatePresence>
    </main>
  );
};

export default Kitchen;
