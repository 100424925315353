import React from "react";

const TextAreaField = ({ heading, input, setInput }) => {
  return (
    <div className="mt-4 w-full">
      <div className=" font-semibold mb-2">{heading}</div>
      <div className="w-full">
        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          rows={3}
          className="bg-blue-grey py-1 px-2 w-full rounded-md outline-blue text-xs"
        />
      </div>
    </div>
  );
};

export default TextAreaField;
