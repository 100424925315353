import React from "react";
import { createPortal } from "react-dom";
import { motion } from "framer-motion";
import RoundedClose from "../assets/RoundedClose";

const Modal = ({
  handleCancel,
  heading,
  children,
  secondary = false,
  ternery = false,
  headerFunction = () => {},
  callFunction
}) => {
  const theme = secondary ? "bg-green" : ternery ? "bg-yellow" : "bg-blue"
  return createPortal(
    <>
      <div onClick={handleCancel}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.5 }}
          transition={{stiffness: 60, duration: 0.2 }}
          exit={{ opacity: 0 }}
          className="w-screen h-screen fixed top-0 left-0 z-20 bg-grey"
        ></motion.div>
        <div className="w-screen h-screen fixed top-0 left-0 z-30 backdrop-blur-[1px]"></div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.2 }}
        className="select-none fixed w-[530px] h-fit z-50 top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg"
      >
        <div className={`w-full py-3 px-5 rounded-t-lg flex justify-between items-center ${theme}`}>
          <div onClick={headerFunction} className={`${callFunction && "cursor-pointer"} text-white font-semibold`}>{heading}</div>
          <div className="stroke-white">
            <RoundedClose handleCancel={handleCancel} size="20" />
          </div>
        </div>
        {children}
      </motion.div>
    </>,
    document.getElementById("modal")
  );
};

export default Modal;
