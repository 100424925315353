import React from 'react'

const DinningButtons = ({ selectedType }) => {
  return (
    <div className="mt-5 flex mb-8">
        <div className={`px-3 py-1 mr-3 transition cursor-default ease-in-out delay-50 ${selectedType === "DINE_IN" ? "bg-green text-white" : "text-grey/40 bg-blue-grey/40"} font-semibold rounded-md w-fit`} >
          Dine In
        </div>
        <div className={`px-3 py-1 mr-3 transition cursor-default ease-in-out delay-50 ${selectedType === "TO_GO" ? "bg-green text-white" : "text-grey/40 bg-blue-grey/40"} font-semibold rounded-md w-fit`} >
          To Go
        </div>
        <div className={`px-3 py-1 mr-3 transition cursor-default ease-in-out delay-50 ${selectedType === "DELIVERY" ? "bg-green text-white" : "text-grey/40 bg-blue-grey/40"} font-semibold rounded-md w-fit`} >
          Delivery
        </div>
    </div>
  )
}

export default DinningButtons