import React, { useState } from 'react'

const useArrayOfObjs = (initialValue) => {
  const [value, setter] = useState(initialValue)

  const find = (key, val) => {
    return(value.findIndex(each => each[key] === val))
  }

  const set = (obj, key, val) => {
    obj[key] = val
    setter([...value])
  }

  return ([value, setter])
}

export default useArrayOfObjs