import React, { useEffect, useState, useMemo } from "react";
import { useTable } from "react-table";
import { cartesian } from "../../common/Calculations";
import TableLayout from "./TableLayout";
import { motion } from "framer-motion";

const CombinationTable = ({
  options,
  combinationData,
  setCombinationData,
  referenceVariants,
}) => {
  const [inputChange, setInputChange] = useState({});
  const [quantity, setQuantity] = useState({});
  const [isActive, setIsActive] = useState(null);
  const [initial, setInitial] = useState(true);

  const handleValueChange = (value, row) => {
    let temp = combinationData[Number(row.id)];
    temp = { ...combinationData[Number(row.id)], price: value || "" };
    const changableValue = combinationData.map((item, idx) => {
      return idx === Number(row.id) ? temp : item;
    });
    setCombinationData([...changableValue]);
  };

  const handleQuantityChange = (row, value) => {
    let temp = combinationData[row];
    if (!(temp.quantity + value < 0)) {
      temp.quantity = temp.quantity + value;
      const changableValue = combinationData.map((item, idx) => {
        return idx === row ? temp : item;
      });
      setCombinationData([...changableValue]);
    }
  };

  const handleIsActiveChange = () => {
    let temp = combinationData[isActive.row];
    temp.status = temp.status === "ARCHIVED" ? "ACTIVE" : "ARCHIVED";
    const changedValue = combinationData.map((item, idx) => {
      return idx === isActive ? temp : item;
    });
    setCombinationData([...changedValue]);
    setIsActive(null);
  };

  const combinationDataSetup = () => {
    const combinedData = options?.reduce(
      (acc, cur) => acc.concat([cur.values.slice(0, cur.values.length - 1)]),
      []
    );
    const combinatedData = cartesian(...combinedData);
    const necessaryData = combinatedData?.map((data) =>
      referenceVariants?.some(
        (eachData) => JSON.stringify(data) === JSON.stringify(eachData.options)
      )
        ? referenceVariants.filter(
            (eachData) =>
              JSON.stringify(data) === JSON.stringify(eachData.options)
          )?.[0]
        : Array.isArray(data)
        ? {
            options: data,
            price: 0,
            status: "ARCHIVED",
          }
        : {
            options: [data],
            price: 0,
            status: "ARCHIVED",
          }
    );
    setCombinationData(necessaryData);
  };

  const COLUMN = [
    {
      id: "variant",
      Header: "Variants",
      Cell: ({ row }) =>
        Array.isArray(row.original.options) ? (
          <p>{row.original.options.join("/ ")}</p>
        ) : (
          <>{row.original.options}</>
        ),
      width: 200,
    },
    {
      id: "price",
      Header: "Price",
      Cell: ({ row }) => (
        <input
          onFocus={(e) => (e.target.value = "")}
          onBlur={(e) => (e.target.value = row.original.price)}
          value={row.original.price}
          onChange={(e) =>
            setInputChange({ value: e.target.value, row, input: e.target })
          }
          className="w-12 bg-blue-grey px-1"
        />
      ),
    },
    // {
    //   id: "quantity",
    //   Header: "Quantity",
    //   Cell: ({ row }) => (
    //     <div className="w-12 bg-blue-grey flex justify-around">
    //       <button
    //         onClick={() => setQuantity({row: Number(row.id), value: -1})}
    //         className="pr-1"
    //       >
    //         -
    //       </button>
    //       {row.original.quantity}
    //       <button
    //         onClick={() => setQuantity({row: Number(row.id), value: 1})}
    //         className="pl-1"
    //       >
    //         +
    //       </button>
    //     </div>
    //   ),
    // },
    {
      id: "buttons",
      // width: 200,
      Cell: ({ row }) => (
        <button
          onClick={() => setIsActive({ row: Number(row.id) })}
          className={`w-8 h-5 rounded-full flex justify-start ${
            row.original.status === "ACTIVE" ? "bg-blue" : "bg-grey"
          } items-center`}
        >
          <motion.div
            animate={
              row.original.status === "ACTIVE" ? { x: "100%" } : { x: 0 }
            }
            className="w-4 h-4 bg-white rounded-full"
          ></motion.div>
        </button>
      ),
    },
  ];

  const columns = useMemo(() => COLUMN, []);
  const data = useMemo(() => combinationData, [combinationData]);

  const tableInstance = useTable({ data, columns });

  useEffect(() => {
    if (options.length > 0 && combinationData.length > 0 && initial) {
      setCombinationData(combinationData.map((item) => ({ ...item })));
    } else {
      combinationDataSetup();
    }
    setInitial(false);
  }, [options]);

  useEffect(() => {
    if (Object.keys(inputChange).length > 0) {
      handleValueChange(inputChange.value, inputChange.row, inputChange.input);
    }
  }, [inputChange]);

  useEffect(() => {
    if (Object.keys(quantity).length > 0) {
      handleQuantityChange(quantity.row, quantity.value);
    }
  }, [quantity]);

  useEffect(() => {
    if (isActive) {
      handleIsActiveChange();
    }
  }, [isActive]);

  return <TableLayout tableInstance={tableInstance} />;
};

export default CombinationTable;
