import React from "react";

const AddressField = ({ address, setAddress, secondary=false }) => {
  const handleStreetOne = e => setAddress(prev => ({ ...prev, street1 : e.target.value }))
  const handleStreetTwo = e => setAddress(prev => ({ ...prev, street2 : e.target.value }))
  const handleCity = e => setAddress(prev => ({ ...prev, city : e.target.value }))
  const handleState = e => setAddress(prev => ({ ...prev, state : e.target.value }))
  const handlePostal = e => setAddress(prev => ({ ...prev, postal : parseInt(e.target.value) }))

  return (
    <div className="mt-4 w-full">
      <div className="text-md font-semibold mb-2">Address</div>
      <div className="w-full mr-2">
        <input value={address.street1 || ""} onChange={handleStreetOne} className={`${secondary ? "bg-white" : "bg-blue-grey"} p-2 w-full rounded-md outline-blue text-xs`} />
        <div className="text-xs text-grey">Street Address Line 1</div>
      </div>
      <div className="w-full mr-2 my-2">
        <input value={address.street2 || ""} onChange={handleStreetTwo} className={`${secondary ? "bg-white" : "bg-blue-grey"} p-2 w-full rounded-md outline-blue text-xs`} />
        <div className="text-xs text-grey">Street Address Line 2</div>
      </div>
      <div className="w-full mt-2 justify-between flex">
        <div className="w-full mr-2">
          <input value={address.city || ""} onChange={handleCity} className={`${secondary ? "bg-white" : "bg-blue-grey"} p-2 w-full rounded-md outline-blue text-xs`} />
          <div className="text-xs text-grey">City</div>
        </div>
        <div className="w-full ml-2">
          <input value={address.state || ""} onChange={handleState} className={`${secondary ? "bg-white" : "bg-blue-grey"} p-2 w-full rounded-md outline-blue text-xs`} />
          <div className="text-xs text-grey">State/Province</div>
        </div>
      </div>
      <div className="w-full mr-2 my-2">
        <input value={address.postal || ""} onChange={handlePostal} className={`${secondary ? "bg-white" : "bg-blue-grey"} p-2 w-full rounded-md outline-blue text-xs`} />
        <div className="text-xs text-grey">Postal/Zip code</div>
      </div>
    </div>
  );
};

export default AddressField;
