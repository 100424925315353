import { authenticatedAxiosInstance as axios } from "../axios";

const list = (params) => axios.get(`/orders`, params)
const create = payload => axios.post(`/orders`, payload)
const show = id => axios.get(`/orders/${id}`)
const update = (id, payload) => axios.put(`/orders/${id}`, payload)
const cancel = (id, payload) => axios.post(`/orders/${id}/cancel`, payload)
const timeline = (id, timeline) => axios.get(`/order/${id}/timeline/${timeline}`)
const categoriesList = params => axios.get(`/orders/categories`, params) 
const productsList = (params, payload) => axios.get(`/orders/products`,params, payload)
const kitchensList= () => axios.get(`/orders/kitchens`)
const tablesList = (params) => axios.get(`/orders/tables`, params)
const productSearch = (params, payload) => axios.post(`/orders/products/search`, params, payload)
const customerSearch = payload => axios.post(`/orders/customers/search`, payload)
const customerDetails = customer_id => axios.get(`/orders/customers/${customer_id}`)
const customerList = (params) => axios.get(`/orders/customers`, params)
const kotPrint = (id, kotTime) => axios.get(`/orders/${id}/kot/${kotTime}/print`)
const orderPrint = (id) => axios.post(`/orders/${id}/print`)

export const OrdersApi = {
    list,
    create,
    show,
    update,
    cancel,
    timeline,
    categoriesList,
    productsList,
    kitchensList,
    tablesList,
    productSearch,
    customerSearch,
    customerDetails,
    customerList,
    kotPrint,
    orderPrint,
}
