import React, { useState, useEffect, useCallback, useRef } from "react";
import UpwardArrow from "../../assets/UpwardArrow";
import { motion } from "framer-motion";
import { debounce } from "lodash";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";


const SelectInfiniteField = ({
  heading,
  count,
  setMoreOptions = () => {},
  options,
  errorValue,
  showInput,
  setErrorObject,
  setSelected,
  showOnInput,
  inputSetter = () => {},
  colored = false,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectInputValue, setSelectInputValue] = useState(showInput || "");
  const [shouldShowValue, setShouldShowValue] = useState(showOnInput);
  const [showLoading, setShowLoading] = useState(false)
  const selectRef = useRef()

  const handleOptimisedChange = useCallback(
    debounce((text) => {
      inputSetter(text);
      if (text) {
        setShowOptions(true);
      } else {
        setShowOptions(false);
      }
    }, 300),
    []
  );


  const onScroll = () => {
    if(options?.length === count){
        return
    }
    if (selectRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = selectRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setShowLoading(true)
        setMoreOptions(prev => prev + 1)
      }
    }
  };

  useEffect(() => {
      if(!showOptions){
        setMoreOptions(1)
      }
  }, [showOptions])

  useEffect(() => {
    handleOptimisedChange(selectInputValue);
    setShouldShowValue(null);
  }, [selectInputValue]);

  useEffect(() => {
    setShouldShowValue(showOnInput);
  }, [showOnInput]);

  return (
    <div className="w-full mt-4">
      <div className={`font-semibold mb-2 ${errorValue && "text-red"}`}>
        {heading}
      </div>
      <div
        className={`w-full flex focus:outline-1 focus:outline-blue active:outline-blue active:outline-1 ${
          errorValue ? "bg-red/5 border border-red" : "bg-blue-grey"
        } items-center rounded-md `}
      >
        <input
          value={selectInputValue || shouldShowValue || ""}
          onChange={(e) => {
            if(showInput) return;
            if (errorValue) setErrorObject();
            setSelectInputValue(e.target.value);
          }}
          className={`bg-transparent py-1 px-2 w-full rounded-md outline-none text-xs`}
        />
        {options?.length ? (
          <motion.button
            animate={{ rotate: showOptions ? 0 : 180 }}
            onClick={() => {
              if (errorValue) setErrorObject();
              setShowOptions(!showOptions);
            }}
            className="stroke-black px-2"
          >
            <UpwardArrow />
          </motion.button>
        ) : (
          ""
        )}
      </div>
      {showOptions && (
        <div 
            onScroll={onScroll}
            ref={selectRef} 
            className="max-h-40 overflow-y-auto">
          {options?.map((item, index) => (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              key={item.name ? item.name : item.title ? item.title : item}
              onClick={() => {
                setSelected(item);
                setSelectInputValue("");
                setShowOptions(false);
                console.log(showOnInput)
                if(showOnInput) setShouldShowValue(item.name || item.title);
              }}
              className={`text-grey text-xs hover:text-blue p-2 w-full ${
                colored
                  ? item.type === "category"
                    ? "bg-red/5"
                    : " bg-normal-blue/5"
                  : "bg-blue-grey"
              } hover:bg-blue/10`}
            >
              {colored && item.type + ": "}
              {item.name ? item.name : item.title ? item.title : item}
            </motion.button>
          ))}
          {options?.length !== count && (<div className="w-full py-1 flex justify-center bg-blue-grey">
                <ClipLoader color={"#604BE8"} loading={showLoading} size={12} />
              </div>)}
        </div>
      )}
      <p className="text-xs text-red">{errorValue}</p>
    </div>
  );
};

export default SelectInfiniteField;
