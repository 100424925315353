import moment from "moment";

// export const getArrayOfdays = (month, year) => {
//   month = parseInt(month);
//   year = parseInt(year);
//   const monthsWithThirty = [4, 6, 9, 11];
//   const leapYear = (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
//   return month === 2
//     ? leapYear
//       ? Array.from({ length: 29 }).map((_, index) => index + 1)
//       : Array.from({ length: 28 }).map((_, index) => index + 1)
//     : monthsWithThirty.includes(month)
//     ? Array.from({ length: 30 }).map((_, index) => index + 1)
//     : Array.from({ length: 31 }).map((_, index) => index + 1);
// };

export const checkIfToday = (day, date) => {
  const isSameDay = day === new Date().getDate();
  const isSameMonth = date.getMonth() === new Date().getMonth();
  const isSameYear = date.getYear() === new Date().getYear();
  return isSameDay && isSameMonth && isSameYear;
};

// export const getMonthLength = (month, year) => {
//   month = parseInt(month);
//   year = parseInt(year);
//   const monthsWithThirty = [4, 6, 9, 11];
//   const leapYear = (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
//   return month === 2
//     ? leapYear
//       ? 29
//       : 28
//     : monthsWithThirty.includes(month)
//     ? 30
//     : 31;
// };

// export const checkIfSelectedDate = (day, month, year, date) => {
//   const isSameDay = day === date.getDate();
//   const isSameMonth = date.getMonth() === month;
//   const isSameYear = date.getFullYear() === year;
//   // if(isSameDay && isSameMonth && isSameYear) console.log("YES")
//   return isSameDay && isSameMonth && isSameYear;
// };

// export const checkIfListIncludesDate = (day, month, year, list) => {
//   const date = new Date();
//   date.setDate(day);
//   date.setMonth(month);
//   date.setFullYear(year);
//   const readableDate = moment(date).format("YYYY-MM-DD");
//   return list?.includes(readableDate);
// };

// export const calculateDatePadding = (date) => {
//   var padding;
//   switch (parseInt(moment(new Date(date).setDate(1)).format("d"))) {
//     case 1:
//       padding = "col-start-2";
//       break;
//     case 2:
//       padding = "col-start-3";
//       break;
//     case 3:
//       padding = "col-start-4";
//       break;
//     case 4:
//       padding = "col-start-5";
//       break;
//     case 5:
//       padding = "col-start-6";
//       break;
//     case 6:
//       padding = "col-start-7";
//       break;
//   }
//   return padding;
// };

// export const months = [
//   "Jan",
//   "Feb",
//   "Mar",
//   "Apr",
//   "May",
//   "Jun",
//   "July",
//   "Aug",
//   "Sep",
//   "Oct",
//   "Nov",
//   "Dec",
// ];

// export const fullMonth = [
//   "January",
//   "February",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "August",
//   "September",
//   "October",
//   "November",
//   "December",
// ];

// export const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

// export const shortWeekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

// export const checkDateIsLess = (newDate, settableDate) => {
//   const dateCheck = newDate.getDate() < settableDate.getDate();
//   const monthCheck = newDate.getMonth() < settableDate.getMonth();
//   const yearCheck = newDate.getFullYear() < settableDate.getFullYear();
//   const check1 =
//     dateCheck &&
//     newDate.getMonth() === settableDate.getMonth() &&
//     newDate.getFullYear() === settableDate.getFullYear();
//   const check2 =
//     monthCheck && newDate.getFullYear() === settableDate.getFullYear();
//   const check3 = yearCheck;
//   return check3 || check2 || check1;
// };

// export const checkDateIsGreater = (newDate, settableDate) => {
//   const dateCheck = newDate.getDate() > settableDate.getDate();
//   const monthCheck = newDate.getMonth() > settableDate.getMonth();
//   const yearCheck = newDate.getFullYear() > settableDate.getFullYear();
//   const check1 =
//     dateCheck &&
//     newDate.getMonth() === settableDate.getMonth() &&
//     newDate.getFullYear() === settableDate.getFullYear();
//   const check2 =
//     monthCheck && newDate.getFullYear() === settableDate.getFullYear();
//   const check3 = yearCheck;
//   return check3 || check2 || check1;
// };

export const getArrayOfdays = (month, year) => {
  month = parseInt(month);
  year = parseInt(year);
  const monthsWithThirty = [4, 6, 9, 11];
  const leapYear = (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
  return month === 2
    ? leapYear
      ? Array.from({ length: 29 }).map((_, index) => index + 1)
      : Array.from({ length: 28 }).map((_, index) => index + 1)
    : monthsWithThirty.includes(month)
    ? Array.from({ length: 30 }).map((_, index) => index + 1)
    : Array.from({ length: 31 }).map((_, index) => index + 1);
};

export const getMonthLength = (month, year) => {
  month = parseInt(month);
  year = parseInt(year);
  const monthsWithThirty = [4, 6, 9, 11];
  const leapYear = (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
  return month === 2
    ? leapYear
      ? 29
      : 28
    : monthsWithThirty.includes(month)
    ? 30
    : 31;
};

export const checkIfSelectedDate = (day, month, year, date, from, val) => {
  return((date.getDate() === day) && (date.getMonth() === month) && (date.getFullYear() === year))
  const isSameDay = (day === date.getDate()) ? true : false;
  const isSameMonth = (date.getMonth() === month) ? true : false;
  const isSameYear = (date.getFullYear() === year) ? true : false;
  if(isSameDay && isSameMonth && isSameYear) {
  }
  return isSameDay && isSameMonth && isSameYear;
};

export const checkIfListIncludesDate = (day, month, year, list) => {
  const date = new Date()
  date.setDate(day)
  date.setMonth(month)
  date.setFullYear(year)
  const readableDate = moment(date).format("YYYY-MM-DD")
  return list?.includes(readableDate)
}

export const calculateDatePadding = (date) => {
  // var padding;
  return parseInt(moment(new Date(date).setDate(1)).format("d"));
  // switch (parseInt(moment(new Date(date).setDate(1)).format("d"))) {
  //   case 1:
  //     padding = "col-start-2";
  //     break;
  //   case 2:
  //     padding = "col-start-3";
  //     break;
  //   case 3:
  //     padding = "col-start-4";
  //     break;
  //   case 4:
  //     padding = "col-start-5";
  //     break;
  //   case 5:
  //     padding = "col-start-6";
  //     break;
  //   case 6:
  //     padding = "col-start-7";
  //     break;
  // }
  // return padding;
};

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const fullMonth = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const shortWeekDays = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

export const checkDateIsLess = (newDate, settableDate) => {
  const dateCheck = newDate.getDate() < settableDate.getDate();
  const monthCheck = newDate.getMonth() < settableDate.getMonth();
  const yearCheck = newDate.getFullYear() < settableDate.getFullYear();
  const check1 =
    dateCheck &&
    newDate.getMonth() === settableDate.getMonth() &&
    newDate.getFullYear() === settableDate.getFullYear();
  const check2 =
    monthCheck && newDate.getFullYear() === settableDate.getFullYear();
  const check3 = yearCheck;
  return check3 || check2 || check1;
};

export const checkDateIsGreater = (newDate, settableDate) => {
  const dateCheck = newDate.getDate() > settableDate.getDate();
  const monthCheck = newDate.getMonth() > settableDate.getMonth();
  const yearCheck = newDate.getFullYear() > settableDate.getFullYear();
  const check1 =
    dateCheck &&
    newDate.getMonth() === settableDate.getMonth() &&
    newDate.getFullYear() === settableDate.getFullYear();
  const check2 =
    monthCheck && newDate.getFullYear() === settableDate.getFullYear();
  const check3 = yearCheck;
  return check3 || check2 || check1;
};


export const pickerList = {
  "Today": moment().startOf("day").toDate(),
  "Yesterday": moment().subtract(1, "days").startOf("day").toDate(),
  "This week": moment().startOf("week").toDate(),
  // "Last 7 days": moment().subtract(7, "days").toDate(),
  "Last 14 days": moment().subtract(14, "days").toDate(),
  // "Last 30 days": moment().subtract(30, "days").toDate(),
  // "Last 3 months": moment().subtract(3, "months").toDate(),
  // "Last 12 months": moment().subtract(12, "days").toDate(),
  "This month": moment().startOf("month").toDate(),
  // "Month to date": moment().startOf("month").toDate(),
  // "Quater to date": moment().startOf("quarter").toDate(),
  "All time": moment().toDate(),
};
