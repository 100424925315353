import React from "react";

const FullNameField = ({
  name,
  setName,
  secondary = false,
  errorObject,
  setErrorObject = () => {},
}) => {
  const bgColor = secondary ? "bg-white" : "bg-blue-grey";
  const errorInputStyle = ["first_name", "last_name"].map((field) => (
    errorObject?.[field] ? "bg-red/5 border border-red" : bgColor
  ))
  const errorParaStyle = ["first_name", "last_name"].map((field) => (
    errorObject?.[field] ? "text-red" : "text-grey"
  ))

  const handleFirstNameChange = (e) => {
    delete errorObject?.first_name;
    delete errorObject?.non_field_errors
    setErrorObject({ ...errorObject });
    setName((prev) => ({ ...prev, firstName: e.target.value }));
  };

  const handleLastNameChange = (e) => {
    delete errorObject?.last_name;
    delete errorObject?.non_field_errors
    setErrorObject({ ...errorObject });
    setName((prev) => ({ ...prev, lastName: e.target.value }));
  };

  return (
    <section className="mt-5 w-full">
      <p className={`text-md font-semibold`}>Full Name</p>
      <div className="w-full mt-2 justify-between flex">
        <div className="w-full mr-2">
          <input
            value={name.firstName || ""}
            onChange={handleFirstNameChange}
            className={`${errorInputStyle[0]} p-2 w-full rounded-md outline-blue text-xs`}
          />
          <p className={`text-xs ${errorParaStyle[0]}`}>
            First Name
            {errorObject?.first_name && (": " + errorObject?.first_name.join(". "))}
          </p>
        </div>
        <div className="w-full ml-2">
          <input
            value={name.lastName || ""}
            onChange={handleLastNameChange}
            className={`${errorInputStyle[1]} p-2 w-full rounded-md outline-blue text-xs`}
          />
          <p className={`text-xs ${errorParaStyle[1]}`}>
            Last Name
            {errorObject?.last_name && (": " + errorObject.last_name.join(". "))}
          </p>
        </div>
      </div>
    </section>
  );
};

export default FullNameField;
