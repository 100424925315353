import React, { useContext, useState, useEffect } from "react";
import { Payment } from "../../screens/Tables";
import Cart from "./Cart";
import CustomerDetails from "./CustomerDetails";
import DinningButtons from "./DinningButtons";
import PaymentButton from "./PaymentButton";
import TableName from "./TableName";
import { AnimatePresence, motion } from "framer-motion";
import AddCustomerModal from "./AddCustomerModal";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  OrderDetailsAtom,
  CartAtom,
  CartHistoryAtom,
  ShowCartHistoryAtom,
  InnerHtml,
  PayableAmountAtom,
  BillPrintableValueAtom,
} from "../../common/Atoms";
import { OrdersApi } from "../../apis/Orders.js/index.js";
import { CustomersApi } from "../../apis/Customers";
import Modal from "../../helperComponents/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SidePane = () => {
  const [showCustomerAddModal, setShowCustomerAddModal] = useState(false);
  const { showPaymentPanel } = useContext(Payment);
  const [subTotal, setSubTotal] = useState(null);
  const [editableCustomerId, setEditableCustomerId] = useState(null);
  const [editableCustomer, setEditableCustomer] = useState(null);
  const [editValueObjects, setEditValueObjects] = useState([]);
  const [prevCartTotal, setPrevCartTotal] = useState(0);
  const [prevCartSection, setPrevCartSection] = useState(null);
  const [fixedTotal, setFixedTotal] = useState(0);
  const [initial, setInitial] = useState(true);
  const [printModal, setPrintModal] = useState(null);
  const [mainDiscountValue, setMainDiscountValue] = useState(null);
  const [canDiscount, setCanDiscount] = useState(true);
  const [isPayLater, setIsPayLater] = useState(false);
  const [printableValues, setPrintableValues] = useRecoilState(InnerHtml);
  const [cart, setCart] = useRecoilState(CartAtom);
  const [orderDetails, setOrderDetails] = useRecoilState(OrderDetailsAtom);
  const [prevCart, setPrevCart] = useRecoilState(CartHistoryAtom);
  const [showPrevCart, setShowPrevCart] = useRecoilState(ShowCartHistoryAtom);
  const payableAmount = useRecoilValue(PayableAmountAtom);


  const fetchCustomer = async () => {
    try {
      const { data: response } = await CustomersApi.show(editableCustomerId.id);
      setEditableCustomer(response.data);
      setShowCustomerAddModal(true);
    } catch (e) {
      console.log(e);
    }
  };


  const clearData = () => {
    setSubTotal(0);
    setCart([]);
    setPrevCart({});
    setShowPrevCart(false);
    setFixedTotal(null);
    setPrevCartTotal(0);
    setMainDiscountValue(null);
    setEditableCustomer("");
    setOrderDetails({});
  };

  const handleSave = async () => {
    const item_list = cart.map((item) => ({
      product: item.id,
      variant: item?.selectedVariant?.id,
      quantity: item.qty,
      note: item.note,
      addon_ids: item.selectedAddons?.map((addon) => addon.id),
      discount: item.discount || 0,
      // total_amount: item.price,
    }));
    const payload = {
      table: orderDetails.table?.id,
      customer: orderDetails.customer?.id,
      order_type: orderDetails?.type,
      note: orderDetails?.note,
      is_complementary: false,
      discount: mainDiscountValue || "0.00",
      // sub_total: subTotal,
      // grand_total: subTotal,
    };
    // console.log(payload, item_list)
    // return;
    if (isPayLater) {
      payload.is_pay_later = true;
      setIsPayLater(false);
    }
    let resp;
    if (Object.keys(prevCart).length) {
      payload.items_create = item_list;
      payload.items_update = editValueObjects;
      const { data: response } = await OrdersApi.update(prevCart.id, payload);
      resp = response?.data;
    } else {
      payload.items = item_list;
      const { data: response } = await OrdersApi.create(payload);
      resp = response?.data;
    }
    if (resp.message) {
      Object.keys(resp.message).forEach((each) => {
        if (each === "items") {
          Object.keys(resp.message[each]).forEach((itemError) =>
            toast.error(`${itemError}- ${resp.message[each][itemError]}`)
          );
          return;
        }
        toast.error(`${each} - ${resp.message[each]}`);
      });
      return;
    }
    let grandTotal = subTotal * ((100 - Number(mainDiscountValue)) / 100);
    if (resp.kot_time) setPrintModal(resp);
    return { grandTotal, check: resp.kot_time ? true : false, id: resp.id };
  };

  useEffect(() => {
    const totalPrice = cart.reduce((acc, cur) => {
      return (
        acc +
        parseFloat(cur.price) *
          parseFloat(cur.qty) *
          ((100 - parseFloat(cur.discount || 0)) / 100)
      );
    }, 0);
    setSubTotal(
      (parseFloat(prevCartTotal) + parseFloat(totalPrice.toFixed(2))).toFixed(2)
    );
  }, [cart, prevCartTotal]);

  useEffect(() => {
    if (editableCustomerId) {
      fetchCustomer();
    }
  }, [editableCustomerId]);

  useEffect(() => {
    if (editableCustomerId) {
      setEditableCustomerId(null);
      setEditableCustomer(null);
    }
  }, [showCustomerAddModal]);

  useEffect(async () => {
    if (isPayLater) {
      const returnValue = await handleSave();
      if (returnValue) {
        clearData();
      }
    }
  }, [isPayLater]);

  useEffect(() => {
    if (initial) {
      if (showPrevCart && Object.keys(prevCart).length) {
        setOrderDetails({
          table: prevCart.table,
          customer: prevCart.customer,
          note: prevCart.note,
          type: prevCart.order_type,
        });
        const total = prevCart.items.reduce(
          (acc, cur) =>
            acc +
            ((cur.variant_price +
              cur.addons.reduce((ac, cu) => ac + cu.price, 0)) *
              (100 - cur.discount)) /
              100,
          0
        );
        setSubTotal(total);
        setMainDiscountValue(prevCart.discount);
        setPrevCartTotal(total);
        setFixedTotal(total);
        setShowPrevCart(false);
      } else if (!showPrevCart && Object.keys(prevCart).length) {
        setPrevCart({});
        setOrderDetails({});
      } else if (orderDetails.customer) {
        setOrderDetails({});
      }
      setInitial(false);
    }
    // return () => {
    //   setPrevCart({})
    //   setShowPrevCart(false)
    //   setCart([])
    // }
    return () => {
      setOrderDetails({});
    };
  }, [prevCart]);

  useEffect(() => {
    if (editValueObjects.length) {
      let newTotal = fixedTotal;
      const allPrev = prevCartSection?.reduce(
        (acc, cur) => acc.concat(cur.subRows),
        []
      );
      const sumAllPrev = allPrev.reduce((acc, cur) => acc + cur.price, 0);
      editValueObjects.forEach((each) => {
        if (each.is_deleted) {
          newTotal = newTotal - each.price;
        } else {
          newTotal =
            sumAllPrev - (each.real_price * (each.discount || 0)) / 100;
        }
      });
      setPrevCartTotal(newTotal);
    }
  }, [editValueObjects]);

  useEffect(() => {
    const allPrev = prevCartSection?.reduce(
      (acc, cur) => acc.concat(cur.subRows),
      []
    );
    const hasDiscountInPrev = allPrev?.find(
      (each) => each.discount && each.discount > 0
    );
    const hasDiscountInCart = cart?.find(
      (each) => each.discount && each.discount > 0
    );
    if (hasDiscountInCart || hasDiscountInPrev) {
      setMainDiscountValue("");
      setCanDiscount(false);
    } else {
      setCanDiscount(true);
    }
  }, [prevCartSection, cart]);

  return (
    <>
      <motion.div
        initial={{ x: 100 }}
        animate={showPaymentPanel && payableAmount ? { x: "-100%" } : { x: 0 }}
        transition={{ duration: 0.6 }}
        className={`w-[30%] z-20 h-screen bg-white fixed top-0  right-0 `}
      >
        <div className="p-5">
          <TableName />
          <CustomerDetails
            setShowCustomerAddModal={setShowCustomerAddModal}
            setEditableCustomer={setEditableCustomerId}
            editableCustomer={showCustomerAddModal}
          />
          <DinningButtons />
          <Cart
            editValueObjects={editValueObjects}
            setEditValueObjects={setEditValueObjects}
            prevCartSection={prevCartSection}
            setPrevCartSection={setPrevCartSection}
          />
          <div className="absolute w-full h-[30vh] bottom-0 right-0 bg-[#C3CDE4]/[0.35]">
            <input
              value={orderDetails.note || ""}
              onChange={(e) =>
                setOrderDetails({ ...orderDetails, note: e.target.value })
              }
              placeholder="Additional cooking instructions (note)"
              className="w-[90%] text-xs relative -top-[3.5vh] left-[1vw] border-b outline-none"
            />
            <div className="p-5">
              <div className="flex justify-between font-light mb-[3vh]">
                <div>
                  <div>Sub Total</div>
                  <div>Discount</div>
                </div>
                <div className="max-w-[8vw] mr-1 ">
                  <div className="text-right mr-5">
                    Rs {subTotal > 0 ? subTotal : "0000.00"}
                  </div>
                  <div className="w-[90%] border border-grey rounded-lg flex pr-2">
                    <input
                      disabled={!canDiscount}
                      className={`w-[90%] text-xs bg-inherit outline-none text-right px-2 py-1 ${!canDiscount && "cursor-not-allowed"}`}
                      value={mainDiscountValue || ""}
                      onChange={(e) =>
                        canDiscount &&
                        setMainDiscountValue(
                          (parseInt(e.target.value) <= 100 &&
                            parseInt(e.target.value)) ||
                            ""
                        )
                      }
                    />
                    {mainDiscountValue && "%"}
                  </div>
                </div>
              </div>
              {/* <div className="flex items-center">
                <input type="checkbox" />
                <span className="text-xs font-light ml-2">Complimentary</span>
              </div> */}
              <div className="flex justify-between mt-3 text-sm font-bold p-3">
                <div>Total Payment</div>
                <div>
                  Rs{" "}
                  {subTotal > 0
                    ? (
                        subTotal *
                        ((100 - Number(mainDiscountValue)) / 100)
                      ).toFixed(2)
                    : "0000.00"}
                </div>
              </div>
              <PaymentButton
                handleSave={handleSave}
                clearData={clearData}
                printModal={printModal}
                setIsPayLater={setIsPayLater}
              />
            </div>
          </div>
        </div>
      </motion.div>
      <AnimatePresence>
        {showCustomerAddModal && (
          <AddCustomerModal
            setShowCustomerAddModal={setShowCustomerAddModal}
            editableCustomer={editableCustomer}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {printModal && (
          <Modal
            heading={`Print KOT (Order No: ${printModal?.order_no})`}
            handleCancel={() => setPrintModal(null)}
          >
            <section className="w-full flex justify-center">
              <div className="w-[30%] flex justify-between my-5">
                <button
                  onClick={() => setPrintModal(null)}
                  className="px-2 py-1 bg-blue-grey text-grey hover:bg-red hover:text-white rounded-md"
                >
                  Save
                </button>
                <button
                  onClick={async () => {
                    const { data: response } = await OrdersApi.kotPrint(
                      printModal.id,
                      printModal.kot_time
                    );
                    let printHtml = response.data.print_list.join(
                      `<p style="page-break-before: always">`
                    );
                    setPrintModal(null);
                    setPrintableValues(printHtml);
                  }}
                  className="px-2 py-1 bg-blue-grey text-grey hover:bg-blue hover:text-white rounded-md"
                >
                  Print
                </button>
              </div>
            </section>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default SidePane;
