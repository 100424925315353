import React from "react";

const KitchenIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <circle cx="13" cy="13" r="13" fill="white" fillOpacity="0.15" />
      <path
        d="M17.6667 7H8.33333C7.59695 7 7 7.59695 7 8.33333V17.6667C7 18.403 7.59695 19 8.33333 19H17.6667C18.403 19 19 18.403 19 17.6667V8.33333C19 7.59695 18.403 7 17.6667 7Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.667 9.66675H9.66699V16.0667H11.667V9.66675Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.333 9.66675H14.333V16.0667H16.333V9.66675Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default KitchenIcon;
