import React from "react";
import { motion } from "framer-motion";

function Card({ product, text, price, onClick }) {
  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      className="h-40 w-36 rounded-lg relative shadow top-0 left-0  m-5 bg-white hover:bg-light-grey/30 hover:shadow-xl"
    >
      <button
        onClick={() => onClick(product)}
        className="h-full w-full absolute"
      >
        <div className="h-full w-full flex flex-col items-center justify-evenly ">
          <div className="flex-grow flex items-end ">
            <div className="my-2 flex h-12 w-12 items-center cursor-pointer justify-center rounded-full bg-orange-400 text-xs text-white">
              000
            </div>
          </div>
          <div className="flex h-20 flex-col justify-evenly items-center">
            <div className="w-full text-center text-sm font-semibold">
              {text}
            </div>
            <div className="mt-0.5 text-xs text-center font-semibold text-blue">
              {price}
            </div>
          </div>
        </div>
      </button>
    </motion.div>
  );
}

export default Card;
