import React from "react";
import Circle from "../../assets/Circle";
import FilledCircle from "../../assets/FilledCircle";
import { motion } from "framer-motion";

const Radio = ({ checked, onClick }) => {
  return (
    <div onClick={onClick}>
      <div
        className={`relative cursor-pointer max-h-[24px] h-[24px] my-2`}
      >
        <Circle />
      </div>
        {checked && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}
            className="relative -top-[28px] left-[4px] h-0"
          >
            <FilledCircle />
          </motion.div>
        )}
    </div>
  );
};

export default Radio;
