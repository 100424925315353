import React, { useEffect, useState } from "react";
import TableTile from "../../utils/TableTile";
import { AnimatePresence, motion } from "framer-motion";
import Modal from "../../helperComponents/Modal";
import SeatNow from "./ModalDataSection/SeatNow";
import { TablesApi } from "../../apis/TableArea/Tables";
import Form from "../../forms/Table";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { CartHistoryAtom, TableNameSelectToOngoingOrdersAtom } from "../../common/Atoms";
import TableListLoader from "../../Loaders/TableList";

const TablesList = ({
  tableArea,
  setSelectedTable,
  selectedTable,
  selectedArea,
  openSeatNowModal,
  setOpenSeatNowModal,
  editMode,
  setTableArea,
  loading
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");
  const [capacity, setCapacity] = useState("");
  const [editableId, setEditableId] = useState(null);
  const [errorObject, setErrorObject] = useState({});
  const [customerData, setCustomerData] = useState({})
  const setSelectedToOngoingOrder = useSetRecoilState(TableNameSelectToOngoingOrdersAtom)
  const navigate = useNavigate()

  const handleMouseEnter = (e, item) => {
    if (editMode) return;
    e.stopPropagation();
    setSelectedTable(item.id);
  };

  const handleMouseLeave = (e) => {
    if (editMode) return;
    e.stopPropagation();
    setSelectedTable(null);
  };

  const handleTableAdd = async (area) => {
    const payload = {
      name,
      capacity,
    };
    console.log(payload,'payload');
    const { data: response } = await TablesApi.create(area.id, payload);
    console.log(response, "response");
    if (!response.data.message) {
      setOpenModal(false);
      const changedTableArea = tableArea.map((eachArea) => {
        if (area.id !== eachArea.id) {
          return eachArea;
        }
        eachArea.tables.push({ id: response.data.id, name, capacity, is_available: true  });
        return eachArea;
      });
      setTableArea(changedTableArea);
    } else {
      setErrorObject(response.data.message);
      
    }
  };

  const handleDeleteTable = async (area, item) => {
    const tableFilteredAreas = tableArea.map((eachArea) => {
      if (eachArea.id !== area.id) return eachArea;
      const filteredArea = eachArea.tables.filter(
        (table) => table.id !== item.id
      );
      return { ...eachArea, tables: filteredArea };
    });
    setTableArea(tableFilteredAreas);
    await TablesApi.destroy(area.id, item.id);
  };

  const handleTableUpdate = async () => {
    const payload = {
      name,
      capacity,
    };
    const { data: response } = await TablesApi.update(
      openModal.id,
      editableId.id,
      payload
    );
    if (!response.data.message) {
    const updatableArea = tableArea.find((area) => openModal.id === area.id);
    const updatableTable = updatableArea.tables.find(
      (table) => editableId.id === table.id
    );

    updatableTable.name = name;
    updatableTable.capacity = capacity;
    setTableArea([...tableArea]);
    setOpenModal(false);
    setEditableId(null);
    } else {
      setErrorObject(response.data.message)
    }
  };

  const handleFunction = () => {
    setSelectedToOngoingOrder({area_name: customerData.area, table_name: customerData.table})
    navigate("/tables/ongoing-orders")
  }

  useEffect(() => {
    if (!openModal) {
      setName("");
      setCapacity("");
      setEditableId(null);
    } else if (editableId) {
      setName(editableId.name);
      setCapacity(editableId.capacity);
    } else {
      setName("");
      setCapacity("");
    }
    if(Object.keys(errorObject) !== 0){
      setErrorObject({})
    }
  }, [editableId, openModal]);

  console.log(errorObject ,"errorObject");
  return (
    <section
      onClick={() => setSelectedTable(null)}
      className="w-full h-full mt-10 overflow-y-scroll scrollbar-hide"
    >
      {loading ? (
        <TableListLoader />
      ) : tableArea.slice(1).map(
        (area) =>
          (area.name === selectedArea || selectedArea === "All") && (
            <div key={area.name}>
              {selectedArea === "All" && (
                <p className="text-blue text-lg font-semibold">{area.name}</p>
              )}
              <div className="w-full flex flex-wrap justify-start pr-10">
                <AnimatePresence>
                  {area.tables.map((table, i) => (
                    <TableTile
                      key={table.id}
                      area={area}
                      item={table}
                      selectedTable={selectedTable}
                      // setOpenReserveModal={setOpenReserveModal}
                      setOpenSeatNowModal={setOpenSeatNowModal}
                      editMode={editMode}
                      setCustomerData={setCustomerData}
                      setOpenModal={setOpenModal}
                      setEditableId={setEditableId}
                      handleDeleteTable={handleDeleteTable}
                      handleMouseEnter={handleMouseEnter}
                      handleMouseLeave={handleMouseLeave}
                    />
                  ))}
                </AnimatePresence>
                <AnimatePresence>
                  {editMode && (
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      onClick={() => {
                        setOpenModal(area);
                      }}
                      className=" w-24 h-24 flex justify-center cursor-pointer items-center rounded-lg mx-4 my-5 bg-grey/60 text-white text-2xl border-4 border-yellow"
                    >
                      +
                    </motion.button>
                  )}
                </AnimatePresence>
              </div>
              <AnimatePresence>
                {openModal && (
                  <Modal
                    heading="Add New Table"
                    handleCancel={() => setOpenModal(false)}
                  >
                    <Form
                      name={name}
                      openModal={openModal}
                      capacity={capacity}
                      errorObject={errorObject}
                      setErrorObject={setErrorObject}
                      setName={setName}
                      setCapacity={setCapacity}
                      setOpenModal={setOpenModal}
                      handleTableSubmit={
                        editableId ? handleTableUpdate : handleTableAdd
                      }
                    />
                  </Modal>
                )}
              </AnimatePresence>
            </div>
          )
      )}

      <AnimatePresence>
        {openSeatNowModal && (
          <Modal
            handleCancel={() => setOpenSeatNowModal(false)}
            heading={`Table No. ${openSeatNowModal.name}`}
            headerFunction={handleFunction}
            secondary
            callFunction
          >
            <SeatNow handleCancel={() => setOpenSeatNowModal(false)} data={customerData} handleFunction={handleFunction} />
          </Modal>
        )}
        {/* {openReserveModal && (
          <Modal
            handleCancel={() => setOpenReserveModal(false)}
            heading="Table No. 00A"
            ternery
          >
            <SeatNow handleCancel={() => setOpenReserveModal(false)} />
          </Modal>
        )} */}
      </AnimatePresence>
    </section>
  );
};

export default TablesList;
