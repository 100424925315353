import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
} from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import NoView from "../assets/NoView";
import Pen from "../assets/Pen";
import Trash from "../assets/Trash";
import View from "../assets/View";
import { categoryAtom, kitchenAtom } from "../common/Atoms";
import ManagementTable from "../helperComponents/ManagementTable";
import { useRecoilState } from "recoil";
import Modal from "../helperComponents/Modal";
import { ProductCategoriesApi } from "../apis/Products/Category";
import { CategoriesContext } from "../sections/ManageCategory";
import UpwardArrow from "../assets/UpwardArrow";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { ProductsApi } from "../apis/Products";

const CategoryManagementTable = ({
  setEditableCategory,
  setShowModal,
  showModal,
  kitchens,
}) => {
  const [page, setPage] = useState(1);
  const [view, setView] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleteableId, setDeleteableId] = useState(null);
  const [initial, setInitial] = useState(true);
  const [sortValue, setSortValue] = useState("");
  const [categories, setCategories] = useRecoilState(categoryAtom);
  const { totalCount, setTotalCount, pageLength, searchString } =
    useContext(CategoriesContext);
  const [openDropDown, setOpenDropDown] = useState("");

  const handleDelete = async (id) => {
    try {
      await ProductCategoriesApi.destroy(id);
      await fetchCategories();
      toast.success("Product Deleted Successfull");
    } catch (e) {
      toast.error(`Some issue ${e}`);
    }
    setDeleteableId(null);
  };

  const handleViewChange = async (id) => {
    const change = !categories[id].is_visible;
    const { data: response } = await ProductCategoriesApi.update(
      categories[id].id,
      { name: categories[id].name, is_visible: change }
    );

    if (change) {
      toast.error("Deactived Successfully");
    } else {
      toast.success("Actived Successfully");
    }

    if (response.message) {
      toast.error(Object.values(response.message).join(", "));
      return;
    }
    const changableCategories = categories.map((category, idx) =>
      idx === id ? { ...category, is_visible: change } : category
    );
    setCategories(changableCategories);
  };

  const fetchCategories = useCallback(async () => {
    let params = { length: pageLength, page, search: searchString };
    if (sortValue) params.sort_by = sortValue;
    const { data: response } = await ProductCategoriesApi.list({
      params,
    });
    setCategories(response.data.results);
    setTotalCount(response.data.count);
    setLoading(false);
  }, [page, pageLength, searchString, sortValue]);

  const CATEGORIES = categories.map((category, index) => ({
    id: category.id,
    is_visible: category.is_visible,
    name: category.name,
    kitchen: category.kitchen,
  }));

  const COLUMN = [
    {
      id: "name",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          Name
          <button
            className={`stroke-white ml-2 ${
              sortValue === "-name" ? "" : "rotate-180"
            }`}
            onClick={() =>
              setSortValue((prev) => (prev === "name" ? "-name" : "name"))
            }
          >
            <UpwardArrow />
          </button>
        </div>
      ),
      accessor: "name",
      width: 200,
      Cell: ({ value }) => (
        <div
          className="font-semibold
      "
        >
          {value}
        </div>
      ),
    },
    // {
    //   id: "NoOfItems",
    //   Header: "No of Items",
    //   accessor: "noOfItems",
    //   width: 200,
    //   Cell: ({ value }) => <div className="w-[90%]">{value}</div>,
    // },
    {
      id: "kitchen",
      Header: ({ column }) => (
        <div className="flex justify-start items-center m-auto">Kitchen</div>
      ),
      accessor: "kitchen",
      Cell: ({ value, row }) => {
        return (
          <div className="w-32 h-8 text-xs m-auto ">
            <div
              onClick={() => {
                setOpenDropDown((prev) => (prev === row.id ? "" : row.id));
              }}
              className={`w-full flex ${
                openDropDown === row.id ? "rounded-t-lg border-b" : "rounded-lg"
              } bg-white justify-between items-center py-2 px-3 stroke-black`}
            >
              {value === null ? "---" : (value?.name)}
              <motion.div
                initial={{ rotate: 180 }}
                animate={
                  openDropDown === row.id ? { rotate: 0 } : { rotate: 180 }
                }
              >
                <UpwardArrow />
              </motion.div>
            </div>
            {openDropDown === row.id && (
              <div className="relative bg-white w-full z-10 shadow-lg rounded-md top-0 left-0">
                <div
                    onClick={async () => {
                      setOpenDropDown("");
                      const { data: response } =
                        await ProductCategoriesApi.update(row.original.id, {
                          kitchen: null,
                          name: row.original.name,
                        });
                      if (response.success) {
                        toast.success("Kitchen Added Successfully");
                        fetchCategories();
                      }
                    }}
                    className=" px-2 py-2 hover:bg-blue/10 cursor-pointer"
                  >
                    ---
                  </div>
                {kitchens?.map((each) => (
                  <div
                    onClick={async () => {
                      setOpenDropDown("");
                      const { data: response } =
                        await ProductCategoriesApi.update(row.original.id, {
                          kitchen: each.id,
                          name: row.original.name,
                        });
                      if (response.success) {
                        toast.success("Kitchen Added Successfully");
                        fetchCategories();
                      }
                    }}
                    className=" px-2 py-2 hover:bg-blue/10 cursor-pointer"
                  >
                    {each.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      },
    },
    {
      id: "buttons",
      width: 200,
      Header: () => <div className="flex w-full justify-end pr-8">Action</div>,
      Cell: ({ row }) => (
        <div className="flex justify-end">
          {row.original.is_visible ? (
            <div
              onClick={() => handleViewChange(row.index)}
              className="stroke-grey cursor-pointer bg-white px-1 py-1.5 mr-4 rounded-md hover:bg-grey hover:stroke-white"
            >
              <View />
            </div>
          ) : (
            <div
              onClick={() => handleViewChange(row.index)}
              className="cursor-pointer rounded-md p-1 mr-4 bg-grey stroke-white"
            >
              <NoView />
            </div>
          )}
          <div
            onClick={() => {
              setEditableCategory(row.original);
            }}
            className="stroke-grey cursor-pointer bg-white p-1 mr-4 rounded-md hover:bg-red hover:stroke-white"
          >
            <Pen />
          </div>
          <div
            onClick={() => setDeleteableId(row.original.id)}
            className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white"
          >
            <Trash />
          </div>
        </div>
      ),
    },
  ];

  const columns = useMemo(
    () => COLUMN,
    [view, categories, openDropDown, kitchens]
  );
  const data = useMemo(() => CATEGORIES, [categories]);


  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page - 1, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
    },
    useSortBy,
    usePagination
  );

  const { gotoPage, setPageSize } = tableInstance;

  useEffect(() => {
    if (!initial) {
      fetchCategories();
      gotoPage(page - 1);
      return () => {};
    }
  }, [page]);

  useEffect(() => {
    if (!showModal && !initial) {
      fetchCategories();
    }
  }, [showModal]);

  useEffect(() => {
    if (sortValue && !initial) {
      fetchCategories();
    }
  }, [sortValue]);

  useEffect(() => {
    setPageSize(pageLength);
    if (page === 1) {
      fetchCategories();
    } else {
      setPage(1);
    }
    setInitial(false);
    return () => {};
  }, [pageLength, searchString]);

  return (
    <>
      <ManagementTable
        tableInstance={tableInstance}
        setPage={setPage}
        page={page}
        loading={loading}
        setLoading={setLoading}
        totalCount={totalCount}
      />
      {deleteableId !== null && (
        <Modal
          handleCancel={() => setDeleteableId(null)}
          heading="Confirm Delete"
        >
          <section className="pt-10 px-5">
            <p className="font-semibold">
              Are you sure you want to delete the category ?
            </p>
            <div className="flex justify-center mb-10 mt-5">
              <button
                onClick={() => setDeleteableId(null)}
                className="px-3 py-2 mr-3 font-semibold rounded-md bg-blue-grey text-grey"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDelete(deleteableId)}
                className="bg-red font-semibold text-white rounded-md px-3 py-2"
              >
                Delete
              </button>
            </div>
          </section>
        </Modal>
      )}
    </>
  );
};

export default CategoryManagementTable;
