import React, { useState, useEffect } from "react";
import ContentLoader from "react-content-loader";
import { OrdersApi } from "../../apis/Orders.js/index.js";
import CategoryLoader from "../../Loaders/Category.js";
import AreaTile from "../../utils/AreaTile";

const CategoryFilter = ({ selectedKitchen, selectedCategory, setSelectedCategory }) => {
  const [orderCategories, setOrderCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  
  const fetchCategories = async () => {
    const params = selectedKitchen ? { kitchen: selectedKitchen.id } : {}
    const { data: response } = await OrdersApi.categoriesList({params});
    console.log(response.data)
    let sortedResponse = [...response.data]
    sortedResponse.sort((a,b) => a.name.localeCompare(b.name))
    setOrderCategories(sortedResponse);
    setLoading(false);
  };

  useEffect(() => {
    setOrderCategories([])
    setLoading(true)
  }, [selectedKitchen]);

  useEffect(() => {
    if(loading) {
      fetchCategories();
    }
  }, [loading])

  return (
    <div className="flex mt-[3vh] flex-wrap px-2 justify-items-center">
      {loading ? (
        <CategoryLoader />
      ) : orderCategories.length ? (
        <AreaTile
          key={"All"}
          area={"All"}
          onClick={() => selectedCategory !== null && setSelectedCategory(null)}
          selected={null === selectedCategory}
          secondary
          small
        />
      ) : (
        <></>
      )}
      {orderCategories.map((category) => (
        <AreaTile
          key={category.id}
          area={category.name}
          onClick={() =>
            selectedCategory?.id !== category.id &&
            setSelectedCategory(category)
          }
          selected={category.id === selectedCategory?.id}
          secondary
          small
        />
      ))}
    </div>
  );
};

export default CategoryFilter;
