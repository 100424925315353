import React from "react";
import ContentLoader from "react-content-loader";

const OrdersCardLoader = () => {
  return (
    <ContentLoader
      width="56vw"
      height="80vh"
      speed={1}
      backgroundColor="#ffffff"
      foregroundColor="#F3F7FF"
    >
      <rect x="0vw" y="5" rx="10" ry="10" width="24vw" height="15vh" />
      <rect x="30vw" y="5" rx="10" ry="10" width="24vw" height="15vh" />

      <rect x="0vw" y="17vh" rx="10" ry="10" width="24vw" height="15vh" />
      <rect x="30vw" y="17vh" rx="10" ry="10" width="24vw" height="15vh" />

      <rect x="0vw" y="34vh" rx="10" ry="10" width="24vw" height="15vh" />
      <rect x="30vw" y="34vh" rx="10" ry="10" width="24vw" height="15vh" />

      <rect x="0vw" y="51vh" rx="10" ry="10" width="24vw" height="15vh" />
      {/* <rect x="30vw" y="51vh" rx="10" ry="10" width="24vw" height="15vh" /> */}
    </ContentLoader>
  );
};

export default OrdersCardLoader;
