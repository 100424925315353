import React from "react";

const AddCustomer = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <circle cx="13" cy="13" r="13" fill="white" fillOpacity="0.15" />
      <path
        d="M16.6667 19V17.6667C16.6667 16.9594 16.3858 16.2811 15.8857 15.781C15.3856 15.281 14.7073 15 14.0001 15H9.33341C8.62617 15 7.94789 15.281 7.4478 15.781C6.9477 16.2811 6.66675 16.9594 6.66675 17.6667V19"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 12.3333C13.1394 12.3333 14.3333 11.1394 14.3333 9.66667C14.3333 8.19391 13.1394 7 11.6667 7C10.1939 7 9 8.19391 9 9.66667C9 11.1394 10.1939 12.3333 11.6667 12.3333Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3333 10.333V14.333"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3333 12.333H17.3333"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddCustomer;
