import { authenticatedAxiosInstance as axios } from "../axios"

const list = () => axios.get("/areas")
const create = payload => axios.post("/areas", payload)
const update = (id, payload) => axios.patch(`/areas/${id}`, payload)
const destroy = id => axios.delete(`/areas/${id}`)
const customerDetail = (table_id, area_id) => axios.get(`/areas/${area_id}/tables/${table_id}/order-info`)

export const TableAreasApi = {
    list,
    create,
    update,
    destroy,
    customerDetail,
}