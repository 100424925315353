import React from "react";
import AddressField from "../../../../../utils/form/AddressField";
import FullNameField from "../../../../../utils/form/FullNameField";
import Header from "../../../../../utils/form/Header";
import InputField from "../../../../../utils/form/InputField";
import PhoneNumberField from "../../../../../utils/form/PhoneNumberField";

const Form = ({ setShowModal }) => {
  return (
    <div className=" w-full h-full flex flex-col justify-between">
      <Header heading="Vendor Details" setModalOpen={() => setShowModal(false)} />
      <div className="max-h-[80%] mb-7 overflow-y-scroll scrollbar-hide text-sm">
        <FullNameField />
        <InputField heading="Company Name" />
        <AddressField />
        <PhoneNumberField />
        <InputField heading="GST Number" />
      </div>
      <div className="bg-blue cursor-pointer w-full text-lg font-semibold text-white flex justify-center rounded-lg py-2">
        Save
      </div>
    </div>
  );
};

export default Form;
