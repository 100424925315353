import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { AppApi } from "../../apis/Core";
import { ReactComponent as Icon } from "../../assets/filterIcon.svg";
import FilterModal from "./Modal";

const Filter = ({ filterBy, setFilterBy }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState([])

  const fetchPaymentMethod = async () => {
    const {data: response} = await AppApi.info()
    setFilterOptions(response.data.payment_types)
  }

  useEffect(() => {
    fetchPaymentMethod()
  }, [])

  return (
    <div className="w-20 h-[4vh]">
      <button
        onClick={() => setOpenFilter(!openFilter)}
        className="flex justify-center items-center font-semibold bg-white rounded px-5 w-20 text-[12px] py-[4px] h-full"
      >
        <div className="mr-1">
          <Icon />
        </div>
        Filter
      </button>
      <AnimatePresence>
      {openFilter && <FilterModal setFilterBy={setFilterBy} filterBy={filterBy} filterOptions={filterOptions} />}
      </AnimatePresence>
    </div>
  );
};

export default Filter;
