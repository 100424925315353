import React from 'react'

const useScrollDirection = (initialScrollTop = 0) => {
    const UP = true;
    const DOWN = false;
  
    const initialState = {
      node: window,
      lastScrollTop: initialScrollTop,
      ticking: false,
      direction: null
    };
  
    const reducer = (state, { type, lastScrollTop, direction, node }) => {
      switch (type) {
        case "TICK_DONE":
          return { ...state, ticking: false, lastScrollTop, direction };
        case "TICK_START":
          return { ...state, ticking: true };
        case "SET_NODE":
          return { ...state, node };
        default:
          return state;
      }
    };
  
    const [state, dispatch] = React.useReducer(reducer, initialState);
  
    const setNode = React.useCallback(
      node => {
        if (node !== window) {
          dispatch({
            type: "SET_NODE",
            node
          });
        }
      },
      [dispatch]
    );
  
    const scrollHandler = React.useCallback(
      () => {
        const { node, lastScrollTop, ticking } = state;
        if (!ticking) {
          window.requestAnimationFrame(() => {
            const scrollVar = node === window ? "scrollY" : "scrollTop";
            dispatch({
              type: "TICK_DONE",
              lastScrollTop: node[scrollVar],
              direction: lastScrollTop < node[scrollVar] ? DOWN : UP
            });
          });
          dispatch({ type: "TICK_START" });
        }
      },
      [state, dispatch]
    );
  
    React.useEffect(
      () => {
        const { node } = state;
        node.addEventListener("scroll", scrollHandler);
        return () => {
          node.removeEventListener("scroll", scrollHandler);
        };
      },
      [scrollHandler, state.node]
    );
    return [state.direction, setNode];
  };

export default useScrollDirection