import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination } from "react-table";
import Pen from "../../../../assets/Pen";
import Trash from "../../../../assets/Trash";
import ManagementTable from "../../../../helperComponents/ManagementTable";

const VendorManagementTable = () => {
  const [page, setPage] = useState(1);
  const CUSTOMERS = Array.from({ length: 100 }).map((_, index) => ({
    name: "Priti Patel",
    company: "Blah Blah society",
    address: "Blah Blah society, Alkpauri",
    phone: `000${index}00`,
    gst: `000${index}00`,
  }));

  const COLUMN = [
    {
      id: "name",
      Header: "Name",
      accessor: "name",
      Cell: ({ value }) => <div className="font-semibold">{value}</div>,
    },
    {
      id: "company",
      Header: "Company Name",
      accessor: "company",
    },
    {
      id: "address",
      Header: "Address",
      accessor: "address",
    },
    {
      id: "phone",
      Header: "Phone",
      accessor: "phone",
    },
    {
      id: "gst",
      Header: "PAN/GST No.",
      accessor: "gst",
    },
    {
      id: "buttons",
      Header: () => <div className="flex w-full justify-end pr-8">Action</div>,
      Cell: ({ row }) => (
        <div className="flex justify-end pr-5">
          <div className="stroke-grey cursor-pointer bg-white p-1 mr-4 rounded-md hover:bg-red hover:stroke-white">
            <Pen />
          </div>
          <div className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white">
            <Trash />
          </div>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMN, []);
  const data = useMemo(() => CUSTOMERS, []);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
    usePagination
  );

  const { gotoPage } = tableInstance;

  useEffect(() => gotoPage(page - 1), [page]);

  return (
    <ManagementTable
      tableInstance={tableInstance}
      setPage={setPage}
      page={page}
    />
  );
};

export default VendorManagementTable;
