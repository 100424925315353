import React from 'react'
import ContentLoader from 'react-content-loader'

const ManagementTableLoader = () => {
  return (
    <ContentLoader
      width="110"
      height="30"
      speed={1}
      backgroundColor="#ecebeb"
      foregroundColor="#F3F7FF"
      // className="bg-red"
    >
      <rect x="0" y="0" rx="10" ry="10" width="30" height="30" />
      <rect x="40" y="0" rx="10" ry="10" width="30" height="30" />
      <rect x="80" y="0" rx="10" ry="10" width="30" height="30" />
    </ContentLoader>
  )
}

export default ManagementTableLoader