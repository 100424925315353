import React, { useState, useEffect } from "react";
import SidePane from "../../SidePane/Order";
import Card from "../../utils/Card";
import CategoryFilter from "./CategoryFilter";
import Searchbar from "../../utils/Searchbar";
import { OrdersApi } from "../../apis/Orders.js/index.js";
import Modal from "../../helperComponents/Modal";
import ProductSelect from "../../forms/ProductSelect";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  BillPrintableValueAtom,
  CartAtom,
  CartHistoryAtom,
  InnerHtml,
  ShowCartHistoryAtom,
} from "../../common/Atoms";
import { validateProductToCart } from "../../common/Calculations";
import ProductLoader from "../../Loaders/Product";
import KitchenFilter from "./KitchenFilter";

const Order = () => {
  const [orderProducts, setOrderProducts] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedKitchen, setSelectedKitchen] = useState(null);
  const [searchedProduct, setSearchedProduct] = useState("");
  const [loading, setLoading] = useState(true);
  const [variantsAddonsSelectable, setVariantsAddonsSelectable] =
    useState(null);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [selectedProductOptions, setSelectedProductOptions] = useState({
    selectedAddons: [],
  });
  const [errorObject, setErrorObject] = useState({});
  const setSelectedOngoingOrder = useSetRecoilState(CartHistoryAtom);
  const setShowInCart = useSetRecoilState(ShowCartHistoryAtom);
  const [cart, setCart] = useRecoilState(CartAtom);

  const fetchProducts = async () => {
    let params = { category: selectedCategory?.id, search: searchedProduct };
    if (selectedKitchen?.id) {
      params.kitchen = selectedKitchen.id;
    }
    const { data: response } = await OrdersApi.productsList({
      params,
    });

    let sortedResponse = [...response.data];
    sortedResponse.sort((a, b) => a.title.localeCompare(b.title));
    console.log(sortedResponse);
    setOrderProducts(sortedResponse);
    setLoading(false);
  };

  const handleProductSelect = (product) => {
    const variantsCheckCondition = !(
      product.variants?.[0]?.options[0].includes("Default") ||
      product.variants.length <= 1
    );
    if (!variantsCheckCondition) {
      product.selectedVariant = product.variants?.[0];
    }
    const addonsCheckCondition = product.addons.length > 0;

    if (!product.real_price) product.real_price = product.price;
    if (variantsCheckCondition || addonsCheckCondition) {
      setVariantsAddonsSelectable(product);
      return;
    }

    const checkForNonDefaultSingleVariant =
      !product.variants?.[0]?.options[0].includes("Default") &&
      product.variants.length === 1 &&
      product.variants?.[0]?.options.length === 1;

    if (checkForNonDefaultSingleVariant) {
      product.selectedVariant = product.variants[0];
    }
 
    if (cart.some((eachProduct) => eachProduct.id === product.id)) {
      const reducer = (acc, cur) => {
        return cur.id === product.id
          ? acc.concat({ ...cur, qty: cur.qty + 1 })
          : acc.concat(cur);
      };
      const newCart = cart.reduce(reducer, []);
      setCart(newCart);
      return;
    }
    product.qty = 1;
    const new_product = { ...product, qty: 1 };
    setCart([...cart, new_product]);
  };

  const handleProductWithOptions = (price) => {
    let product = { ...variantsAddonsSelectable };
    console.log(variantsAddonsSelectable, "variantsAddonsSelectable");
    product.price = Object.values(price)
      .reduce((acc, cur) => acc + cur, 0)
      .toFixed(2);
    product.real_price = product.price;
    if (selectedProductOptions.selectedAddons) {
      product.selectedAddons = selectedProductOptions.selectedAddons;
    }
    if (selectedProductOptions.selectedVariant) {
      product.selectedVariant = selectedProductOptions.selectedVariant;
    }

    const shouldUpdate = validateProductToCart(
      product,
      cart,
      setCart,
      setErrorObject
    );
    if (shouldUpdate !== "error") {
      setVariantsAddonsSelectable(null);
    } else {
      return;
    }
    if (Number.isInteger(shouldUpdate)) {
      return;
    }
    const settable = { ...product, qty: 1 };
    setCart([...cart, settable]);
  };

  const handleSelectProductFromSearch = (e) => {
    if (orderProducts.length === 1) {
      handleProductSelect(orderProducts[0]);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchProducts();
  }, [selectedCategory, searchedProduct, selectedKitchen]);

  useEffect(() => {
    if (!variantsAddonsSelectable) {
      setErrorObject({});
      setSelectedProductOptions({ selectedAddons: [] });
    }
  }, [variantsAddonsSelectable]);

  useEffect(() => {
    return () => {
      setSelectedOngoingOrder([]);
      setShowInCart(false);
      setCart([]);
    };
  }, []);

  return (
    <div className="w-full h-[80vh]">
      <div className="w-2/3 lg:w-[62%] relative z-0">
        {/* <div className="text-3xl font-bold text-black">Order</div> */}

        <Searchbar
          onEnter={handleSelectProductFromSearch}
          setter={(text) => setSearchedProduct(text)}
        />

        <KitchenFilter
          selectedKitchen={selectedKitchen}
          setSelectedKitchen={setSelectedKitchen}
        />

        <CategoryFilter
          selectedKitchen={selectedKitchen}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />

        <div className="h-[65vh] border-t-2 border-gray-200 mt-[3vh]">
          <div className="w-full h-full mt-[2vh] px-2 overflow-y-scroll scrollbar-hide">
            <div className="w-full grid grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6">
              {loading ? (
                <ProductLoader />
              ) : (
                orderProducts?.map((product, index) => (
                  <Card
                    onClick={handleProductSelect}
                    product={product}
                    key={product.id}
                    text={product.title}
                    price={`Rs. ${product.price}`}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      <SidePane />
      {variantsAddonsSelectable && (
        <Modal
          heading={variantsAddonsSelectable.title}
          handleCancel={() => setVariantsAddonsSelectable(null)}
        >
          <ProductSelect
            errorObject={errorObject}
            setErrorObject={setErrorObject}
            variantsAddonsSelectable={variantsAddonsSelectable}
            selectedProductOptions={selectedProductOptions}
            setSelectedProductOptions={setSelectedProductOptions}
            setVariantsAddonsSelectable={setVariantsAddonsSelectable}
            handleProductWithOptions={handleProductWithOptions}
          />
        </Modal>
      )}
    </div>
  );
};

// {Array.from({ length: 40 }).map((_, index) => (
//   <Card
//     key={index}
//     text="Cheesy Chicken Pasta"
//     price="Rs. 000.00"
//   />
// ))}

export default Order;
