import moment from "moment";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ReportApi } from "../apis/Report/Report";
import Page from "../assets/Page";
import Printer from "../assets/Printer";
import Trash from "../assets/Trash";
import UpwardArrow from "../assets/UpwardArrow";
import { InnerHtml, SalesCustomerAtom } from "../common/Atoms";
import ManagementTable from "../helperComponents/ManagementTable";
import Searchbar from "../utils/Searchbar";
import DatePicker from "../helperComponents/DatePicker";
import Radio from "../utils/form/Radio";
import Download from "../assets/Download";
import Filter from "../helperComponents/Filter";
import ShowEntries from "../utils/ShowEntries";

const ReportTable = ({
  searchString,
  openPicker,
  addDateInParam,
  setAddDateInParam,
  fromDate,
  toDate,
  filterBy,
  setPriceTotal,
  orderType,
  setSearchString,
  setOpenPicker,
  setFromDate,
  setToDate,
  setOrderType,
  downloadReport,
  setFilterBy,
}) => {
  const [totalCount, setTotalCount] = useState(0);
  const [pageLength, setPageLength] = useState(10);
  const [page, setPage] = useState(1);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initial, setInitial] = useState(true);
  const [sortValue, setSortValue] = useState("");
  const setPrintableValues = useSetRecoilState(InnerHtml);
  const [customer, setCustomer] = useRecoilState(SalesCustomerAtom);

  // const CUSTOMERS = Array.from({ length: 100 }).map((_, index) => ({
  //   name: "Priti Patel",
  //   amount: `Rs.00${index}.00`,
  //   date: "dd/mm/yy",
  //   order_id: "oiru2398r23oi",
  //   payment_type: index%2 === 0 ? "card": "online payment",
  // }));

  const fetchReports = useCallback(async () => {
    let params = {
      length: pageLength,
      page,
      search: searchString,
      payment_method: filterBy?.id,
      order_type: orderType,
      date_from: fromDate,
      date_to: toDate,
    };
    if (addDateInParam) {
      params = {
        length: pageLength,
        page,
        search: searchString,
        date_from: fromDate,
        date_to: toDate,
        payment_method: filterBy?.id,
        order_type: orderType,
      };
    }
    if (customer) {
      params.customer = customer;
      setCustomer(null);
    }
    if (sortValue) {
      params.sort_by = sortValue;
    }
    const { data: response } = await ReportApi.list({ params });

    setReports(response.data.results);
    setTotalCount(response.data.count);
    setPriceTotal(response.data.price_total);
    setLoading(false);
  }, [
    page,
    searchString,
    fromDate,
    toDate,
    filterBy,
    sortValue,
    orderType,
    pageLength,
  ]);

  const handlePrint = async (id) => {
    const { data: response } = await ReportApi.print(id);
    let printHtml = response.data.print_list.join(
      `<p style="page-break-before: always">`
    );
    setPrintableValues(printHtml);
  };

  const COLUMN = [
    {
      id: "date",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          Date
          <button
            className={`stroke-white ml-2 ${
              sortValue === "-created_at" ? "" : "rotate-180"
            }`}
            onClick={() => {
              setSortValue(
                sortValue === "created_at" ? "-created_at" : "created_at"
              );
            }}
          >
            <UpwardArrow />
          </button>
        </div>
      ),
      accessor: "date",
      Cell: ({ value }) => <p>{moment(value).format("DD/MM/YYYY")}</p>,
    },
    {
      id: "order_no",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          Order no
          <button
            className={`stroke-white ml-2 ${
              sortValue === "-orders__order_no" ? "" : "rotate-180"
            }`}
            onClick={() =>
              setSortValue(
                sortValue === "orders__order_no"
                  ? "-orders__order_no"
                  : "orders__order_no"
              )
            }
          >
            <UpwardArrow />
          </button>
        </div>
      ),
      accessor: "order_nos",
      Cell: ({ row }) => <p>{row.original.order_nos.join(", ")}</p>,
    },
    {
      id: "name",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          Name
          {/* <button
            className={`stroke-white ml-2 ${
              sortValue === "name" ? "" : "rotate-180"
            }`}
            onClick={() => setSortValue(sortValue === "name" ? "-name" : "name")}
          >
            <UpwardArrow />
          </button> */}
        </div>
      ),
      accessor: "customer_name",
      Cell: ({ value }) => <div className="font-semibold">{value}</div>,
    },
    {
      id: "payment_type",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          {/* <button
            className={`stroke-white mr-2 ${
              column.isSortedDesc ? "" : "rotate-180"
            }`}
            onClick={() => column.toggleSortBy()}
          >
            <UpwardArrow />
          </button> */}
          Payment type
        </div>
      ),
      accessor: "payment_types",
      Cell: ({ row }) => (
        <p>{row.original.payment_types.map((each) => each.name).join(", ")}</p>
      ),
    },
    {
      id: "amount",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          {/* <button
            className={`stroke-white mr-2 ${
              column.isSortedDesc ? "" : "rotate-180"
            }`}
            onClick={() => column.toggleSortBy()}
          >
            <UpwardArrow />
          </button> */}
          Payment amount
        </div>
      ),
      accessor: "payment_amount",
      Cell: ({ value }) =>
        new Intl.NumberFormat("en-IN").format(
          value
        ),
    },
    {
      id: "buttons",
      Header: () => <div className="flex w-full justify-end pr-8">Action</div>,
      Cell: ({ row }) => (
        <div className="flex justify-end pr-5">
          {/* <div className="stroke-grey cursor-pointer bg-white p-1 mr-4 rounded-md hover:bg-grey hover:stroke-white">
            <Page />
          </div> */}
          <div
            onClick={() => handlePrint(row.original.id)}
            className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white"
          >
            <Printer />
          </div>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMN, [sortValue]);
  const data = useMemo(() => reports || [], [reports]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page - 1, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
    },
    useSortBy,
    usePagination
  );

  const { gotoPage } = tableInstance;

  useEffect(() => {
    if (!initial) {
      fetchReports();
    }
  }, [searchString, filterBy, orderType]);

  useEffect(() => {
    if (!initial) {
      if (!openPicker) {
        fetchReports();
      }
    }
  }, [openPicker]);

  useEffect(() => {
    if (!initial) {
      fetchReports();
    }
  }, [sortValue]);

  useEffect(() => {
    fetchReports();
    gotoPage(page - 1);
    if (initial) {
      setInitial(false);
    }
  }, [page, pageLength, sortValue]);

  return (
    <div>
      <div>
        <div className="flex justify-start items-center gap-12">
          <Searchbar setter={setSearchString} />
          <div className="flex flex-row items-center text-center justify-center gap-2">
            <div className="px-3 h-[30px] rounded-r-md flex flex-row items-center gap-2 justify-between text-sm font-semibold rounded-lg">
              <div className="pt-1">
                <Radio
                  checked={orderType === "pay_later"}
                  onClick={(e) => setOrderType("pay_later")}
                />
              </div>
              <p>Pay Later</p>
            </div>

            <div className=" px-3 h-[30px] rounded-r-md flex flex-row items-center gap-2  justify-between text-sm font-semibold rounded-lg">
              <div className="pt-1 ">
                <Radio
                  checked={orderType === "regular"}
                  onClick={(e) => setOrderType("regular")}
                />
              </div>
              <p>Regular Transactions</p>
            </div>
          </div>
        </div>

        <section className="flex justify-between mt-[2vh] mb-[2vh] ">
          <div className="flex items-center">
            <p className="font-semibold">Date: </p>
            <DatePicker
              fromDate={fromDate}
              setOpenPicker={setOpenPicker}
              toDate={toDate}
              setFromDate={setFromDate}
              setAddDateInParam={setAddDateInParam}
              setToDate={setToDate}
            />
          </div>

          {/* Filter by payment */}

          <div className="flex justify-center items-center">
            <div className="flex justify-center items-center cursor-pointer rounded-lg h-fit mr-5 ">
              <div
                onClick={downloadReport}
                className="px-2 py-1 border-r bg-white rounded-l-md"
              >
                <Download />
              </div>
              <p
                onClick={downloadReport}
                className="px-3 font-semibold bg-white rounded-r-md"
              >
                Excel
              </p>
            </div>
            <div className="flex justify-center items-center gap-4 ">
              <div>
                <Filter filterBy={filterBy} setFilterBy={setFilterBy} />
              </div>
              <div>
                <ShowEntries
                  setPageLength={setPageLength}
                  totalCount={totalCount}
                  pageLength={pageLength}
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      <ManagementTable
        tableInstance={tableInstance}
        setPage={setPage}
        page={page}
        totalCount={totalCount}
        loading={loading}
      />
    </div>
  );
};

export default ReportTable;
