import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { PayableAmountAtom, PaymentFromPayLater } from "../../../common/Atoms";
import OnlinePaymentMethods from "../OnlinePaymentMethods";

const OnlineSection = ({
  paymentMethod,
  onlineMethod,
  setOnlineMethod,
  payedAmount,
  setPayedAmount,
  setConfirmPaymentDisable
}) => {
  const payableAmount = useRecoilValue(PayableAmountAtom);
  const fromPayLater = useRecoilValue(PaymentFromPayLater);

  return (
    <div className="w-full">
      <div className="w-[40%] flex justify-between text-sm mb-5">
        <p className="font-medium text-grey">Total Amount</p>
        <p>Rs. {payableAmount}</p>
      </div>
      {(
        <div className="flex justify-between my-5">
          <div className="text-grey text-sm">Amount paid</div>
          <input
            className="text-black text-xs w-40 outline-blue bg-white px-2"
            placeholder="Input amount"
            value={payedAmount}
            onClick={() => setPayedAmount("")}
            onChange={(e) => setPayedAmount(parseFloat(e.target.value) || "")}
          />
        </div>
      )}
      <div className="text-sm">Payment methods available:</div>
      <OnlinePaymentMethods
        onlineMethod={onlineMethod}
        setOnlineMethod={setOnlineMethod}
        paymentMethod={paymentMethod.childrens}
        setConfirmPaymentDisable={setConfirmPaymentDisable}
      />
    </div>
  );
};

export default OnlineSection;
