import React, { useMemo, useEffect } from "react";
import Percentage from "../../../assets/Percentage";
import Trash from "../../../assets/Trash";
import { useTable, useExpanded } from "react-table";
import TableLayout from "../../../helperComponents/CartTableLayout";
import Edit from "../../../assets/Edit";
import { AnimatePresence, motion } from "framer-motion";

const Cart = ({ orders }) => {
  // const data = useMemo(() => Array.from({length: 10}).map(() => (
  //   {
  //     name: "Cheesy Chickin Pasta",
  //     qty: "0",
  //     price: "0",
  //     singlePrice: "Rs 000.00",
  //   }
  // )));

  const usableOrders = () => {
    const rows = orders?.map((order) => ({
      id: order.id,
      order_no: order.order_no,
      expanded: true,
      items: order.items,
    }));
    rows?.forEach((row) => {
      const kotTimeList = [];
      row.subRows = row.items.reduce((acc, cur) => {
        const index = kotTimeList.indexOf(cur.kot_time);
        if (index < 0) {
          kotTimeList.push(cur.kot_time);
          acc.push({
            kotTime: cur.kot_time,
            orderId: row.id,
            subRows: [cur],
          });
          return acc;
        }
        acc[index]?.subRows.push(cur);
        return acc;
      }, []);
    });

    return rows || [];
  };

  const data = useMemo(() => usableOrders(orders), [orders]);

  // useEffect(() => {
  //   usableOrders()
  // },[orders])

  // const data = useMemo(
  //   () =>
  //     Array.from({ length: 5 }).map(() => {
  //       const subRows = Array.from({ length: 3 }).map(() => ({
  //         name: "Cheesy Chickin Pasta",
  //         qty: "0",
  //         price: "0",
  //         singlePrice: "Rs 000.00",
  //       }));
  //       return {
  //         subRows,
  //       };
  //     }),
  //   []
  // );

  const columns = useMemo(
    () => [
      {
        id: "name",
        Header: () => <div className="text-left text-base">Items</div>,
        width: 600,
        accessor: "product_title",
        Cell: ({ value, row }) => (
          <div className="mt-1 h-fit">
            <div className="flex justify-start">
              <div className="text-sm mr-1">{value} {!row.original.variant_options[0].includes("Default") && `(${row.original.variant_options.join("/")})`}</div>
            </div>
            {row.original.addons.length ? (
            <div className="text-xs font-semibold text-blue">
              toppings: {row.original.addons.map(addon => addon.name).join(", ")}
            </div>
            ) : <></>}
          </div>
        ),
      },
      {
        id: "qty",
        Header: () => <div className="text-left text-base pl-1">Qty</div>,
        accessor: "quantity",
        Cell: ({ value }) => (
          <div className="mr-2 w-fit flex select-none justify-center py-1 rounded-md bg-blue">
            <div className="flex cursor-default text-xs select-none justify-center px-5 text-white">
              {value}
            </div>
          </div>
        ),
      },
      {
        id: "price",
        Header: () => <div className="text-left text-base">Price</div>,
        width: 120,
        accessor: "variant_price",
        Cell: ({ value }) => `Rs ${String(parseFloat(value).toFixed(2))}`,
      },
    ],
    []
  );
  useEffect(() => {
    if(data?.length === 1 && data?.[0]?.subRows.length === 1){
      tableInstance.toggleRowExpanded("0.0", true)
    } else {
      tableInstance.toggleAllRowsExpanded(false)
    }
  }, [data])

  const tableInstance = useTable(
    { columns, data, manualExpandedKey: "expanded" },
    useExpanded
  );

  return (
    <AnimatePresence>
      <motion.div
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{duration: 0.6}}
        key={orders?.[0].id}
        className="mt-5 flex text-sm font-light max-h-[66vh] h-fit w-full justify-center overflow-y-scroll px-3"
      >
        <TableLayout tableInstance={tableInstance} isPayLater />
      </motion.div>
    </AnimatePresence>
  );
};

export default Cart;
