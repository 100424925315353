import { authenticatedAxiosInstance as axios } from "../axios";

const list = (params) => axios.get(`/orders/pay-later`, params)
const show = (id) => axios.get(`/orders/pay-later/${id}`)
const print = (id) => axios.get(`/orders/pay-later/${id}/print`)
const pay = (id, payload) => axios.post(`/orders/pay-later/${id}/pay`, payload)

export const PayLaterApi = {
    list,
    show,
    print,
    pay
}
