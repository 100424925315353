import React, { useState, useEffect } from "react";
import { KitchensApi } from "../../apis/Kitchens";
import { motion } from "framer-motion";
import AreaTile from "../../utils/AreaTile";
import { OrdersApi } from "../../apis/Orders.js";
import TableAreaLoader from "../../Loaders/TableArea";

const KitchenFilter = ({ selectedKitchen, setSelectedKitchen }) => {
  const [kitchens, setKitchens] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchKitchens = async () => {
    const { data: response } = await OrdersApi.kitchensList()
    setKitchens(response.data);
    setLoading(false)
  };

  useEffect(() => {
    fetchKitchens();
  }, []);

  return (
    <div className="mt-4 mb-3">
      <div className="flex px-2">
        {loading ? (
            <TableAreaLoader margin={false} />
        ) : (
        <AreaTile
          key={"All"}
          area={"All"}
          onClick={() => selectedKitchen !== null && setSelectedKitchen(null)}
          selected={null === selectedKitchen}
        />)}
        {kitchens.map((kitchen) => (
          <AreaTile
            key={kitchen.name}
            area={kitchen.name}
            onClick={() =>
              selectedKitchen?.id !== kitchen.id && setSelectedKitchen(kitchen)
            }
            selected={kitchen.id === selectedKitchen?.id}
          />
        ))}
      </div>
    </div>
  );
};

export default KitchenFilter;
