import React from "react";
import MainPage from "../../helperComponents/MainPage";

const Vendors = () => {
  const links = ["Vendor Management", "Purchase Order"];
  const urls = [
    "/vendors/vendor-management",
    "/vendors/purchase-order",
  ];

  return <MainPage links={links} urls={urls} />;
};

export default Vendors;
