import React from 'react'

const Form = ({ setShowModal, input, errorMessage, setErrorMessage, setInput, handleSubmit }) => {
  return (
    <section>
        <div className="p-5">
            <p className={`w-full rounded-md text-sm text-center p-1 mb-6 ${errorMessage ? "bg-red/5" : "bg-blue-grey"}`}>Kitchen Name</p>
            <input 
              value={input}
              onChange={(e) => {
                setErrorMessage(null)
                console.log(e.target.value);
                setInput(e.target.value)
              }}
              className={`bg-blue-grey rounded-md w-full p-1 text-center text-sm ${errorMessage && "border border-red"}`}
            />
            {
              errorMessage && (
                <p className="text-red text-xs">{errorMessage}</p>
              )
            }
        </div>
        <div className="w-full bg-blue-grey py-2 flex justify-center text-sm">
            <button 
              onClick={setShowModal}
              className="mr-2 bg-white text-grey rounded-md py-2 px-3 font-medium"
            >
              cancel
            </button>
            <button 
              onClick={handleSubmit}
              className="ml-2 bg-blue text-white rounded-md py-2 px-3 font-medium"
            > 
              save
            </button>
        </div>
    </section>
  )
}

export default Form