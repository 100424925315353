import React from "react";
import { Outlet } from "react-router-dom";
import Topbar from "./Topbar";

const MainPage = ({ links, urls }) => {
  return (
    <div className="w-full box-content bg-blue-grey px-12">
      <div className="w-full h-screen max-h-screen overflow-scroll scrollbar-hide">
      <Topbar links={links} urls={urls} />

      <Outlet />
      </div>
    </div>
  );
};

export default MainPage;
