import React from 'react'

const ShowEntries = ({ pageLength, setPageLength, totalCount }) => {
  const handlePageLengthChange = (event) => {
    setPageLength(parseInt(event.target.value, 10));
  };

  return (
    <div className="flex items-center">
      <div className="font-semibold">Show Entries</div>
      <select
        value={pageLength}
        onChange={handlePageLengthChange}
        name="entries"
        className="rounded text-grey mx-3 px-2 outline-blue bg-white"
      >
        {[10, 25, 50, 100].map((idx) => (
          <option key={idx} value={idx}>
            {idx}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ShowEntries;
