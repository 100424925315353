import { authenticatedAxiosInstance as axios } from "../axios"

const show = () => axios.get("/accounts/profile")
const update = payload => axios.post("/accounts/profile", payload)
const changePassword = payload => axios.post("/accounts/profile/change-password", payload)

export const ProfileApi = {
    show,
    update,
    changePassword
}
