import React from "react";

const Clock = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
      <path
        d="M4.99992 9.16634C7.30111 9.16634 9.16659 7.30086 9.16659 4.99967C9.16659 2.69849 7.30111 0.833008 4.99992 0.833008C2.69873 0.833008 0.833252 2.69849 0.833252 4.99967C0.833252 7.30086 2.69873 9.16634 4.99992 9.16634Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 2.5V5L6.66667 5.83333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Clock;
