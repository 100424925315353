import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthApi } from "../apis/Accounts/Auth";
import Spinner from "../assets/Spinner";
import { setToLocalStorage } from "../common/LocalStorage";
import logo from "../assets/poslogo.png";
function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const hostHasCom = window.location.host.includes(".com")
  const host = window.location.host
    .replace("www.", "")
    .replace(".com", "")
    .split(".");
  // if(!hostHasCom){
  //   host.splice(-2, 2);
  // } else {
  //   host.splice(-1, 1);
  // }
//  const subdomain = "signaturebrew.pixelotechapp.dev";
  const subdomain = host[0];

  const handleLogin = async () => {
    const payload = {
      sub_domain: subdomain,
      username,
      password,
    }; 
    try {
      setLoading(true);
      const { data: response } = await AuthApi.login(payload);
      const { access, refresh } = response.data;

      console.log(access , "access", refresh , 'refresh');
      setToLocalStorage(access, refresh);
      if (access) {
        navigate("/");
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      if (e.response?.data?.data?.message?.sub_domain) {
        setErrorMessage(e.response.data.data.message.sub_domain);
        return;
      }
      let err = e.response.data.data.message;
      let message = "";
      if (typeof err === "string") {
        setErrorMessage(err);
        return;
      }
      Object.keys(err).forEach((eachError) => {
        message = `${message && `${message}\n`} ${eachError} : ${
          err[eachError]
        }`;
      });
      setErrorMessage(message);
    }
  };

  return (
    <div className="flex w-screen h-screen">
      {/* Left Side */}
      <div className="w-3/5 bg-white relative">
        {/* Menu icon  */}

        <div className=" absolute top-2 left-6 w-34 h-28 cursor-pointer">
          <img
            className="overflow-hidden h-full w-full object-fit"
            src={logo}
            alt="logo"
          />
        </div>

        {/* Swipe indicators */}
        <div className="flex absolute bottom-20 inset-x-1/2 w-full">
          <div className="h-2 w-2 rounded-full bg-blue mr-4"></div>
          <div className="h-2 w-2 rounded-full bg-light-grey"></div>
          <div className="h-2 w-2 rounded-full bg-light-grey ml-4"></div>
        </div>
      </div>

      {/* Right Side */}
      <div className="w-2/5 bg-blue-grey flex items-center">
        {/* Form container */}
        <div className="mx-auto flex flex-col w-full px-24">
          {/* Top text */}
          <div className="text-blue font-bold	text-4xl">Welcome Back</div>
          <div className={`text-grey font-bold ${!errorMessage && "mb-6"}`}>
            Please sign in to continue
          </div>
          {/* Text fields */} 
          {errorMessage && (
            <div className="bg-red/5 text-black text-center text-sm border border-red  mb-3 whitespace-pre-wrap">
              {errorMessage}
            </div>
          )}
          <label className={`relative block mb-6`}>
            <input
              value={username}
              onChange={(e) => {
                setErrorMessage("");
                setUsername(e.target.value);
              }}
              className="block bg-white w-full rounded-lg py-4 pl-9 pr-3 focus:outline-none focus:border-none"
              placeholder="Username"
              type="text"
            />
          </label>
          <label className="relative block">
            <input
              value={password}
              onChange={(e) => {
                setErrorMessage("");
                setPassword(e.target.value);
              }}
              className="block bg-white w-full rounded-lg py-4 pl-9 pr-3 focus:outline-none focus:border-none"
              placeholder="Password"
              type="password"
            />
          </label>
          <button
            onClick={handleLogin}
            className="bg-blue w-40 mx-auto mt-12 py-3 rounded-lg text-white flex justify-center items-center gap-3"
          >
            <p> Sign in</p> {loading && <Spinner />}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
