import React from "react";
import ContentLoader from "react-content-loader";

const TableListLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="full"
      height="full"
      backgroundColor="#ffffff"
      foregroundColor="#F3F7FF"
    >
      <rect x="0vw" y="0" rx="5" ry="5" width="6vw" height="28" />

      <rect x="20" y="60" rx="5" ry="5" width="96" height="96" />
      <rect x="146" y="60" rx="5" ry="5" width="96" height="96" />
      <rect x="272" y="60" rx="5" ry="5" width="96" height="96" />
      <rect x="388" y="60" rx="5" ry="5" width="96" height="96" />
      <rect x="504" y="60" rx="5" ry="5" width="96" height="96" />

      <rect x="20" y="176" rx="5" ry="5" width="96" height="96" />
      <rect x="146" y="176" rx="5" ry="5" width="96" height="96" />
      <rect x="272" y="176" rx="5" ry="5" width="96" height="96" />
      <rect x="388" y="176" rx="5" ry="5" width="96" height="96" />
      {/* <rect x="504" y="176" rx="5" ry="5" width="96" height="96" /> */}


      <rect x="0vw" y="312" rx="5" ry="5" width="6vw" height="28" />

      <rect x="20" y="372" rx="5" ry="5" width="96" height="96" />
      <rect x="146" y="372" rx="5" ry="5" width="96" height="96" />
      <rect x="272" y="372" rx="5" ry="5" width="96" height="96" />
      <rect x="388" y="372" rx="5" ry="5" width="96" height="96" />
      <rect x="504" y="372" rx="5" ry="5" width="96" height="96" />

      <rect x="20" y="488" rx="5" ry="5" width="96" height="96" />
      <rect x="146" y="488" rx="5" ry="5" width="96" height="96" />
      <rect x="272" y="488" rx="5" ry="5" width="96" height="96" />
      <rect x="388" y="488" rx="5" ry="5" width="96" height="96" />
      {/* <rect x="504" y="488" rx="5" ry="5" width="96" height="96" /> */}
    </ContentLoader>
  );
};

export default TableListLoader;
