import React, { useState, useEffect, useCallback } from "react";
import Searchbar from "../utils/Searchbar";
import DatePicker from "../helperComponents/DatePicker";
import Filter from "../helperComponents/Filter";
import ReportTable from "../tables/Report";
import PrintablePage from "./PrintablePage";
import { useRecoilState, useRecoilValue } from "recoil";
import { InnerHtml, IsOwnerAtom } from "../common/Atoms";
import { useNavigate } from "react-router-dom";
import Download from "../assets/Download";
import axios from "axios";
import { BASE_URL } from "../apis/axios";
import { ReportApi } from "../apis/Report/Report";
import { debounce } from "lodash";
import Radio from "../utils/form/Radio";
const Report = () => {
  const [searchString, setSearchString] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [toDate, setToDate] = useState(
    new Date(new Date().setHours(23, 59, 0, 0))
  );
  const [filterBy, setFilterBy] = useState(null);
  const [priceTotal, setPriceTotal] = useState(null);
  const [openPicker, setOpenPicker] = useState(false);
  const [printableValues, setPrintableValues] = useRecoilState(InnerHtml);
  const [addDateInParam, setAddDateInParam] = useState(false);
  const [orderType, setOrderType] = useState("regular");
  const isOwner = useRecoilValue(IsOwnerAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (printableValues) {
      window.print();
      setPrintableValues(null);
    }
  }, [printableValues]);

  useEffect(() => {
    if (!isOwner) {
      navigate("/");
    }
  }, []);

  const downloadReport = async () => {
    const { data: response, headers: responseHeaders } = await ReportApi.list({
      responseType: "blob",
      params: {
        download_format: "xlsx",
        order_type: orderType,
        date_from: fromDate,
        date_to: toDate,
      },
    });

    // const fileNameHeader = "x-suggested-filename";
    // const suggestedFileName = responseHeaders[fileNameHeader];
    //     const effectiveFileName = (suggestedFileName === undefined
    //                 ? "allergierOchPreferenser.xlsx"
    //                 : suggestedFileName);

    // console.log(response, "RESPONE")

    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "report.xlsx");
    document.body.appendChild(link);
    link.click();
  };

  // const downloadReport = useCallback(debounce(downloadApiCall(), 300), [])
  return (
    <div className="relative z-10 pt-[5vh] bg-blue-grey w-full h-screen flex flex-col">
      <p className="text-3xl font-bold bg-blue-grey px-10 h-fit">Report</p>
      <div className="w-full px-10 h-full overflow-y-auto">
        <ReportTable
          openPicker={openPicker}
          addDateInParam={addDateInParam}
          setAddDateInParam={setAddDateInParam}
          searchString={searchString}
          fromDate={fromDate}
          toDate={toDate}
          filterBy={filterBy}
          setPriceTotal={setPriceTotal}
          orderType={orderType}
          setSearchString={setSearchString}
          setOpenPicker={setOpenPicker}
          setFromDate={setFromDate}
          setToDate={setToDate}
          setOrderType={setOrderType}
          downloadReport={downloadReport}
          setFilterBy={setFilterBy}
        />
      </div>

      <div className="sticky z-20 bg-white/40 bottom-0 left-0 h-fit w-full px-[4vw] py-[2vh]">
        <div className="flex flex-col items-end justify-start">
          <p className="text-lg font-semibold mr-4">
            Total Amount:{" "}
            <span className="ml-5 text-blue">
              Rs{" "}
              {new Intl.NumberFormat("en-IN", {
                maximumSignificantDigits: 3,
              }).format(priceTotal?.all)}
            </span>{" "}
          </p>
          <div className="w-full flex justify-end mt-2">
            <div className="bg-white px-4 py-2 mr-1 rounded-md">
              <p>
                Cash:{" "}
                <span className="ml-2">
                  Rs{" "}
                  {new Intl.NumberFormat("en-IN", {
                    maximumSignificantDigits: 3,
                  }).format(priceTotal?.payment_types.CASH || "0000.00")}
                </span>
              </p>
            </div>
            <div className="bg-white px-4 py-2 mr-1 rounded-md">
              <p>
                Card:{" "}
                <span className="ml-2">
                  Rs{" "}
                  {new Intl.NumberFormat("en-IN", {
                    maximumSignificantDigits: 3,
                  }).format(priceTotal?.payment_types.CARD || "0000.00")}
                </span>
              </p>
            </div>
            <div className="bg-white px-4 py-2 rounded-md">
              <p>
                Online:{" "}
                <span className="ml-2">
                  Rs{" "}
                  {new Intl.NumberFormat("en-IN", {
                    maximumSignificantDigits: 3,
                  }).format(
                    (priceTotal &&
                      Object.values(priceTotal.payment_types.ONLINE).reduce(
                        (acc, cur) => acc + (Number(cur) || 0),
                        0
                      )) ||
                      "0000.00"
                  )}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <PrintablePage />
    </div>
  );
};

export default Report;
