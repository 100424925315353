import React from "react";

const ItemControl = ({
  value = 0,
  decrement = () => {},
  increment = () => {},
  add = () => {},
  dontShowAdd = false,
  small = false,
  cannotAdd = false,
}) => {
  const color = small
    ? `bg-tuxedo/80 shadow-tuxedo/80`
    : "bg-blue shadow-blue";

  if (value === 0 && !dontShowAdd) {
    return (
      <div
        className={`flex justify-end ${
          small ? "text-xxs" : "text-xs"
        } item-center h-fit`}
      >
        <button
          onClick={() => {
            console.log("heellooo", add);
            add();
          }}
          className={`${cannotAdd ? "bg-tuxedo/60 shadow-tuxedo/60" : color} text-white shadow-md rounded-md px-8 py-1`}
        >
          Add
        </button>
      </div>
    );
  }

  return (
    <div
      className={`flex justify-end ${
        small ? "text-xxs" : "text-xs"
      } item-center h-fit`}
    >
      <button
        onClick={decrement}
        className={`rounded-l-md px-2 py-1  shadow-md ${color} text-white`}
      >
        -
      </button>
      <div
        className={` text-white shadow-md ${color} px-4 py-1 border-x border-white`}
      >
        {value}
      </div>
      <button
        onClick={increment}
        className={`px-2 py-1 shadow-md ${cannotAdd ? "bg-tuxedo/60 shadow-tuxedo/60" : color} text-white rounded-r-md`}
      >
        +
      </button>
    </div>
  );
};

export default ItemControl;
