import React, { useMemo, useState, useEffect, useRef } from "react";
import Percentage from "../../../assets/Percentage";
import { useExpanded, useTable } from "react-table";
import Edit from "../../../assets/Edit";
import Trash from "../../../assets/Trash";
import Modal from "../../../helperComponents/Modal";
import CartTableLayout from "../../../helperComponents/CartTableLayout";
import { useRecoilState } from "recoil";
import {
  CartAtom,
  CartHistoryAtom,
  ShowCartHistoryAtom,
} from "../../../common/Atoms";
import { AnimatePresence } from "framer-motion";
import ReactTooltip from "react-tooltip";
import { validateProductToCart } from "../../../common/Calculations";

const Cart = ({
  editValueObjects,
  setEditValueObjects,
  prevCartSection,
  setPrevCartSection,
}) => {
  const scrollRef = useRef(null);
  const [openNoteModal, setOpenNoteModal] = useState(null);
  const [openPercentageModal, setOpenPercentageModal] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(null);
  // const [orders, setOrders] = useState([]);
  const [note, setNote] = useState("");
  const [percentageValue, setPercentageValue] = useState("");
  const [cart, setCart] = useRecoilState(CartAtom);
  const [prevCart, setPrevCart] = useRecoilState(CartHistoryAtom);
  const [showPrev, setShowPrev] = useRecoilState(ShowCartHistoryAtom);

  // const fetchOrders = () => {
  //   const orderArray = Array.from({ length: 6 }).map((_, index) => ({
  //     id: index,
  //     name: "Cheesy Chickin Pasta",
  //     qty: 0,
  //     price: "0",
  //     singlePrice: "Rs 000.00",
  //   }));
  //   setOrders(cart);
  // };
  // console.log("** Cart **")
  // console.log(cart)
  // console.log("***********")
  const prevCartReducer = () => {
    console.log(prevCart, "prevCart")
    const items = prevCart?.items?.map((item) => ({
      id: item.id,
      product: item.product,
      title: item.product_title,
      qty: item.quantity,
      real_price:
        item.variant_price +
        item.addons.reduce((acc, cur) => acc + cur.price, 0),
      price:
        item.variant_price +
        item.addons.reduce((acc, cur) => acc + cur.price, 0),
      note: item.note,
      variant:
        !item.variant_options.join("/").includes("Default") &&
        item.variant_options.join("/"),
      addons: item.addons,
      selectedAddons: item.addons,
      selectedVariant: { id: item.variant, options: item.variant_options },
      discount: item.discount,
      kotTime: item.kot_time,
    }));
    const usableItems = items?.reduce((acc, cur) => {
      const kotIndex = acc.findIndex(
        (each) => each.kotTime.slice(0, -8) === cur.kotTime.slice(0, -8)
      );
      if (kotIndex >= 0) acc[kotIndex].subRows.push(cur);
      return kotIndex >= 0
        ? acc
        : [...acc, { kotTime: cur.kotTime, subRows: [cur] }];
    }, []);
    setPrevCartSection(usableItems);
  };

  const handlePercentageSubmit = () => {
    const condition = openPercentageModal.split(".");
    if (Number(condition[0]) >= prevCartSection?.length || !prevCartSection) {
      const temp = cart.map((each, id) => {
        const match = condition.length > 1 ? condition[1] : condition[0];
        return id === Number(match)
          ? {
              ...each,
              discount: percentageValue,
            }
          : each;
      });
      setCart(temp);
    } else {
      prevCartSection[Number(condition[0])].subRows[
        Number(condition[1])
      ].discount = percentageValue ? percentageValue : 0;
      const itemId =
        prevCartSection[Number(condition[0])].subRows[Number(condition[1])].id;
      const itemObject = editValueObjects.find((each) => each.id === itemId);
      if (itemObject) {
        itemObject.discount = percentageValue ? percentageValue : 0;
        itemObject.real_price =
          prevCartSection[Number(condition[0])].subRows[
            Number(condition[1])
          ].real_price;
      } else {
        const newItemObject = {};
        newItemObject.discount = percentageValue ? percentageValue : 0;
        newItemObject.real_price =
          prevCartSection[Number(condition[0])].subRows[
            Number(condition[1])
          ].real_price;
        newItemObject.id = itemId;
        editValueObjects.push(newItemObject);
      }
      setEditValueObjects([...editValueObjects]);
      setPrevCartSection([...prevCartSection]);
    }
    setOpenPercentageModal(null);
    setPercentageValue(null);
  };

  const data = useMemo(
    () =>
      prevCartSection?.length
        ? cart?.length
          ? prevCartSection.concat({ subRows: cart })
          : prevCartSection
        : cart,
    [cart, prevCartSection, prevCart]
  );


  const handleOperation = (row, id, value) => {
    if (prevCartSection?.length) {
      const kotId = Number(row.id.split(".")[0]);
      console.log(
        row.original,
        id,
        kotId < prevCartSection?.length,
        kotId , prevCartSection?.length
      );
      if (kotId < prevCartSection?.length) {
        const shouldUpdate = validateProductToCart(
          row.original,
          cart,
          setCart,
          () => {},
          value
        );
        if (Number.isInteger(shouldUpdate)) {
          console.log(shouldUpdate, Number(shouldUpdate) === 0, "should update")
          if(Number(shouldUpdate) === 0){
            console.log("hello")
            setOpenDeleteModal(shouldUpdate)
          }
          return;
        }
        const settable = { ...row.original, qty: 1 };
        setCart([...cart, settable]);
        console.log("settable -> ", settable);
        return;
      }
    }
    if (cart[Number(id)].qty === 1 && value === -1) {
      setOpenDeleteModal(String(id));
      return;
    }
    const changedOrders = cart.map((order, idx) => {
      return idx === Number(id)
        ? {
            ...order,
            qty: order.qty + value >= 0 ? order.qty + value : order.qty,
          }
        : order;
    });
    setCart(changedOrders);
  };

  const COLUMNS = [
    {
      id: "name",
      Header: () => <div className="text-left text-base">Items</div>,
      accessor: "title",
      width: 600,
      Cell: ({ value, row }) => (
        <div
          className={`mt-1 h-fit ${
            row.original.is_deleted && "line-through opacity-50"
          }`}
        >
          <p className={`text-sm mr-1`}>
            {value}
            {((row.original.selectedVariant && !row.original.selectedVariant.options[0].includes("Default")) || row.original.variant) &&
              ` (${
                row.id.includes(".") &&
                !(Number(row.id.split(".")[0]) >= prevCartSection?.length)
                  ? row.original.variant
                  : row.original.selectedVariant.options.join("/")
              })`}
            <span
              onClick={() => {
                if (row.original.note) {
                  setNote(row.original.note);
                }
                setOpenNoteModal((prev) => (!prev ? row.id : null));
              }}
              data-tip={row.original.note}
              className={`cursor-pointer pl-2 ${
                row.original.note ? "stroke-yellow" : "stroke-grey"
              }`}
            >
              <Edit />
            </span>
          </p>
          <ReactTooltip />
          <div className="text-xs text-grey font-medium">
            {row.original.selectedAddons?.length ||
            (row.original.addons?.length &&
              row.id.includes(".") &&
              !row.original.selectedAddons)
              ? `toppings: ${
                  row.original.selectedAddons
                    .map((addon) => addon.name)
                    .join(", ")
                }`
              : ""}
          </div>
        </div>
      ),
    },
    {
      id: "qty",
      Header: () => <div className="text-left text-base pl-1">Qty</div>,
      accessor: "qty",
      Cell: ({ value, row }) => (
        <div
          className={`flex select-none justify-center ${
            row.original.is_deleted && "opacity-40"
          }`}
        >
          {
            <button
              onClick={() => (Number(row.id.split(".")[0]) >= prevCartSection?.length) && handleOperation(row, row.index, -1)}
              className={`${!(Number(row.id.split(".")[0]) >= prevCartSection?.length) ? "bg-blue/40 cursor-default" : "bg-blue cursor-pointer"} border-r text-xs border-white px-2 py-1 text-white bg-blue rounded-l-md`}
            >
              -
            </button>
          }
          <div
            className={`flex ${
              // row.original.kotTime ? "w-[70%] rounded-md mr-2" :
              "w-7"
            } cursor-default text-xs select-none justify-center px-1 py-1 text-white bg-blue`}
          >
            {value}
          </div>
          {
            <button
              onClick={() => handleOperation(row, row.index, 1)}
              className="cursor-pointer select-none text-xs border-l border-white px-2 py-1 text-white bg-blue rounded-r-md"
            >
              +
            </button>
          }
        </div>
      ),
    },
    {
      id: "price",
      Header: () => <div className="text-left text-base">Price</div>,
      accessor: "price",
      Cell: ({ value, row }) => (
        <p className={row.original.is_deleted && "line-through opacity-30"}>
          {" "}
          {`Rs ${String(
            (
              parseFloat(value) *
              Number(row.original.qty) *
              ((100 - (Number(row.original.discount) || 0)) / 100)
            ).toFixed(2)
          )}`}
        </p>
      ),
    },
    {
      id: "buttons",
      width: 150,
      Cell: ({ row }) => (
        <div
          className={`flex justify-evenly ${
            row.original.is_deleted && "hidden"
          }`}
        >
          {/* {!row.original.discount && row.id !== openPercentageModal ? (
            <div
              onClick={() => setOpenPercentageModal(row.id)}
              className="cursor-pointer"
            >
              <Percentage />
            </div>
          ) : (
            <input
              value={
                row.id === openPercentageModal
                  ? percentageValue
                  : row.original.discount
              }
              autoFocus={row.id === openPercentageModal}
              onFocus={() => setOpenPercentageModal(row.id)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handlePercentageSubmit();
                  e.target.blur();
                }
              }}
              onBlur={handlePercentageSubmit}
              onChange={(e) =>
                (parseInt(e.target.value) <= 100 || e.target.value === "") &&
                setPercentageValue(parseInt(e.target.value) || "")
              }
              className="text-green font-semibold p-0.5 w-[50%] text-xs text-center outline-none border-b-2"
            />
          )} */}
          <div
            onClick={() =>
              row.id.includes(".") &&
              !(Number(row.id.split(".")[0]) >= prevCartSection?.length)
                ? setOpenDeleteModal(row.id)
                : setOpenDeleteModal(String(row.index))
            }
            className="cursor-pointer stroke-red"
          >
            <Trash />
          </div>
        </div>
      ),
    },
  ];

  const columns = useMemo(
    () => COLUMNS,
    [cart, prevCartSection, openPercentageModal, percentageValue]
  );

  useEffect(() => {
    if (showPrev) prevCartReducer();
    if (!Object.keys(prevCart).length) setPrevCartSection([]);
    return () => setShowPrev(false);
  }, [showPrev, prevCart]);

  const tableInstance = useTable(
    { columns, data, autoResetExpanded: false },
    useExpanded
  );

  useEffect(() => {
    if(cart?.length){
      tableInstance.toggleRowExpanded(data.length-1, true);
    }
    scrollRef.current?.scrollIntoView({ behaviour: "smooth" });
  }, [data]);

  return (
    <>
      <div className="mt-5 flex flex-col text-sm font-light max-h-[38vh] w-full items-center overflow-y-scroll scrollbar-hide px-3">
        <CartTableLayout tableInstance={tableInstance} />
        <div ref={scrollRef} />
      </div>
      <AnimatePresence>
        {openNoteModal !== null && (
          <Modal
            heading="Note"
            handleCancel={() => {
              setOpenNoteModal(null);
              setNote("");
            }}
          >
            <section className="p-5 mb-3 text-sm">
              <p className="font-semibold mb-2">Enter Note</p>
              <textarea
                value={note}
                onChange={(e) => setNote(e.target.value)}
                className="w-full bg-blue-grey p-2 outline-blue"
              />
              <div className="w-full flex justify-center mt-3">
                <button
                  onClick={() => {
                    setNote("");
                    setOpenNoteModal(null);
                  }}
                  className="bg-blue-grey px-2 py-1 rounded-md text-grey font-medium mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    const condition = openNoteModal.split(".");
                    console.log(prevCartSection, "prevCartSec")
                    if (
                      Number(condition[0]) >= prevCartSection?.length ||
                      !prevCartSection
                    ) {
                      const temp = cart.map((each, id) => {
                        const match =
                          condition.length > 1 ? condition[1] : condition[0];
                        return id === Number(match) ? { ...each, note } : each;
                      });
                      setCart(temp);
                    } else {
                      prevCartSection[Number(condition[0])].subRows[
                        Number(condition[1])
                      ].note = note;
                      const itemId =
                        prevCartSection[Number(condition[0])].subRows[
                          Number(condition[1])
                        ].id;
                      const itemObject = editValueObjects.find(
                        (each) => each.id === itemId
                      );
                      if (itemObject) {
                        itemObject.note = note;
                      } else {
                        const newItemObject = {};
                        newItemObject.id = itemId;
                        newItemObject.note = note;
                        editValueObjects.push(newItemObject);
                      }
                      setEditValueObjects([...editValueObjects]);
                      setPrevCartSection([...prevCartSection]);
                    }
                    setNote("");
                    setOpenNoteModal(null);
                  }}
                  className="bg-blue text-white px-2 py-1 rounded-md font-medium ml-2"
                >
                  Save
                </button>
              </div>
            </section>
          </Modal>
        )}
      </AnimatePresence>
      {/* <AnimatePresence>
        {openPercentageModal && (
          <Modal
            heading="Discount"
            handleCancel={() => setOpenPercentageModal(false)}
          >
            <section className="p-5 mb-3 w-full text-sm">
              <p className="font-semibold mb-2">Enter Discount Percentage</p>
              <input className="w-full bg-blue-grey py-1 px-2 rounded outline-blue" />
              <div className="w-full flex justify-center mt-5">
                <button
                  onClick={() => setOpenPercentageModal(false)}
                  className="bg-blue-grey px-2 py-1 rounded-md text-grey font-medium mr-2"
                >
                  Cancel
                </button>
                <button className="bg-blue text-white px-2 py-1 rounded-md font-medium ml-2">
                  Save
                </button>
              </div>
            </section>
          </Modal>
        )}
      </AnimatePresence> */}
      <AnimatePresence>
        {openDeleteModal && (
          <Modal
            heading="Delete"
            handleCancel={() => setOpenDeleteModal(false)}
          >
            <section className="p-5 mb-3 text-sm">
              <p className="mb-4 font-medium">
                Do you want to remove the item from the order?
              </p>
              <div className="w-full flex justify-center">
                <button
                  onClick={() => setOpenDeleteModal(false)}
                  className="bg-blue-grey px-2 py-1 rounded-md text-grey font-medium mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    if (openDeleteModal.includes(".")) {
                      const [value1, value2] = openDeleteModal.split(".");
                      const [valueA, valueB] = [Number(value1), Number(value2)];
                      prevCartSection[valueA].subRows[valueB].is_deleted = true;
                      const itemId = prevCartSection[valueA].subRows[valueB].id;
                      const itemObject = editValueObjects.find(
                        (each) => each.id === itemId
                      );
                      if (itemObject) {
                        itemObject.is_deleted = true;
                        itemObject.price =
                          prevCartSection[valueA].subRows[valueB].price;
                      } else {
                        const newItemObject = {};
                        newItemObject.is_deleted = true;
                        newItemObject.price =
                          prevCartSection[valueA].subRows[valueB].price;
                        newItemObject.id = itemId;
                        editValueObjects.push(newItemObject);
                      }
                      setEditValueObjects([...editValueObjects]);
                      setPrevCartSection([...prevCartSection]);
                    } else {
                      const tempCart = [...cart];
                      tempCart.splice(Number(openDeleteModal), 1);
                      setCart([...tempCart]);
                    }
                    setOpenDeleteModal(false);
                  }}
                  className="bg-red text-white px-2 py-1 rounded-md font-medium ml-2"
                >
                  Delete
                </button>
              </div>
            </section>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default Cart;
