import { authenticatedAxiosInstance as axios } from "../axios"

const list = (params) => axios.get("/customers",params)
const create = payload => axios.post("/customers", payload)
const show = id => axios.get(`/customers/${id}`)
const update = (id, payload) => axios.patch(`/customers/${id}`, payload)
const destroy = id => axios.delete(`/customers/${id}`)

export const CustomersApi = {
    list,
    create,
    show,
    update,
    destroy
}