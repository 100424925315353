import { authenticatedAxiosInstance as axios } from "../axios";

const list = (params) => axios.get("/products", params);
const create = (payload) => axios.post("/products", payload);
const show = (id) => axios.get(`/products/${id}`);
const update = (id, payload) => axios.put(`/products/${id}`, payload);
const destroy = (id) => axios.delete(`/products/${id}`);
const search = (params) => axios.get(`/products/search`, params);
const statusUpdate = (id, payload) =>  axios.post(`/products/${id}/change-status`, payload)
const addKitchen = (id, payload) => axios.post(`products/${id}/add-kitchen`, payload)
const categorySearch = (categoryId) =>
  axios.get(`/products/categories/${categoryId}/product-list`);

export const ProductsApi = {
  list,
  create,
  show,
  update,
  destroy,
  search,
  statusUpdate,
  categorySearch,
  addKitchen,
};
