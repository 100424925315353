import { authenticatedAxiosInstance as axios } from "../axios"

const list = (params) => axios.get("/products/categories", params)
const create = payload => axios.post("/products/categories", payload)
const show = id => axios.get(`/products/categories/${id}`)
const update = (id, payload) => axios.patch(`/products/categories/${id}`, payload)
const destroy = id => axios.delete(`/products/categories/${id}`)

export const ProductCategoriesApi = {
    list,
    create,
    show,
    update,
    destroy
}
