import React from "react";

const View = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
    >
      <path
        d="M1.66675 7.00033C1.66675 7.00033 4.33341 1.66699 9.00008 1.66699C13.6667 1.66699 16.3334 7.00033 16.3334 7.00033C16.3334 7.00033 13.6667 12.3337 9.00008 12.3337C4.33341 12.3337 1.66675 7.00033 1.66675 7.00033Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 9C10.1046 9 11 8.10457 11 7C11 5.89543 10.1046 5 9 5C7.89543 5 7 5.89543 7 7C7 8.10457 7.89543 9 9 9Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default View;
