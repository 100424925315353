import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import Clock from "../assets/Clock";
import Close from "../assets/Close";
import Pen from "../assets/Pen";
import Printer from "../assets/Printer";
import Modal from "../helperComponents/Modal";
import moment from "moment";

const TableCard = ({
  item,
  selected,
  handleShowInCart,
  handleCancel,
  handleEdit,
  handlePrint,
  handlePay,
  handlePayLater,
}) => {
  const diff_hour =
    (((new Date().getUTCHours() - new Date(item.created_at).getUTCHours()) %
      24) +
      24) %
    24;
  const min =
    (((new Date().getUTCMinutes() - new Date(item.created_at).getUTCMinutes()) %
      24) +
      24) %
    24;
  const hour =
    Math.floor((new Date().getTime() - new Date(item.created_at).getTime()) /
    (1000 * 60 * 60));
  const day =
    Math.floor((new Date().getTime() - new Date(item.created_at).getTime()) /
    (1000 * 60 * 60 * 24));

  const [openPayLaterModal, setOpenPayLaterModal] = useState(false);

  // console.log(moment().format("DD-MM-YYYY,hh:mm"), moment(item.created_at).format("DD-MM-YYYY,hh:mm"), item.order_no)

  const orderTypeStyle =
    item.order_type === "DINE_IN"
      ? "bg-green/10 border-t border-dashed border-green"
      : item.order_type === "TO_GO"
      ? "bg-red/10 border-t border-dashed border-red"
      : "bg-yellow/10 border-t border-dashed border-yellow";
  const orderTypeTextStyle =
    item.order_type === "DINE_IN"
      ? "text-green"
      : item.order_type === "TO_GO"
      ? "text-red"
      : "text-yellow";

  const formatToNormal = (val) =>
    val
      .split("_")
      .map((word, index) =>
        index ? word.toLowerCase() : `${word[0]}${word.slice(1).toLowerCase()}`
      )
      .join(" ");

  return (
    <div
      className={`${
        selected === item.id ? "shadow-xl" : "shadow-sm"
      } my-3 w-fit rounded-xl `}
    >
      <div
        onClick={() => handleShowInCart(item.id)}
        className={` w-[24vw] h-[17vh] bg-white rounded-t-xl p-[2vh] flex flex-col justify-around cursor-pointer`}
      >
        <div className="flex w-full justify-between items-end">
          <div className="flex flex-col items-start">
            <div className="text-sm font-semibold mb-[1vh]">
              Table No. {item.table_name} {item?.area_name &&  `(${item?.area_name})`}
            </div>
            <div className="text-xs">{item?.customer?.name}</div>
          </div>
          <div className="flex flex-col items-end">
            <div className="mb-[1vh] flex items-center text-xs">
              #{item?.order_no}
            </div>
            <div className="text-sm font-semibold">
              Rs. {item?.total_due ? item.total_due : item.grand_total}
            </div>
          </div>
        </div>

        <div className="flex justify-around mt-[2vh]">
          <div
            onClick={(e) => handlePay(e, item)}
            className={`w-[6vw] cursor-pointer transition ease-in-out delay-50 rounded-md flex justify-center items-center font-semibold ${
              selected === item.id
                ? "bg-blue text-white"
                : "hover:bg-blue hover:text-white bg-blue-grey text-grey"
            }`}
          >
            Pay
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setOpenPayLaterModal(true);
            }}
            className="w-[6vw] font-semibold text-grey bg-blue-grey rounded-md hover:bg-blue hover:text-white"
          >
            Pay Later
          </button>
          <div
            onClick={(e) => handleCancel(e, item)}
            className={`p-[1vh] cursor-pointer transition ease-in-out delay-50 ${
              item.payment_status === "UNPAID"
                ? "hover:bg-red hover:stroke-white  bg-blue-grey stroke-grey"
                : "bg-white/40 stroke-grey/30 cursor-default"
            } rounded-md flex items-center`}
          >
            <Close />
          </div>
          <div
            onClick={(e) => handlePrint(e, item.id)}
            className="p-[1vh] cursor-pointer transition ease-in-out delay-50 hover:bg-yellow hover:stroke-white  bg-blue-grey stroke-grey rounded-md flex items-center"
          >
            <Printer />
          </div>
          <div
            onClick={(e) => handleEdit(e, item)}
            className={`p-[1vh] transition ease-in-out delay-50 ${
              item.total_due < item.grand_total
                ? "bg-white/40 stroke-grey/40 cursor-default"
                : "hover:bg-normal-blue hover:stroke-white  bg-blue-grey stroke-grey cursor-pointer"
            } rounded-md flex items-center`}
          >
            <Pen />
          </div>
        </div>
        <AnimatePresence>
          {openPayLaterModal && (
            <Modal
              handleCancel={(e) => {
                e.stopPropagation();
                setOpenPayLaterModal(false);
              }}
              heading="Pay Later"
            >
              <section className="p-5 flex flex-col justify-center items-center">
                <p className="text-medium text-center">
                  Are you sure to add the order of table-{item.table_name} (
                  {item?.area_name}) to PayLater section ?
                </p>
                <div className="w-[40%] mt-5 flex justify-between">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenPayLaterModal(false);
                    }}
                    className="bg-blue-grey text-grey text-medium py-1 px-2 rounded-md"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePayLater(item);
                      setOpenPayLaterModal(false);
                    }}
                    className="bg-blue text-white text-medium py-1 px-2 rounded-md"
                  >
                    Pay Later
                  </button>
                </div>
              </section>
            </Modal>
          )}
        </AnimatePresence>
      </div>
      <div
        className={`${orderTypeStyle} w-[24vw] flex justify-between p-2 rounded-b-xl text-xs`}
      >
        <p className={`${orderTypeTextStyle}`}>
          {formatToNormal(item.order_type)}
        </p>
        <div className="flex items-center stroke-blue text-blue">
          <Clock />
          <div className="text-xs ml-2">
            
            {parseInt(hour) ? 
               (parseInt(hour) > 100 ? `${day}D ${diff_hour}h` : `${hour}h ${min}min`) : `${min}min`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableCard;

{
  /*  */
}
