import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { ProductsApi } from "../apis/Products";
import NoView from "../assets/NoView";
import Pen from "../assets/Pen";
import Trash from "../assets/Trash";
import UpwardArrow from "../assets/UpwardArrow";
import View from "../assets/View";
import { productAtom } from "../common/Atoms";
import ManagementTable from "../helperComponents/ManagementTable";
import Modal from "../helperComponents/Modal";
import DropdownField from "../utils/form/DropdownField";
import SelectInfiniteField from "../utils/form/SelectInfiniteField";
import { motion } from "framer-motion";

const ProductManagementTable = ({
  totalCount,
  setTotalCount,
  pageLength,
  searchString,
  setEditableProduct,
  kitchens,
  setShowModal,
  showModal,
}) => {
  const [page, setPage] = useState(1);
  const [view, setView] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleteableId, setDeleteableId] = useState(null);
  const [initial, setInitial] = useState(true);
  const [sortValue, setSortValue] = useState("");
  const [products, setProducts] = useRecoilState(productAtom);
  const [openDropDown, setOpenDropDown] = useState("");

  const fetchProducts = useCallback(async () => {
    let params = { length: pageLength, page, search: searchString };
    if (sortValue) params.sort_by = sortValue;
    const { data: response } = await ProductsApi.list({
      params,
    });

    setProducts(response.data.results);
    setTotalCount(response.data.count);
    setLoading(false);
  }, [page, pageLength, searchString, showModal, sortValue]);

  const handleDelete = async (id) => {
    try {
      await ProductsApi.destroy(id);
      fetchProducts();
      toast.success("Product Deleted Successfull");
    } catch (e) {
      toast.error(`Some Issue ${e}`);
    }
    setDeleteableId(null);
  };
  const handleViewChange = async (id) => {
    const change = products[id].status === "ACTIVE" ? "ARCHIVED" : "ACTIVE";

    if (change === "ACTIVE") {
      toast.error("Deactived Successfully");
    } else {
      toast.success("Actived Successfully");
    }
    const { data: response } = await ProductsApi.statusUpdate(products[id].id, {
      status: change,
    });

    if (response.message) {
      toast.error(Object.values(response.message).join(", "));
      return;
    }
    const changableProducts = products.map((product, idx) =>
      idx === id ? { ...product, status: change } : product
    );
    setProducts(changableProducts);
  };

  const COLUMN = [
    {
      id: "name",
      Header: ({ column }) => (
        <div className="flex justify-start items-center ">
          Name
          <button
            className={`stroke-white ml-2 ${
              sortValue === "-title" ? "" : "rotate-180"
            }`}
            onClick={() =>
              setSortValue(sortValue === "title" ? "-title" : "title")
            }
          >
            <UpwardArrow />
          </button>
        </div>
      ),
      accessor: "title",
      Cell: ({ value }) => <div className="font-semibold">{value}</div>,
    },
    {
      id: "price",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          Price
          <button
            className={`stroke-white ml-2 ${
              sortValue === "-product_variants__price" ? "" : "rotate-180"
            }`}
            onClick={() =>
              setSortValue(
                sortValue === "product_variants__price"
                  ? "-product_variants__price"
                  : "product_variants__price"
              )
            }
          >
            <UpwardArrow />
          </button>
        </div>
      ),
      accessor: "price",
    },
    {
      id: "category",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          {/* <button
            className={`stroke-white mr-2 ${
              column.isSortedDesc ? "" : "rotate-180"
            }`}
            onClick={() => column.toggleSortBy()}
          >
            <UpwardArrow />
          </button> */}
          Category
        </div>
      ),
      accessor: "categories",
      Cell: ({ value }) => (
        <p>
          {value?.[0]} {value.length - 1 > 0 && `+ ${value.length - 1} more`}
        </p>
      ),
    },
    {
      id: "kitchen",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          {/* <button
            className={`stroke-white mr-2 ${
              column.isSortedDesc ? "" : "rotate-180"
            }`}
            onClick={() => column.toggleSortBy()}
          >
            <UpwardArrow />
          </button> */}
          Kitchen
        </div>
      ),
      accessor: "kitchen",
      Cell: ({ value, row }) => {
        return (
          <div className="w-full h-8 text-xs">
            <div
              onClick={() =>
                setOpenDropDown((prev) => (prev === row.id ? "" : row.id))
              }
              className={`w-full flex ${
                openDropDown === row.id ? "rounded-t-lg border-b" : "rounded-lg"
              } bg-white justify-between items-center py-2 px-3 stroke-black`}
            >
               {value == null ? "---" : value}
              <motion.div
                initial={{ rotate: 180 }}
                animate={
                  openDropDown === row.id ? { rotate: 0 } : { rotate: 180 }
                }
              >
                <UpwardArrow />
              </motion.div>
            </div>
            {openDropDown === row.id && (
              <div className="relative bg-white w-full z-10 shadow-lg rounded-md top-0 left-0">
                <div
                    onClick={async () => {
                      setOpenDropDown("");
                      const { data: response } =
                        await ProductsApi.addKitchen(row.original.id, {
                          kitchen: null,
                          name: row.original.name,
                        });
                      if (response.success) {
                        toast.success("Kitchen Added Successfully");
                        fetchProducts();
                      }
                    }}
                    className=" px-2 py-2 hover:bg-blue/10 cursor-pointer"
                  >
                    ---
                  </div>
                {kitchens?.map((each) => (
                  <div
                    onClick={async () => {
                      setOpenDropDown("");
                      await ProductsApi.addKitchen(row.original.id, {
                        kitchen: each.id,
                      });
                      fetchProducts();
                      toast.success("Kitchen Added Successfully");
                    }}
                    className=" px-2 py-2 hover:bg-blue/10 cursor-pointer"
                  >
                    {each.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      },
    },
    {
      id: "buttons",
      Header: () => <div className="flex w-full justify-end pr-8">Action</div>,
      Cell: ({ row }) => (
        <div className="flex justify-end">
          {row.original.status === "ACTIVE" ? (
            <div
              onClick={() => {
                handleViewChange(row.index);
              }}
              className="stroke-grey cursor-pointer bg-white px-1 py-1.5 mr-4 rounded-md hover:bg-grey hover:stroke-white"
            >
              <View />
            </div>
          ) : (
            <div
              onClick={() => handleViewChange(row.index)}
              className="cursor-pointer rounded-md p-1 mr-4 bg-grey stroke-white"
            >
              <NoView />
            </div>
          )}
          <button
            onClick={() => setEditableProduct(row.original.id)}
            className="stroke-grey bg-white p-1 mr-4 rounded-md hover:bg-red hover:stroke-white"
          >
            <Pen />
          </button>
          <button
            onClick={() => setDeleteableId(row.original.id)}
            className="stroke-grey bg-white p-1 rounded-md hover:bg-red hover:stroke-white"
          >
            <Trash />
          </button>
        </div>
      ),
    },
  ];

  const columns = useMemo(
    () => COLUMN,
    [view, products, openDropDown, kitchens]
  );
  const data = useMemo(() => products, [products]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page - 1, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
    },
    useSortBy,
    usePagination
  );

  const { gotoPage, setPageSize } = tableInstance;

  useEffect(() => {
    if (!initial) {
      fetchProducts();
      gotoPage(page - 1);
      return () => {};
    }
  }, [page]);

  useEffect(() => {
    if (!showModal && !initial) {
      fetchProducts();
    }
  }, [showModal]);

  useEffect(() => {
    if (sortValue && !initial) {
      fetchProducts();
    }
  }, [sortValue]);

  useEffect(() => {
    setPageSize(pageLength);
    if (page === 1) {
      fetchProducts();
    } else {
      setPage(1);
    }
    setInitial(false);
    return () => {};
  }, [pageLength, searchString]);

  return (
    <>
      <ManagementTable
        tableInstance={tableInstance}
        setPage={setPage}
        page={page}
        loading={loading}
        setLoading={setLoading}
        totalCount={totalCount}
      />
      {deleteableId !== null && (
        <Modal
          handleCancel={() => setDeleteableId(null)}
          heading="Confirm Delete"
        >
          <section className="pt-10 px-5">
            <p className="font-semibold">
              Are you sure you want to delete the product ?
            </p>
            <div className="flex justify-center mb-10 mt-5">
              <button
                onClick={() => setDeleteableId(null)}
                className="px-3 py-2 mr-3 font-semibold rounded-md bg-blue-grey text-grey"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDelete(deleteableId)}
                className="bg-red font-semibold text-white rounded-md px-3 py-2"
              >
                Delete
              </button>
            </div>
          </section>
        </Modal>
      )}
    </>
  );
};

export default ProductManagementTable;
