import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import ManagementModal from "../../../helperComponents/ManagementModal";
import VendorManagementTable from "./VendorManagement/ManagementTable";
import Form from "./VendorManagement/AddVendorModal/Form";
import ManagementControl from "../../../helperComponents/ManagementControl";

const PurchaseOrder = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="relative z-10 h-[80%] bg-blue-grey w-full">
      <ManagementControl
        setShowModal={setShowModal}
        heading="Purchase Order"
        buttonText="vendor"
      />
      <VendorManagementTable />
      <AnimatePresence>
        {showModal && (
          <ManagementModal form>
            <Form setShowModal={setShowModal} />
          </ManagementModal>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PurchaseOrder;
