import React, { useContext, useEffect, useState } from "react";
import Searchbar from "../utils/Searchbar";
import TableCard from "../utils/TableCard";
import SidePane from "../SidePane/OngoingOrders";
import { OrdersApi } from "../apis/Orders.js";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  BillPrintableValueAtom,
  CartHistoryAtom,
  CurrentlyBillingIdAtom,
  InnerHtml,
  OrderDetailsAtom,
  PayableAmountAtom,
  PaymentOrderIdAtom,
  ShowCartHistoryAtom,
  TableNameSelectToOngoingOrdersAtom,
} from "../common/Atoms";
import { useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Modal from "../helperComponents/Modal";
import { Payment } from "../screens/Tables";
import { toast } from "react-toastify";
import OrdersCardLoader from "../Loaders/OrdersCard";
import AreaTile from "../utils/AreaTile";

const OngoingOrders = () => {
  const [initial, setInitial] = useState(true)
  const [ongoingOrders, setOngoingOrders] = useState();
  const [editableOrders, setEditableOrders] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [cancelationNote, setCancelationNote] = useState("");
  const [cancelationError, setCancelationError] = useState("");
  const [clickId, setClickId] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedMethod, setSelectedMethod] = useState("");
  const { showPaymentPanel, setShowPaymentPanel } = useContext(Payment);
  const setPrintableValues = useSetRecoilState(InnerHtml);
  const [selectedOngoingOrder, setSelectedOngoingOrder] =
    useRecoilState(CartHistoryAtom);
  const setOrderDetails = useSetRecoilState(OrderDetailsAtom);
  const [editInOrders, setEditInOrders] = useRecoilState(ShowCartHistoryAtom);
  const [payableAmount, setPayableAmount] = useRecoilState(PayableAmountAtom);
  const [payableOrderId, setPayableOrderId] =
    useRecoilState(PaymentOrderIdAtom);
  const setBillableId = useSetRecoilState(CurrentlyBillingIdAtom)
  const setBillPrintable = useSetRecoilState(BillPrintableValueAtom);
  const [selectedTableName, setSelectedTableName] = useRecoilState(
    TableNameSelectToOngoingOrdersAtom
  );
  const dineMethods = [
    { label: "All", value: "" },
    { label: "Dine In", value: "DINE_IN" },
    { label: "To Go", value: "TO_GO" },
    { label: "Delivery", value: "DELIVERY" },
  ];

  const navigate = useNavigate();

  const handleShowInCart = async (id) => {
    if (id === selectedOngoingOrder?.id) return;
    const { data: response } = await OrdersApi.show(id);
    setSelectedOngoingOrder(response.data);
  };

  const fetchOngoingOrders = async () => {
    const params = { kind: "ONGOING" };
    if (searchValue) params.search = searchValue;
    const { data: response } = await OrdersApi.list({
      params,
    });
    // console.log(response.data)
    setOngoingOrders(response.data);
    setLoading(false);
    if (selectedTableName) {
      const selectedTable = response.data.results.filter(
        (data) =>
          data.table_name === selectedTableName.table_name &&
          data.area_name === selectedTableName.area_name
      );
      handleShowInCart(selectedTable[0].id);
      setSelectedTableName({});
    }
  };

  const handleCancel = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const handleAddItem = () => {
    handleShowInCart(editableOrders);
    setEditInOrders(true);
  };

  const handleCancelWithCall = (e, item) => {
    e.stopPropagation();
    if (item.payment_status === "UNPAID") {
      setClickId(item.id);
      handleCancel();
    }
  };

  const handleEdit = async (e, item) => {
    e.stopPropagation();
    if (item.total_due === item.grand_total) {
      await handleShowInCart(item.id);
      setEditInOrders(true);
    }
  };

  const handlePrint = async (e, id) => {
    e.stopPropagation();
    const { data: response } = await OrdersApi.orderPrint(id);
    setPrintableValues(response);
  };

  const handlePay = async (e, item) => {
    e.stopPropagation();
    // const { data: response } = await OrdersApi.orderPrint(item.id);
    // setBillPrintable(response);
    setBillableId(item.id)
    await handleShowInCart(item.id);
    setPayableAmount(item.total_due ? item.total_due : item.grand_total);
    setPayableOrderId(item.id);
    setShowPaymentPanel(true);
  };

  const handleDelete = async () => {
    const id = clickId ? clickId : selectedOngoingOrder.id;
    const { data: response } = await OrdersApi.cancel(id, {
      cancel_reason: cancelationNote,
    });
    if (!response.success) {
      setCancelationError(response.data.message.cancel_reason);
      return;
    }
    setModalIsOpen(false);
    setSelectedOngoingOrder({});
    setCancelationNote("");
    fetchOngoingOrders();
  };

  const handlePayLater = async (item) => {
    const { data: response } = await OrdersApi.update(item.id, {
      is_pay_later: true,
    });
    if (response.data.message) {
      toast.error(JSON.stringify(response.data.message));
      return;
    }
    setSelectedOngoingOrder({});
    fetchOngoingOrders();
  };

  useEffect(() => {

    if(initial) {   
      setInitial(false)
    } else {
      fetchOngoingOrders();
    }
  }, [searchValue]);

  useEffect(() => {
    if(!initial) {
      fetchOngoingOrders();
    }
    setEditInOrders(false);
  }, []);

  useEffect(() => {
    setEditInOrders(false);
    setSelectedOngoingOrder({});
  }, []);

  useEffect(() => {
    if (editInOrders) {
      navigate("/tables/order");
    }
  }, [editInOrders]);

  useEffect(() => {
    if (ongoingOrders?.count === 1) {
      handleShowInCart(ongoingOrders.results[0].id);
    }
  }, [ongoingOrders]);

  useEffect(() => {
    if (!showPaymentPanel && !payableAmount && selectedOngoingOrder) {
      fetchOngoingOrders();
      setSelectedOngoingOrder({});
      setOrderDetails({});
      setEditInOrders(false);
      setPayableOrderId(null);
    }
  }, [showPaymentPanel, payableAmount]);

  useEffect(() => {
    if (editableOrders) {
      handleAddItem();
    }
  }, [editableOrders]);

  return (
    <>
      <div className="w-full h-[85%] 2xl:h-[90%] relative">
        <div className="w-2/3 lg:w-[66%] h-1/2 md:h-4/5">
          <div className="text-3xl font-bold text-black">Ongoing Orders</div>

          <Searchbar setter={setSearchValue} />

          <div className="w-full flex  mt-[2vh]">
            {dineMethods
              .map((obj) => obj.label)
              .map((method, id) => (
                <AreaTile
                  key={method}
                  area={method}
                  onClick={() =>
                    selectedMethod !== dineMethods[id].value &&
                    setSelectedMethod(dineMethods[id].value)
                  }
                  selected={dineMethods[id].value === selectedMethod}
                />
              ))}
          </div>

          <div className="w-full h-full mt-[4vh] overflow-y-scroll scrollbar-hide">
            <div className="w-full grid grid-cols-2 pr-6 gap-3">
              {loading ? (
                <OrdersCardLoader />
              ) : (
                ongoingOrders?.results
                  .filter(({ order_type }) =>
                    order_type.includes(selectedMethod)
                  )
                  .map((item, idx) => (
                    <TableCard
                      key={idx}
                      item={item}
                      selected={selectedOngoingOrder.id}
                      handleShowInCart={handleShowInCart}
                      handleCancel={handleCancelWithCall}
                      handleEdit={handleEdit}
                      handlePrint={handlePrint}
                      handlePay={handlePay}
                      handlePayLater={handlePayLater}
                    />
                  ))
              )}
            </div>
          </div>
        </div>
        <SidePane
          orders={selectedOngoingOrder}
          setEditableOrders={setEditableOrders}
          handleCancel={handleCancel}
        />
      </div>
      <AnimatePresence>
        {modalIsOpen && (
          <Modal handleCancel={handleCancel} heading="Cancel Order #1234">
            <div className="w-full px-8">
              <div
                className={`mt-5 mb-2 text-sm font-semibold ${
                  cancelationError && "text-red"
                }`}
              >
                Cancel Reason
              </div>
              <textarea
                onChange={(e) => {
                  setCancelationError(null);
                  setCancelationNote(e.target.value);
                }}
                rows={6}
                className={`w-full text-sm ${
                  cancelationError ? "bg-red/5" : "bg-blue-grey"
                } p-3 rounded-lg`}
              ></textarea>
              {cancelationError && (
                <p className="text-xs text-red mb-2">{cancelationError}</p>
              )}
              <p className="text-xs text-grey">
                This order will be completely cancelled
              </p>
              <div className="mt-3 mb-5 flex justify-center">
                <div
                  onClick={handleCancel}
                  className="px-3 py-2 m-3 bg-blue-grey text-grey text-sm font-semibold rounded-lg cursor-pointer"
                >
                  Cancel
                </div>
                <div
                  onClick={handleDelete}
                  className="px-5 py-2 m-3 bg-blue text-white text-sm font-semibold rounded-lg cursor-pointer"
                >
                  Save
                </div>
              </div>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default OngoingOrders;
