import React, { useEffect, useState } from "react";
import { OrdersApi } from "../apis/Orders.js";
import useArrayOfObjs from "../hooks/useArrayOfObjs.js";
import ProductsCard from "./ProductsCard.js";
import { AnimatePresence, motion } from "framer-motion";

const ProductsRoll = ({ searchValue, category }) => {
  const [products, setProducts] = useArrayOfObjs(null);

  const fetchProducts = async () => {
    const params = {};
    if (searchValue) {
      params.search = searchValue;
    }
    if (category) {
      params.category = category.id;
    }
    const { data: response } = await OrdersApi.productsList({ params });
    console.log(response.data);
    setProducts(response.data);
  };

  useEffect(() => {
    fetchProducts();
  }, [category, searchValue]);

  return (
    <AnimatePresence>
      <motion.div
        key={products}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="text-sm bg-slate-100 flex flex-col gap-y-3"
      >
        {products?.map((product) => (
          <ProductsCard product={product} />
        ))}
      </motion.div>
    </AnimatePresence>
  );
};

export default ProductsRoll;
