import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { useRecoilState } from "recoil";
import { AddonContext } from "../sections/ManageAddon";
import { ProductAddonsApi } from "../apis/Products/Addon";
import NoView from "../assets/NoView";
import Pen from "../assets/Pen";
import Trash from "../assets/Trash";
import View from "../assets/View";
import { addonAtom } from "../common/Atoms";
import ManagementTable from "../helperComponents/ManagementTable";
import Modal from "../helperComponents/Modal";
import UpwardArrow from "../assets/UpwardArrow";
import { toast } from "react-toastify";

const AddonManagementTable = ({
  setEditableAddonId,
  setShowModal,
  showModal,
}) => {
  const [page, setPage] = useState(1);
  const [view, setView] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initial, setInitial] = useState(true);
  const [sortValue, setSortValue] = useState("");
  const [addons, setAddons] = useRecoilState(addonAtom);
  const [deleteableId, setDeleteableId] = useState(null);
  const { totalCount, setTotalCount, pageLength, searchString } =
    useContext(AddonContext);

  const handleDelete = async (id) => {
    try {
      await ProductAddonsApi.destroy(id);
      fetchAddons();
      toast.success("Product Deleted Successfull");
    } catch (e) {
      toast.error(`Some issue ${e}`);
    }
    setDeleteableId(null);
  };

  const handleViewClick = async (id) => {
    const change = addons[id].status === "ACTIVE" ? "ARCHIVED" : "ACTIVE";
    const { data: response } = await ProductAddonsApi.update(addons[id].id, {
      status: change,
    });
    if (change === "ACTIVE") {
      toast.error("Deactived Successfully");
    } else {
      toast.success("Actived Successfully");
    }
    if (response.message) {
      toast.error(Object.values(response.message).join(", "));
      return;
    }
    const changableAddons = addons.map((addon, idx) =>
      idx === id ? { ...addon, status: change } : addon
    );
    setAddons(changableAddons);
  };

  const fetchAddons = useCallback(async () => {
    let params = { length: pageLength, page, search: searchString };
    if (sortValue) params.sort_by = sortValue;
    const { data: response } = await ProductAddonsApi.list({
      params,
    });
    setAddons(response.data.results);
    setTotalCount(response.data.count);
    setLoading(false);
  }, [page, pageLength, searchString, deleteableId, sortValue]);

  const ADDONS = addons.map((addon) => ({
    id: addon.id,
    status: addon.status,
    products: addon.products,
    name: addon.name,
    price: addon.price.toFixed(2),
  }));

  const COLUMN = [
    {
      id: "name",
      Header: ({ column }) => (
        <div className="flex justify-start items-center ">
          Name
          <button
            className={`stroke-white ml-2 ${
              sortValue === "-name" ? "" : "rotate-180"
            }`}
            onClick={() =>
              setSortValue(sortValue === "name" ? "-name" : "name")
            }
          >
            <UpwardArrow />
          </button>
        </div>
      ),
      accessor: "name",
      width: 30,
      Cell: ({ value }) => (
        <div
          className="font-semibold
      "
        >
          {value}
        </div>
      ),
    },
    {
      id: "price",
      Header: ({ column }) => (
        <div className="flex justify-start items-center">
          Price
          <button
            className={`stroke-white ml-2 ${
              sortValue === "price" ? "" : "rotate-180"
            }`}
            onClick={() =>
              setSortValue(sortValue === "price" ? "-price" : "price")
            }
          >
            <UpwardArrow />
          </button>
        </div>
      ),
      accessor: "price",
      width: 30,
      Cell: ({ value }) => <div className="w-[90%]">{value}</div>,
    },
    {
      id: "buttons",
      Header: () => <div className="flex w-full justify-end pr-8">Action</div>,
      Cell: ({ row }) => (
        <div className="flex justify-end">
          {row.original.status === "ACTIVE" ? (
            <div
              onClick={async () => await handleViewClick(row.index)}
              className="stroke-grey cursor-pointer bg-white px-1 py-1.5 mr-4 rounded-md hover:bg-grey hover:stroke-white"
            >
              <View />
            </div>
          ) : (
            <div
              onClick={() => handleViewClick(row.index)}
              className="cursor-pointer rounded-md p-1 mr-4 bg-grey stroke-white"
            >
              <NoView />
            </div>
          )}
          <div
            onClick={() => {
              setEditableAddonId(row.original);
            }}
            className="stroke-grey cursor-pointer bg-white p-1 mr-4 rounded-md hover:bg-red hover:stroke-white"
          >
            <Pen />
          </div>
          <div
            onClick={() => setDeleteableId(row.original.id)}
            className="stroke-grey cursor-pointer bg-white p-1 rounded-md hover:bg-red hover:stroke-white"
          >
            <Trash />
          </div>
        </div>
      ),
    },
  ];

  const columns = useMemo(() => COLUMN, [view, addons]);
  const data = useMemo(() => ADDONS, [addons]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: page - 1, pageSize: pageLength },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageLength),
    },
    useSortBy,
    usePagination
  );

  const { gotoPage, setPageSize } = tableInstance;

  useEffect(() => {
    if (!initial) {
      fetchAddons();
      gotoPage(page - 1);
      return () => {};
    }
  }, [page ]);

  useEffect(() => {
    if (!showModal && !initial) {
      fetchAddons();
      setEditableAddonId(null);
    }
  }, [showModal]);

  useEffect(() => {
    if (sortValue && !initial) {
      fetchAddons();
    }
  }, [sortValue]);

  useEffect(() => {
    setPageSize(pageLength);
    if (page === 1) {
      fetchAddons();
    } else {
      setPage(1);
    }
    setInitial(false);
    return () => {};
  }, [pageLength, searchString]);

  return (
    <>
      <ManagementTable
        tableInstance={tableInstance}
        setPage={setPage}
        page={page}
        loading={loading}
        setLoading={setLoading}
        totalCount={totalCount}
      />
      {deleteableId !== null && (
        <Modal
          handleCancel={() => setDeleteableId(null)}
          heading="Confirm Delete"
        >
          <section className="pt-10 px-5">
            <p className="font-semibold">
              Are you sure you want to delete the addon ?
            </p>
            <div className="flex justify-center mb-10 mt-5">
              <button
                onClick={() => setDeleteableId(null)}
                className="px-3 py-2 mr-3 font-semibold rounded-md bg-blue-grey text-grey"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDelete(deleteableId)}
                className="bg-red font-semibold text-white rounded-md px-3 py-2"
              >
                Delete
              </button>
            </div>
          </section>
        </Modal>
      )}
    </>
  );
};

export default AddonManagementTable;
