import React from "react";

const Triangle = () => {
  return (
    <svg width="11" height="14" viewBox="0 0 11 14" fill="none">
      <path
        d="M1.7 8.55884C0.642946 7.94856 0.11442 7.64341 0.021852 7.20791C-0.00728451 7.07084 -0.00728452 6.92916 0.021852 6.79209C0.11442 6.35659 0.642947 6.05144 1.7 5.44115L8.3 1.63064C9.35705 1.02035 9.88558 0.715207 10.309 0.85279C10.4423 0.896096 10.565 0.966931 10.6691 1.0607C11 1.35862 11 1.96891 11 3.18949L11 10.8105C11 12.0311 11 12.6414 10.6691 12.9393C10.565 13.0331 10.4423 13.1039 10.309 13.1472C9.88558 13.2848 9.35705 12.9796 8.3 12.3694L1.7 8.55884Z"
        fill="#604BE8"
      />
    </svg>
  );
};

export default Triangle;
