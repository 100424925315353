import React, { useContext, useState, useEffect } from "react";
import { addonAtom } from "../common/Atoms";
import Checkbox from "../utils/form/Checkbox";
import Header from "../utils/form/Header";
import InputField from "../utils/form/InputField";
import SelectField from "../utils/form/SelectField";
import TagField from "../utils/form/TagField";
// import { useRecoilState } from "recoil";
import { ProductAddonsApi } from "../apis/Products/Addon";
import { AddonContext } from "../sections/ManageAddon";
import { ProductsApi } from "../apis/Products";
import { toast } from "react-toastify";

const Form = ({ setShowModal, addon, setAddon }) => {
  const [name, setName] = useState(addon?.name || "");
  const [price, setPrice] = useState(addon?.price || 0);
  const [available, setAvailable] = useState(addon?.status === "ACTIVE" ? true : false);
  const [flowInput, setFlowInput] = useState("");
  const [errorObject, setErrorObject] = useState({})
  // const [flowRes, setFlowRes] = useState({})
  const [flowOptions, setFlowOptions] = useState([]);
  const settableProducts = addon?.products ? addon.products.map((product) => ({id: product.product_id, title: product.product_name})) : []
  const [selectedFlow, setSelectedFlow] = useState(settableProducts);
  const [isNotDiscountable, setIsNotDiscountable] = useState(
    addon?.is_not_discountable || false
  );
  // const [addons, setAddons] = useRecoilState(addonAtom);
  const { setTotalCount } = useContext(AddonContext);
console.log(addon,"addon");
  const fetchCategoryWiseProducts = async (categoryId) => {
    const { data: response } = await ProductsApi.categorySearch(categoryId);
    const temp = response.data.filter(
      (each) => !selectedFlow.some((flow) => flow.id === each.product_id)
    );
    const selectableProducts = temp.map((each) => ({
      id: each.product_id,
      title: each.product_name,
    }));
    setSelectedFlow([...selectedFlow, ...selectableProducts]);
  };

  const fetchFlow = async () => {
    const { data: response } = await ProductsApi.search({
      params: { kind: "product,category", q: flowInput },
    });
    if (!response?.data?.message) {
      const categoriesList = response.data.categories.map((category) => {
        category.type = "category";
        return category;
      });
      const productsList = response.data.products.map((product) => {
        product.type = "product";
        return product;
      });

      let all = [];
      all = all.concat(...categoriesList);
      all = all.concat(...productsList);
      setFlowOptions(all);
    }
  };

  const handleNameErrorObject = () => {
    if (errorObject?.name) {
      delete errorObject.name;
      delete errorObject.non_field_errors;
      setErrorObject({...errorObject})
    }
  }

  const handlePriceError = () => {
    if (errorObject?.price) {
      delete errorObject.price;
      delete errorObject.non_field_errors;
      setErrorObject({...errorObject})
    }
  }

  const handleProductRemove = (idx) => {
    selectedFlow.splice(idx, 1);
    setSelectedFlow([...selectedFlow]);
  };

  const handleSelected = async (flow) => {
    if (flow.type === "category") {
      await fetchCategoryWiseProducts(flow.id);
      return;
    }
    const test = !selectedFlow.some((flowCheck) => flow.id === flowCheck.id);
    if (test) setSelectedFlow((prev) => [...prev, flow]);
  };

  const handleSubmit = async () => {
    const payload = {
      name,
      price,
      status: available ? "ACTIVE" : "ARCHIVED",
      is_not_discountable: isNotDiscountable,
      products: selectedFlow.map(product => product.id)
    };
    if (addon) {
      const {data: response} = await ProductAddonsApi.update(
        addon.id,
        payload
      );
      if(response.data.message){
        setErrorObject(response.data.message)
        return;
      }
      toast.success("Addon Updated Successfully")
      setAddon(null);
    } else {
      const {data: response} = await ProductAddonsApi.create(payload);
      if(response.data.message){
        setErrorObject(response.data.message)
        return;
      }
      toast.success("Addon Created Successfully")
      setTotalCount((prev) => prev + 1);
    }
    setShowModal(false);
  };

  useEffect(() => {
    if(flowInput !== "") fetchFlow();
    else setFlowOptions([])
  }, [flowInput]);


  return (
    <div className=" w-full h-full flex flex-col justify-between">
      <Header
        heading={`Edit : ${name}`}
        setModalOpen={() => {
          if (addon) {
            setAddon(null);
            
          }
          setShowModal(false);
        }}
      />
      <div className="max-h-[80%] mb-7 overflow-y-scroll scrollbar-hide text-sm">
        <InputField 
          errorValue={errorObject.name}
          errorObjectSetter={handleNameErrorObject}
          heading="Name" 
          input={name} 
          setInput={setName} 
        />
        <div className="w-full mt-4">
          <div className="w-full flex items-center">
            <Checkbox
              checked={available}
              onClick={() => setAvailable((prev) => !prev)}
            />
            <div className="text-xs ml-2">Available</div>
          </div>
          <div className="w-full flex items-center mt-2">
            <Checkbox
              checked={isNotDiscountable}
              onClick={() => setIsNotDiscountable((prev) => !prev)}
            />
            <div className="text-xs ml-2">Not Discountable</div>
          </div>
        </div>
        <InputField 
          heading="Price" 
          input={price} 
          setInput={setPrice} 
          errorValue={errorObject.price}
          errorObjectSetter={handlePriceError}
        />
        <SelectField
          heading="Flow"
          options={flowOptions}
          inputSetter={setFlowInput}
          setSelected={handleSelected}
          colored
        />
        <div className="flex flex-wrap">
          {selectedFlow.map((flow, idx) => (
            <TagField
              key={flow.id}
              handleClose={() => handleProductRemove(idx)}
              // big={flow.type === "category"}
              name={flow.title}
            />
          ))}
        </div>
      </div>
      <div
        onClick={handleSubmit}
        className="bg-blue cursor-pointer w-full text-lg font-semibold text-white flex justify-center rounded-lg py-2"
      >
        Save
      </div>
    </div>
  );
};

export default Form;
