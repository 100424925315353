import React, { useEffect, useState } from "react";
import { TableAreasApi } from "../../apis/TableArea";
import CategoryLoader from "../../Loaders/Category";
import TableAreaLoader from "../../Loaders/TableArea";
import TableAreaList from "./TableAreaList";
import TablesList from "./TablesList";

function Table() {
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedArea, setSelectedArea] = useState("All");
  const [openSeatNowModal, setOpenSeatNowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  // const [openReserveModal, setOpenReserveModal] = useState(false);
  const [tableArea, setTableArea] = useState([]);
  const [loading, setLoading] = useState(true)

  const items = [];

  for (var i = 0; i < 40; i++) {
    items.push("00A");
  }

  const fetchTableArea = async () => {
    const { data: response } = await TableAreasApi.list();
    const sortedArea = response.data.sort((a, b) => a.name.localeCompare(b.name))
    const sortedResponse = sortedArea.map(each => {
      const tables = each.tables?.sort((a, b) => a.name.localeCompare(b.name))
      return({ ...each, tables })
    })
    console.log(sortedResponse)
    setTableArea([{ name: "All" }].concat(sortedResponse));
    setLoading(false)
  };

  useEffect(() => {
    fetchTableArea();
  }, []);

  return (
    <div className="h-1/2 md:h-3/5 lg:h-2/3">
      <div className="text-3xl font-bold text-black">Table</div>

      {loading ? (
        <TableAreaLoader />
      ) : <TableAreaList
        tableArea={tableArea}
        editMode={editMode}
        selectedArea={selectedArea}
        setSelectedArea={setSelectedArea}
        setEditMode={setEditMode}
        setTableArea={setTableArea}
      />}
      <TablesList
        tableArea={tableArea}
        setSelectedTable={setSelectedTable}
        selectedTable={selectedTable}
        selectedArea={selectedArea}
        openSeatNowModal={openSeatNowModal}
        setOpenSeatNowModal={setOpenSeatNowModal}
        editMode={editMode}
        setTableArea={setTableArea}
        loading={loading}
      />
    </div>
  );
}

export default Table;
