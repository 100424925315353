import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import FilledClose from "../assets/FilledClose";
import Modal from "../helperComponents/Modal";

const AreaTile = ({
  area,
  selected,
  onClick,
  secondary = false,
  small = false,
  editMode,
  id,
  handleDeleteArea,
  long = false, 
}) => {
  const [deletableId, setDeletableId] = useState(null);
  return (
    <motion.div
      exit={{ scale: 0, transition: { duration: 0.6 } }}
      className="h-8 w-fit relative"
    >
      <motion.div
        whileHover={!selected ? { scale: 1.05 } : { scale: 1 }}
        onClick={onClick}
        className={`mr-4 h-8 place-content-center cursor-pointer transition ease-in-out duration-75 ${
          selected
            ? secondary ? "bg-grey text-white z-10" : "bg-blue text-white z-10"
            : secondary
            ? "bg-blue-grey text-grey z-0"
            : "bg-white text-grey"
        } ${small ? "px-2 py-1" : "px-6 py-1 font-bold"} rounded-md`}
      >
        {area}
      </motion.div>
      {editMode && area !== "All" && (
        <button
          onClick={() => setDeletableId({id, area})}
          className="absolute top-[-12px] right-0 w-fit"
        >
          <FilledClose />
        </button>
      )}
      <AnimatePresence>
        {deletableId && (
          <Modal
            heading="Confirm Delete"
            handleCancel={() => setDeletableId(null)}
          >
            <section className="p-5">
              <p className="text-sm text-center">
                Are you sure you want to delete the area: {deletableId.area}
              </p>
              <div className="flex justify-center mt-3">
                <button
                  onClick={() => setDeletableId(null)}
                  className="py-1 px-4 mr-3 bg-blue-grey text-grey font-medium rounded-md"
                >
                  cancel
                </button>
                <button
                  onClick={() => {
                    handleDeleteArea(deletableId.id)
                    setDeletableId(null)
                  }}
                  className="ml-3 py-1 px-4 bg-blue rounded-md text-white font-medium"
                >
                  Delete
                </button>
              </div>
            </section>
          </Modal>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default AreaTile;
