import React from "react";

const Form = ({
  name,
  setName,
  capacity,
  errorObject,
  setErrorObject,
  setCapacity,
  setOpenModal,
  handleTableSubmit,
  openModal,
}) => {
  return (
    <div className="w-full text-sm">
      <div className="px-10 w-full py-8">
        <p
          className={`flex justify-center ${
            errorObject.name ? "bg-red/5" : "bg-blue-grey"
          } rounded-md py-2 px-2`}
        >
          Name
        </p>
        <input
          value={name}
          autoComplete="off"
          onChange={(e) => {
            delete errorObject.name;
            setErrorObject({ ...errorObject });
            if (e.target.value.length <= 4) setName(e.target.value);
          }}
          className={`mt-4 ${
            errorObject.name ? "bg-red/5 border border-red" : "bg-blue-grey"
          } text-center w-full py-2 px-2 flex justify-center rounded-md`}
        />
        {errorObject.name && (
          <p className="text-xs text-red">{errorObject.name.join("")}</p>
        )}
      </div>
      <div className="px-10 w-full pb-6">
        <p
          className={`flex justify-center ${
            errorObject.name ? "bg-red/5" : "bg-blue-grey"
          } rounded-md py-2 px-2`}
        >
          Capacity
        </p>
        <input
          value={capacity}
          autoComplete="off"
          onChange={(e) => {
            delete errorObject.capacity;
            setErrorObject({ ...errorObject });
            setCapacity(parseInt(e.target.value) || "");
          }}
          className={`mt-4 ${
            errorObject.capacity ? "bg-red/5 border border-red" : "bg-blue-grey"
          } text-center w-full py-2 px-2 flex justify-center rounded-md`}
        />
        {errorObject.capacity && (
          <p className="text-xs text-red">{errorObject.capacity.join("")}</p>
        )}
      </div>
      <div className="w-full bg-blue-grey rounded-lg flex justify-center py-3 font-medium">
        <button
          onClick={() => setOpenModal(false)}
          className="p-2 bg-white text-grey rounded-md mx-2"
        >
          Cancel
        </button>
        <button
          onClick={() => handleTableSubmit(openModal)}
          className="p-2 bg-blue text-white rounded-md mx-2"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Form;
