import React, { useState, useCallback, useEffect } from "react";
import Search from "../assets/Search";
import RoundedClose from "../assets/RoundedClose";
import { debounce } from "lodash";

const Searchbar = ({ setter = () => {}, onEnter = () => {} }) => {
  const [searchInputValue, setSearchInputValue] = useState("");

  const handleChange = (e) => {
    setSearchInputValue(e.target.value);
  };

  const handleCancel = () => {
    setSearchInputValue("");
  };

  const handleOptimisedChange = useCallback(
    debounce((text) => {
      setter(text);
    }, 300),
    []
  );

  useEffect(() => {
    handleOptimisedChange(searchInputValue);
  }, [searchInputValue]);

  return (
    <div className="flex mt-[1vh] w-fit items-center justify-center rounded-lg border bg-white px-2 py-1.5">
      <Search />

      <input
        type="text"
        value={searchInputValue}
        onChange={handleChange}
        onKeyDown={e => e.key === "Enter" && onEnter(e)}
        className="mx-2 w-72 text-sm outline-none"
      />

      {searchInputValue ? (
        <div className="stroke-red">
          <RoundedClose handleCancel={handleCancel} size="15" />
        </div>
      ) : (
        <div className="w-[15px]"></div>
      )}
    </div>
  );
};

export default Searchbar;
