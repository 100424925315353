import React from 'react'

const Header = () => {
    const host = window.location.host.replace("www.", "").split(".");
  const subdomain = host[0];

  return (
    <div className={`w-full sticky top-0 z-10 flex justify-center bg-blue py-2 ${false && "shadow-lg shadow-blue"} text-white text-lg font-bold`}>
        <p className="drop-shadow-2xl shadow-white">{subdomain}</p>
    </div>
  )
}

export default Header