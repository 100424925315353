import React from "react";

const GPay = ({ small = false }) => {
  return (
    <svg
      width={small ? "24" : "34"}
      height={small ? "24" : "34"}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="17" r="17" fill="white" />
      <path
        d="M26.7972 10.869C24.43 9.53181 21.403 10.3264 20.0351 12.6429L16.5867 18.4886C15.5889 20.1766 16.8734 20.7665 18.3058 21.6069L21.6239 23.481C22.7475 24.1152 24.1828 23.7387 24.8309 22.6406L28.3758 16.6331C29.5667 14.6144 28.8601 12.0345 26.7972 10.869Z"
        fill="#EA4335"
      />
      <path
        d="M22.8956 13.2888L19.5774 11.4147C17.7457 10.4196 16.7085 10.3538 15.9564 11.5235L11.0626 19.8174C9.69612 22.1325 10.5096 25.0933 12.8753 26.4276C14.9382 27.5931 17.5746 26.9015 18.7655 24.8828L23.7529 16.4285C24.404 15.329 24.0192 13.923 22.8956 13.2888Z"
        fill="#FBBC04"
      />
      <path
        d="M23.5338 9.02465L21.2032 7.70748C18.6253 6.25142 15.3291 7.11474 13.8412 9.63742L9.40384 17.1582C8.7484 18.2678 9.13757 19.688 10.2714 20.328L12.8815 21.8027C14.1704 22.5315 15.8177 22.0991 16.5625 20.8377L21.6319 12.246C22.6823 10.4664 25.0071 9.85648 26.8256 10.8844L23.5338 9.02465Z"
        fill="#34A853"
      />
      <path
        d="M14.4719 11.7893L11.9525 10.369C10.8289 9.7362 9.3937 10.1113 8.74557 11.208L5.72295 16.3192C4.23504 18.8347 5.11725 22.0532 7.69512 23.505L9.61315 24.5859L11.9394 25.8974L12.9489 26.4657C11.1566 25.2918 10.5875 22.9395 11.6833 21.0868L12.466 19.7639L15.3321 14.9176C15.9788 13.8238 15.594 12.4221 14.4719 11.7893Z"
        fill="#4285F4"
      />
    </svg>
  );
};

export default GPay;
