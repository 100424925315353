import React, { useEffect, useState } from "react";
import DatePicker from "./DatePicker";
import BottombarControl from "./BottombarControl";
import moment from "moment";
import { motion } from "framer-motion";
import SideValueSetter from "../CalenderPicker/SideValueSetter";
import BottomControlBar from "../CalenderPicker/BottomControlBar";

const DateRangePickerContainer = ({
  setPickerOpen,
  setStartDate,
  setAddDateInParam,
  setEndDate,
  setOtherSelectedDate,

  // keyValue,
  // allFilters,
  // setAllFilters,
  // setOpenDatePicker,
}) => {
  const [fromDate, setFromDate] = useState(new Date().getDate());
  const [fromMonth, setFromMonth] = useState(
    (((new Date().getMonth() - 1) % 12) + 12) % 12
  );
  // const [tempStartDate, setTempStartDate] = useState(new Date());
  // const [tempEndDate, setTempEndDate] = useState(new Date());
  const [fromYear, setFromYear] = useState(new Date().getFullYear());
  const [toDate, setToDate] = useState(new Date().getDate());
  const [toMonth, setToMonth] = useState(new Date().getMonth());
  const [toYear, setToYear] = useState(new Date().getFullYear());
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [inputStartDate, setInputStartDate] = useState("");
  const [inputEndDate, setInputEndDate] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    if (initial) {
      setInputEndDate(moment().format("DD/MM/YY"));
      setInitial(false);
      return;
    }
    const settableDate = selectedToDate ? selectedToDate : new Date();
    setToMonth(settableDate.getMonth());
    setToYear(settableDate.getFullYear());
    setInputEndDate(moment(settableDate).format("DD/MM/YY"));
    if (selectedToDate < selectedFromDate) {
      setInvalid(true);
      return;
    }
    setInvalid(false);
  }, [selectedToDate]);

  useEffect(() => {
    if (initial) {
      setInputStartDate(moment().format("DD/MM/YY"));
      setInitial(false);
      return;
    }
    const settableDate = selectedFromDate ? selectedFromDate : new Date();
    setFromMonth(settableDate.getMonth());
    setFromYear(settableDate.getFullYear());
    setInputStartDate(moment(settableDate).format("DD/MM/YY"));
    if (selectedFromDate > selectedToDate) {
      setInvalid(true);
      return;
    }
    setInvalid(false);
    if (initial) {
      setInitial(false);
    }
  }, [selectedFromDate]);

  return (
    <motion.div
      initial={{ scale: 0.6 }}
      animate={{ scale: 1 }}
      className="w-[60vw] 2xl:w-[40vw] z-30 relative top-2 -left-52 bg-white border border-background shadow-lg rounded-md p-3 flex"
    >
      <SideValueSetter
        setAddDateInParam={setAddDateInParam}
        setOtherSelectedDate={setOtherSelectedDate}
        setPickerOpen={setPickerOpen}
      />
      <div>
        <div className="flex w-full divide-x pb-2 border-b">
          <DatePicker
            date={{
              value: fromDate,
              month: fromMonth,
              year: fromYear,
              set: setFromDate,
              setMonth: setFromMonth,
              setYear: setFromYear,
            }}
            otherDate={{
              value: toDate,
              month: toMonth,
              year: toYear,
              set: setToDate,
              setMonth: setToMonth,
              setYear: setToYear,
            }}
            selectedDate={selectedFromDate}
            setSelectedDate={setSelectedFromDate}
            selectedOtherDate={selectedToDate}
            from
          />
          <DatePicker
            otherDate={{
              value: fromDate,
              month: fromMonth,
              year: fromYear,
              set: setFromDate,
              setMonth: setFromMonth,
              setYear: setFromYear,
            }}
            date={{
              value: toDate,
              month: toMonth,
              year: toYear,
              set: setToDate,
              setMonth: setToMonth,
              setYear: setToYear,
            }}
            selectedDate={selectedToDate}
            setSelectedDate={setSelectedToDate}
            selectedOtherDate={selectedFromDate}
          />
        </div>
        {/* <BottombarControl
        keyValue={keyValue}
        allFilters={allFilters}
        setAllFilters={setAllFilters}
        inputStartDate={inputStartDate}
        setInputStartDate={setInputStartDate}
        startDate={selectedFromDate}
        endDate={selectedToDate}
        setInputEndDate={setInputEndDate}
        inputEndDate={inputEndDate}
        setStartDate={setSelectedFromDate}
        setEndDate={setSelectedToDate}
        setPickerOpen={setOpenDatePicker}
        invalid={invalid}
      /> */}

        <BottomControlBar
          setAddDateInParam={setAddDateInParam}
          inputStartDate={inputStartDate}
          tempStartDate={selectedFromDate}
          setInputStartDate={setInputStartDate}
          setTempStartDate={setSelectedFromDate}
          tempEndDate={selectedToDate}
          setTempEndDate={setSelectedToDate}
          setInputEndDate={setInputEndDate}
          inputEndDate={inputEndDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setPickerOpen={setPickerOpen}
          setOtherSelectedDate={setOtherSelectedDate}
        />
      </div>
    </motion.div>
  );
};

export default DateRangePickerContainer;
