import React from "react";

const FullArrow = () => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
    >
      <path
        d="M1.7373 5.0127L12.2373 5.0127M7.76221 9.2627L12.2622 5.0127L7.76221 0.762695"
        stroke="#302D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FullArrow;
