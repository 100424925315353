import React, { createContext, useEffect, useState } from "react";
import ManagementModal from "../helperComponents/ManagementModal";
import CategoryManagementTable from "../tables/Category";
import { AnimatePresence } from "framer-motion";
import Form from "../forms/Category";
import ManagementControl from "../helperComponents/ManagementControl";
import { ProductCategoriesApi } from "../apis/Products/Category";
import { ProductsApi } from "../apis/Products";
import { KitchensApi } from "../apis/Kitchens";
import { kitchenAtom } from "../common/Atoms";
import { useRecoilState } from "recoil";

export const CategoriesContext = createContext();

const ManageCategory = () => {
  const [showModal, setShowModal] = useState(false);
  const [editableCategoryId, setEditableCategoryId] = useState(null);
  const [editableCategory, setEditableCategory] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageLength, setPageLength] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [kitchens, setKitchens] = useRecoilState(kitchenAtom);
  const [formData, setFormData] = useState({
    categories: [],
    addons: [],
  });
  // const fetchCategory = async () => {
  //   const { data: response } = await ProductCategoriesApi.show(
  //     editableCategoryId.id
  //   );
  //   if (response.data) {
  //     editableCategoryId.products = response.data;
  //     editableCategoryId.products = editableCategoryId.products.map(
  //       (product) => ({
  //         id: product.product_id,
  //         title: product.product_name,
  //       })
  //     );
  //     setEditableCategory(editableCategoryId);
  //     setShowModal(true);
  //   }
  // };

  const fetchCategoryProducts = async (id) => {
    const { data: response } = await ProductsApi.categorySearch(id);
    const categoryProducts = response.data.map((product) => ({
      id: product.product_id,
      title: product.product_name,
    }));
    
    setEditableCategory({ ...editableCategory, products: categoryProducts });
  };

  useEffect(async () => {
    if (editableCategory) {
      console.log(editableCategory , 'editableCategory');
      if (!editableCategory.products)
        await fetchCategoryProducts(editableCategory.id);
      else setShowModal(true);
    }

  }, [editableCategory]);

  const fetchKitchens = async (params) => {
    const { data: response } = await KitchensApi.list(params);
    setKitchens(response.data);
  };

  useEffect(() => {
    if (kitchens.length === 0) {
      fetchKitchens();
    }
  }, []);

  return (
    <CategoriesContext.Provider
      value={{
        totalCount,
        setTotalCount,
        pageLength,
        setPageLength,
        searchString,
      }}
    >
      <div className="relative z-10 h-[75%] bg-blue-grey w-full">
        <ManagementControl
          setShowModal={setShowModal}
          heading="Manage Category"
          buttonText="category"
          pageLength={pageLength}
          setPageLength={setPageLength}
          totalCount={totalCount}
          setSearchString={setSearchString}
        />
        <CategoryManagementTable
          setEditableCategory={setEditableCategory}
          showModal={showModal}
          setShowModal={setShowModal}
          kitchens={kitchens}
        />
        <AnimatePresence>
          {showModal && (
            <ManagementModal
              handleCancel={() => {
                setShowModal(false);
                setEditableCategory(null);
              }}
              form
            >
              <Form
                setShowModal={setShowModal}
                category={editableCategory}
                setCategory={setEditableCategory}
                fetchKitchens={fetchKitchens}
                kitchens={kitchens}
    
              />
            </ManagementModal>
          )}
        </AnimatePresence>
      </div>
    </CategoriesContext.Provider>
  );
};

export default ManageCategory;
