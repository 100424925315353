import React, { useState } from "react";
import UpwardArrow from "../assets/UpwardArrow";
import { AnimatePresence, motion } from "framer-motion";
import Combine from "../assets/Combine";
import Close from "../assets/Close";

const CreateCombination = ({
  list = [],
  value = [],
  setter = () => {},
  showComb = () => {},
}) => {
  const [openDropdown, setOpenDropDown] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const removeSelectedItem = (item) => {
    const filteredItems = selectedItems.filter((each) => each.id !== item.id);
    setSelectedItems(filteredItems);
  };

  const combine = () => {
    if (selectedItems.length <= 1) {
      return;
    }
    const id = selectedItems.map((each) => each.id).sort();
    const name = selectedItems.map((each) => each.name).join(", ");
    const price = String(
      selectedItems.reduce((acc, cur) => Number(acc) + Number(cur.price), 0)
    );
    const isPresent = value.find((each) => each.id.join("") === id.join(""));
    if (!isPresent) {
      setter((prev) => [...prev, { id, name, price }]);
    }
    showComb(false);
  };

  return (
    <div className="relative mx-1">
      <div className="flex flex-col gap-y-3">
        <div className="flex justify-between gap-x-2">
          <div
            onClick={() => setOpenDropDown((prev) => !prev)}
            className={`${
              openDropdown
                ? "border-2 shadow-md shadow-normal-blue border-normal-blue "
                : "border-2"
            } rounded-md h-fit w-full py-2 transition ease-in-out flex justify-end px-4`}
          >
            <motion.div
              initial={{ rotate: 180 }}
              animate={{ rotate: openDropdown ? 0 : 180 }}
              className="stroke-gray-500"
            >
              <UpwardArrow />
            </motion.div>
          </div>
          <button
            onClick={combine}
            className="bg-tuxedo/80 text-white font-medium items-center rounded-md flex gap-x-1 px-1"
          >
            <Combine />
            Combine
          </button>
          <button
            onClick={() => showComb(false)}
            className="bg-red/80 stroke-white text-white font-medium items-center rounded-md flex gap-x-1 px-2"
          >
            <Close />
          </button>
        </div>
        <div className="h-10 w-full flex gap-x-2 flex-wrap">
          {selectedItems.map((item) => (
            <div className="border px-2 rounded-md h-fit text-xxs flex items-center gap-x-2">
              {item.name}
              <div
                onClick={() => removeSelectedItem(item)}
                className="stroke-black"
              >
                <Close size={8} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <AnimatePresence>
        {openDropdown && (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            className="absolute top-8 w-full bg-white shadow-md rounded-md"
          >
            {list.map((each) => (
              <button
                onClick={() => {
                  setSelectedItems((prev) =>
                    Array.from(new Set([...prev, each]))
                  );
                  setOpenDropDown(false);
                }}
                className="px-3 w-full py-2"
              >
                {each.name}
              </button>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CreateCombination;
