import { isNumber } from "lodash";

export const cartesian = (...a) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())));

export const validateProductToCart = (product, cart, setCart, setErrorObject, value = 1) => {
    const checkableIds = cart.reduce((acc, cur, idx) => cur.id === product.id ? acc.concat(idx) : (acc), [])
    const checkableAddons = product.selectedAddons && cart.reduce((acc, cur) => cur.id === product.id ? acc.concat([cur.selectedAddons]) : (acc), [])
    const checkableVariant = product.selectedVariant && cart.reduce((acc, cur) => cur.id === product.id ? acc.concat(cur.selectedVariant) : (acc), [])
    const checkableAddonIds = checkableAddons?.map((each) => each.map((eachAddon) => eachAddon.id).sort())
    const checkableVariantIds = checkableVariant?.map((each) => each.id)
    const productAddonIds = product.selectedAddons?.map((each) => each.id).sort()

    const sameVariantIndeces = checkableVariantIds?.reduce((acc, cur, id) => cur === product.selectedVariant.id ? acc.concat(id): acc, [])
    const resultSameAddon = product.selectedAddons && sameVariantIndeces?.reduce((acc, each) => {
      return (JSON.stringify(checkableAddonIds[each]) === JSON.stringify(productAddonIds) ? each : acc)}, null)
    const resultSameVariant = product.selectedVariant && checkableVariantIds?.indexOf(product.selectedVariant?.id)

    if(resultSameVariant ===  undefined &&  product.variants[0] && !(product.variants[0].options[0].includes("Default"))) {
      setErrorObject(prev => ({...prev, variants: "Must select a variant"}))
      return "error";
    }

    let shouldUpdate = ((resultSameVariant !== undefined || (Number.isInteger(resultSameVariant) && resultSameVariant >= 0))) && (resultSameAddon === undefined || (Number.isInteger(resultSameAddon) && resultSameAddon >= 0)) ? (resultSameAddon || resultSameVariant) : false
    
    if(Number.isInteger(shouldUpdate)){
      const index = resultSameVariant === undefined ? resultSameAddon : resultSameAddon
      const settableCart = cart.map((each, idx) => idx === checkableIds[index] ? {...each, qty: each.qty + value} : each)
      shouldUpdate = settableCart[checkableIds[index]].qty
      setCart(settableCart)
    }
    return shouldUpdate
  }