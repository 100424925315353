import React, { useContext, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { OrdersApi } from "../../apis/Orders.js";
import {
  BillPrintableValueAtom,
  CartHistoryAtom,
  PayableAmountAtom,
  PaymentOrderIdAtom,
} from "../../common/Atoms";
import { Payment } from "../../screens/Tables";

const PaymentButton = ({
  handleSave,
  clearData,
  printModal,
  setIsPayLater,
}) => {
  const { setShowPaymentPanel, showPaymentPanel } = useContext(Payment);
  const [shouldClear, setShouldClear] = useState(false);
  const [toLater, setToLater] = useState(null);
  const [disable, setDisable] = useState(false);
  const [payableAmount, setPayableAmount] = useRecoilState(PayableAmountAtom);
  const setOrderId = useSetRecoilState(PaymentOrderIdAtom);
  const setBillPrintable = useSetRecoilState(BillPrintableValueAtom);
  const prevCart = useRecoilValue(CartHistoryAtom);
  useEffect(() => {
    if (!payableAmount && !showPaymentPanel && shouldClear) {
      clearData();
      setShouldClear(false);
    }
  }, [payableAmount, showPaymentPanel, shouldClear]);

  useEffect(() => {
    if (!printModal && toLater) {
      setPayableAmount(toLater);
      setShouldClear(true);
    }
  }, [toLater, printModal]);

  useEffect(async () => {
    if (!disable) return;
    const returnValue = await handleSave();
    if (returnValue) {
      clearData();
    }
    setDisable(false);
  }, [disable]);

  console.log(prevCart);
  return (
    <div className="flex mt-3.5 justify-center">
      <div
        onClick={() => {
          if (disable) return;
          setDisable(true);
        }}
        className={`${
          disable && "opacity-30"
        } py-3.5 px-5 mr-6 transition ease-in-out delay-50 cursor-pointer text-xs 2xl:text-base bg-white text-grey rounded-lg font-semibold hover:bg-blue hover:text-white`}
      >
        {Object.keys(prevCart).length > 0 ? "Update Order" : "Place Order"}
      </div>
      <div
        onClick={async () => {
          setShowPaymentPanel(true);
          const { grandTotal, check, id } = await handleSave();
          if (grandTotal) {
            const { data: response } = await OrdersApi.orderPrint(id);
            setBillPrintable(response);
            setOrderId(id);
            if (check) {
              setToLater(grandTotal);
            } else {
              setPayableAmount(grandTotal);
              setShouldClear(true);
            }
          } else {
            setShowPaymentPanel(false);
          }
        }}
        className={`py-3.5 px-5 mr-6 transition ease-in-out text-xs 2xl:text-base delay-50 cursor-pointer ${
          showPaymentPanel && payableAmount
            ? "bg-blue text-white"
            : " bg-white text-grey hover:bg-blue hover:text-white"
        } rounded-lg font-semibold`}
      >
        Pay Now
      </div>
      <div
        onClick={() => {
          setIsPayLater(true);
        }}
        className="py-3.5 px-5 transition ease-in-out delay-50 cursor-pointer bg-white text-grey text-xs 2xl:text-base rounded-lg font-semibold hover:bg-blue hover:text-white"
      >
        Pay Later
      </div>
    </div>
  );
};

export default PaymentButton;
