import React, { useEffect, useState } from "react";
import CategoryIcon from "../assets/Category";
import { AnimatePresence, motion } from "framer-motion";
import ModalBg from "../helperComponents/ModalBg";
import RoundedClose from "../assets/RoundedClose";

const CategoryMenu = ({
  hasCart = false,
  categories = [],
  setSelectedCategory = () => {},
}) => {
  const [openModal, setOpenModal] = useState(false);
  const isLargeDevice = window.innerWidth > 565;

  const handleModalClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    document.body.style.overflow = openModal ? "hidden" : "auto";
  }, [openModal]);

  return (
    <motion.div
      animate={{ bottom: hasCart ? "7vh" : "0" }}
    //   transition={{ duration: 0.6 }}
      className="sticky h-20 w-full flex pointer-events-none items-center justify-center"
    >
      <motion.div
        // animate={{ bottom: hasCart ? "-5vh" : "0" }}
        className="w-fit rounded-full pointer-events-auto bg-white"
      >
        <button
          onClick={() => setOpenModal(true)}
          className="flex items-center bg-blue shadow-md shadow-blue gap-x-2 p-3 lg:px-6 lg:py-2 rounded-full text-white font-semibold"
        >
          <CategoryIcon />
          {isLargeDevice && "Categories"}
        </button>
      </motion.div>
      {openModal && (
        <ModalBg handleCancel={handleModalClose}>
          <div className="bg-white max-w-2xl rounded-md h-[60vh] pointer-events-auto mx-3 my-10 overflow-y-auto">
            <div className="flex bg-blue pb-4 px-3 pt-3 mb-3 text-white justify-between sticky top-0 items-center text-bold stroke-white">
              Select Category
              <RoundedClose handleCancel={handleModalClose} size={16} />
            </div>
            <div className="px-3">
              <button
                onClick={() => {
                  setSelectedCategory(null);
                  setOpenModal(false);
                }}
                className="w-full p-2 text-left hover:bg-normal-blue/10 text-sm"
              >
                All
              </button>
              {categories.map((category) => (
                <button
                  onClick={() => {
                    setSelectedCategory(category);
                    setOpenModal(false);
                  }}
                  className="w-full p-2 text-left hover:bg-normal-blue/10 text-xs"
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>
        </ModalBg>
      )}
    </motion.div>
  );
};

export default CategoryMenu;
