import React, { useState, useEffect, useRef } from "react";
import VariationForm from "../../../forms/Variation";
import { motion } from "framer-motion";
import Header from "../../../utils/form/Header";
import Checkbox from "../../../utils/form/Checkbox";
import Show from "./Show";
import SquaredPlus from "../../../assets/SquaredPlus";
import CombinationTable from "../../../tables/Variation";

const Variation = ({
  showVariationModal,
  setShowVariationModal,
  options,
  setOptions,
  variantsData,
  setVariantsData,
  referenceVariants,
}) => {
  const [showAddVariant, setShowAddVariant] = useState(options.length === 0);
  const [editableId, setEditableId] = useState(null)
  const [deletableId, setDeletableId] = useState(null)

  useEffect(() => {
    if(editableId !== null){
      setShowAddVariant(true)
    }
    if(deletableId !== null){
      options.splice(deletableId, 1)
      setOptions([...options])
      setDeletableId(null)
    }
  },[editableId, deletableId])

  useEffect(() => {
    setShowAddVariant(options.length === 0)
  }, [options])

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ duration: 0.6 }}
      className="w-[30%] h-screen fixed top-0 right-0 z-50 bg-white p-10 flex flex-col justify-between"
    >
      <Header
        // heading="Add New Product"
        setModalOpen={() => {
          setShowVariationModal(false);
        }}
      />
      <div className=" text-sm h-[82.5vh] overflow-y-auto scrollbar-hide">
        <div className="w-full mb-4">
          <div className=" font-semibold mb-2">Options</div>
          <div className="w-full  flex items-center pb-2 border-b border-gray-300">
            <Checkbox
              checked={showVariationModal}
              onClick={() => setShowVariationModal((prev) => !prev)}
            />
            <div className="text-xs ml-2 text-grey">
              This product has options, like size and color
            </div>
          </div>
        </div>
        <div
          className={
            options.length > 0 && "border-b border-gray-300 pb-2 mb-4 w-full"
          }
        >
          {options.length > 0 &&
            options.map((variant, id) => console.log(variant,"variant")
            // <Show variant={variant} id={id} setEditableId={setEditableId} setDeletableId={setDeletableId} />
            )}
        </div>
        {showAddVariant ? (
          <VariationForm
            options={options}
            setOptions={setOptions}
            setVariantsData={setVariantsData}
            setShowAddVariant={setShowAddVariant}
            editableId={editableId}
            setEditableId={setEditableId}
          />
        ) : (
          options.length < 3 &&
          (<div
            onClick={() => setShowAddVariant(true)}
            className="cursor-pointer flex items-center"
          >
            <SquaredPlus />
            <p className="text-xs ml-2 text-grey"> Add another option </p>
          </div>)
        )}
        {options.length > 0 && (
          <>
            <p className="mt-[5vh] text-sm font-semibold">Variants</p>
            <CombinationTable
              options={options}
              combinationData={variantsData}
              setCombinationData={setVariantsData}
              referenceVariants={referenceVariants}
            />
          </>
        )}
      </div>
      {/* <button onClick={() => {
        setVariantsData(combinationData)
        setShowVariationModal(false)
        }} 
      className="bg-blue w-full text-lg font-semibold text-white flex justify-center rounded-lg py-2">
        Save
      </button> */}
    </motion.div>
  );
};

export default Variation;
